import { httpClient } from "@/shared/api";
import type { AxiosResponse } from "axios";
import type {
    IAssigneeEdge,
    IAssignee,
    IAssigneeResponse,
    IAssigneeGeneral,
    IAssigneeFilter,
    IAssigneeEdgeGeneral,
    IExternalSettingsDescription,
} from "..";

export class AssigneeApi {
    static async fetchAll(params: IAssigneeFilter): Promise<IAssigneeResponse> {
        const response: AxiosResponse = await httpClient.get("/assignee/list/pageable", { params });
        return response.data;
    }
    static async fetchAllShort(params: IAssigneeFilter): Promise<IAssigneeResponse> {
        const response: AxiosResponse = await httpClient.get("/assignee/list/short/pageable", { params });
        return response.data;
    }
    static async fetchForFilter(): Promise<IAssignee[]> {
        const response: AxiosResponse = await httpClient.get("/diagram-search/assignee-list");
        return response.data;
    }
    static async fetchByDiagramId(diagramId: string): Promise<IAssigneeEdge[]> {
        const response: AxiosResponse = await httpClient.get(`/assignee/assigneeedges/${diagramId}`);
        return response.data;
    }
    static async fetchOneById(id: number): Promise<IAssignee> {
        const response: AxiosResponse = await httpClient.get(`/assignee/${id}`);
        return response.data;
    }

    static async save(payload: IAssigneeGeneral): Promise<IAssignee> {
        const response: AxiosResponse = await httpClient.post("/assignee", payload);
        return response.data;
    }
    static async setupEdge(payload: IAssigneeEdgeGeneral): Promise<IAssigneeEdge> {
        const response: AxiosResponse = await httpClient.post("/assignee/edge", payload);
        return response.data;
    }
    static async saveDescription(diagramId: string, elementId: string, payload: IExternalSettingsDescription): Promise<IAssigneeEdge> {
        const response: AxiosResponse = await httpClient.post(`/assignee/update-edge-description/${diagramId}/${elementId}`, payload);
        return response.data;
    }

    static async delete(assigneeId: number): Promise<void> {
        await httpClient.delete(`/assignee/${assigneeId}`);
    }
}
