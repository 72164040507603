<template>
    <OverlayPanel class="w-28rem" ref="colorPickerRef">
        <ColorPicker v-model:color="color" @set-color="setColor" />
    </OverlayPanel>
</template>

<script setup lang="ts">
import { ColorPicker } from "@/shared/ui/color-picker";

import { useChangeElementColor } from "../";

const { color, colorPickerRef, setColor } = useChangeElementColor();
</script>
