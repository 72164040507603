<template>
    <div class="w-full flex flex-column gap-2 align-items-center">
        <div class="storm-grid">
            <span
                class="storm-grid__item border-round border-1 border-100 transition-duration-150"
                v-for="item in colors"
                :style="{ backgroundColor: item }"
                :class="{ 'storm-grid__item_active': color === item }"
                @click="() => setColor(item)"
            ></span>
        </div>
        <span class="h-1rem w-full border-round border-1 border-200 transition-duration-150" :style="backgroundStyle"></span>
        <Button
            v-if="showClear"
            label="Очистить цвет"
            icon="pi pi-eraser"
            severity="secondary"
            outlined
            size="small"
            @click="() => setColor('')"
        />
    </div>
</template>

<script setup lang="ts">
import { colors } from "@/shared/constants/colors";
import { computed, ref } from "vue";

interface IProps {
    showClear?: boolean;
    isToggleColor?: boolean;
    size: string;
}

const props = withDefaults(defineProps<IProps>(), { showClear: true, isToggleColor: true, size: "normal" });

const color = defineModel("color");

const backgroundStyle = computed<string>(() => {
    if (color.value) return `background-color: ${color.value}`;
    return "";
});

const gap = computed(() => (props.size === "small" ? "2px" : "5px"));

function setColor(value: string): void {
    if (color.value === value && props.isToggleColor) {
        color.value = "";
    } else {
        color.value = value;
    }
}
</script>

<style scoped lang="scss">
.storm-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: v-bind(gap);

    &__item {
        aspect-ratio: 1 / 1;
        cursor: pointer;

        &:hover:not(&_active) {
            scale: 1.2;
        }

        &_active {
            border-radius: 20px !important;
            scale: 0.8;
        }
    }
}
</style>
