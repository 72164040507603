import { ref } from "vue";
import { defineStore, storeToRefs } from "pinia";

import { type IAddOverlayParams, useBpmnModelerStore, EOverlayType, type IOverlayDataItem, EOverlayPosition } from "..";
import { EAssetType, type IAssetEdge } from "@/entities/Asset";
import type { IAssigneeEdge } from "@/entities/Assignee";
import type { ICommentIndicator } from "@/entities/Comment";

interface IOverlayData {
    id: string;
    items: IOverlayDataItem<any>[];
}
interface IOverlayDataPayload {
    id: string;
    item: IOverlayDataItem<any>;
}
interface IOverlayDeletePayload {
    elementId: string;
    type?: EOverlayType;
    detailId?: number | string;
}

export const useBpmnModelerOverlayStore = defineStore("bpmn-modeler-overlay", () => {
    const bmpmModelerStore = useBpmnModelerStore();

    const { modelerOverlays } = storeToRefs(bmpmModelerStore);

    const overlaysData = ref<IOverlayData[]>([]);
    function addOverlayData(params: IOverlayDataPayload): void {
        const overlayData: undefined | IOverlayData = overlaysData.value.find(item => item.id === params.id);
        if (overlayData) {
            const overlayDataItem: undefined | IOverlayDataItem<any> = overlayData.items.find(item => item.type === params.item.type);

            if (overlayDataItem) {
                const paramsDetail: any = params.item.details[0];

                const isExist: boolean = overlayDataItem.details.some(item => item.id === paramsDetail.id);

                if (!isExist) {
                    overlayDataItem.details.push(...params.item.details);
                }
            } else {
                overlayData.items.push(params.item);
            }
        } else {
            overlaysData.value.push({
                id: params.id,
                items: [params.item],
            });
        }
    }
    function deleteOverlayData(id?: string, type?: EOverlayType, detailId?: string | number): void {
        if (id) {
            const index: number = overlaysData.value.findIndex(item => item.id === id);
            if (index >= 0) {
                if (type) {
                    if (detailId) {
                        const typeIndex: number = overlaysData.value[index].items.findIndex(item => item.type === type);
                        if (typeIndex === -1) return;

                        const typeItem: IOverlayDataItem<any> = overlaysData.value[index].items[typeIndex];

                        const detailIndex: number = typeItem.details.findIndex(item => item.id === detailId);
                        if (detailIndex >= 0) typeItem.details.splice(detailIndex, 1);

                        if (typeItem.details.length === 0) overlaysData.value[index].items.splice(typeIndex, 1);
                    } else {
                        overlaysData.value[index].items = overlaysData.value[index].items.filter(item => item.type !== type);
                    }
                }

                if (overlaysData.value[index].items.length === 0) overlaysData.value.splice(index, 1);
            }

            return;
        }

        if (type) {
            for (let i = 0; i < overlaysData.value.length; i++) {
                overlaysData.value[i].items = overlaysData.value[i].items.filter(item => item.type !== type);
                if (overlaysData.value[i].items.length === 0) {
                    overlaysData.value.splice(i, 1);
                    i--;
                }
            }
        }
    }
    function findOverlayDataItemBy(elementId: string, type: EOverlayType): undefined | IOverlayDataItem<any> {
        const overlayData = overlaysData.value.find(item => item.id === elementId);
        if (overlayData) {
            const overlayDataItem = overlayData.items.find(item => item.type === EOverlayType.COMMENT);
            return overlayDataItem;
        }
        return undefined;
    }
    function findOverlayDataCountBy(elementId: string, type: EOverlayType): number {
        const overlayItem = overlaysData.value.find(item => item.id === elementId);
        if (!overlayItem) return 0;

        const typeItem = overlayItem.items.find(item => item.type === type);
        if (!typeItem) return 0;

        return typeItem.details.length;
    }
    function clearOverlayData(): void {
        overlaysData.value = [];
    }

    function changeStatusCommentByElement(elementId: string, status: boolean): void {
        const overlayDataItem: undefined | IOverlayDataItem<any> = findOverlayDataItemBy(elementId, EOverlayType.COMMENT);
        if (overlayDataItem) {
            overlayDataItem.settings.open = status;
        }
    }
    function resetStatusCommentByAll(): void {
        for (const element of overlaysData.value) {
            for (const item of element.items) {
                if (item.type === EOverlayType.COMMENT) {
                    item.settings.open = false;
                }
            }
        }
    }
    function chnageDataCommentByElement(payload: ICommentIndicator): void {
        const { elementId, ...data } = payload;
        const overlayDataItem: undefined | IOverlayDataItem<any> = findOverlayDataItemBy(elementId, EOverlayType.COMMENT);
        if (overlayDataItem) {
            overlayDataItem.details = [data];
        } else {
            addOverlayData({
                id: elementId,
                item: {
                    type: EOverlayType.COMMENT,
                    position: EOverlayPosition.TOP_RIGHT,
                    settings: {
                        open: false,
                    },
                    details: [data],
                },
            });
        }
    }

    function createOverlayComment(element: any): void {
        const comments = findOverlays(element.id, EOverlayType.COMMENT);

        if (comments.length) {
            changeStatusCommentByElement(element.id, true);
        } else {
            addOverlayData({
                id: element.id,
                item: {
                    details: [],
                    position: EOverlayPosition.TOP_RIGHT,
                    type: EOverlayType.CREATE_COMMENT,
                    settings: {
                        open: false,
                    },
                },
            });
        }
    }

    function isExistOverlay(elementId: string, type: EOverlayType): boolean {
        const overlays: any[] = modelerOverlays.value.get({ element: elementId, type });

        return overlays.length > 0;
    }
    function findOverlays(elementId: string, type?: EOverlayType) {
        if (type) {
            return modelerOverlays.value.get({ element: elementId, type });
        }

        return modelerOverlays.value.get({ element: elementId });
    }

    function addOverlay(elementId: string, params: IAddOverlayParams): void {
        const isExist: boolean = isExistOverlay(elementId, params.type);

        if (isExist) return;

        modelerOverlays.value.add(elementId, params.type, { ...params });
    }

    async function deleteOverlaysBy(data: IOverlayDeletePayload, isDeleteOverlayData: boolean = true): Promise<void> {
        const elementId = data.elementId;
        const type = data.type;
        const detailId = data.detailId;

        if (elementId && type) {
            const isExist = isExistOverlay(elementId, type);
            if (!isExist) return;

            const countElements = findOverlayDataCountBy(elementId, type);

            if (countElements <= 1 || !detailId) await modelerOverlays.value.remove({ element: elementId, type });
            return isDeleteOverlayData ? deleteOverlayData(elementId, type, detailId) : undefined;
        }
        if (elementId) {
            await modelerOverlays.value.remove(elementId);
            return isDeleteOverlayData ? deleteOverlayData(elementId, undefined, detailId) : undefined;
        }
        if (type) {
            await modelerOverlays.value.remove({ type });
            return isDeleteOverlayData ? deleteOverlayData(undefined, type, detailId) : undefined;
        }
    }
    async function clearOverlays(isClearData: boolean = false): Promise<void> {
        const overlayTypes: string[] = Object.values(EOverlayType);

        for (const type of overlayTypes) {
            if (type === EOverlayType.DRILL_DOWN) continue;
            await modelerOverlays.value.remove({ type });
        }

        if (isClearData) clearOverlayData();
    }

    function addComment(commentCount: ICommentIndicator): void {
        addOverlayData({
            id: commentCount.elementId,
            item: {
                type: EOverlayType.COMMENT,
                position: EOverlayPosition.TOP_RIGHT,
                details: [
                    {
                        count: commentCount.count,
                        unreadCount: commentCount.unreadCount,
                    },
                ],
                settings: {
                    open: false,
                },
            },
        });
    }
    function addAssignee(assigneeEdge: IAssigneeEdge): void {
        addOverlayData({
            id: assigneeEdge.elementId,
            item: {
                type: EOverlayType.ASSIGNEE,
                position: EOverlayPosition.TOP,
                details: [
                    {
                        color: assigneeEdge.color,
                        name: assigneeEdge.assigneeName,
                        type: assigneeEdge.assigneeType,
                        id: assigneeEdge.assigneeId,
                    },
                ],
                settings: {
                    open: false,
                },
            },
        });
    }
    function addAsset(assetEdge: IAssetEdge): void {
        if (
            [EAssetType.DOCUMENT, EAssetType.SYSTEM].includes(assetEdge.assetType) ||
            !Object.values(EAssetType).includes(assetEdge.assetType)
        ) {
            addOverlayData({
                id: assetEdge.fromItemId,
                item: {
                    type: EOverlayType.ASSET,
                    position: EOverlayPosition.BOTTOM,
                    details: [
                        {
                            color: assetEdge.assetColor,
                            name: assetEdge.assetName,
                            type: assetEdge.assetType,
                            id: assetEdge.assetId,
                            icon: assetEdge.assetIcon,
                        },
                    ],
                    settings: {
                        open: false,
                    },
                },
            });
        }
    }
    function addDuration(assigneeEdge: IAssigneeEdge): void {
        addOverlayData({
            id: assigneeEdge.elementId,
            item: {
                type: EOverlayType.DURATION,
                position: EOverlayPosition.TOP,
                details: [
                    {
                        duration: assigneeEdge.duration,
                    },
                ],
                settings: {
                    open: false,
                },
            },
        });
    }
    function addDescription(assigneeEdge: IAssigneeEdge): void {
        addOverlayData({
            id: assigneeEdge.elementId,
            item: {
                type: EOverlayType.DESCRIPTION,
                position: EOverlayPosition.TOP,
                details: [],
                settings: {
                    open: false,
                },
            },
        });
    }
    function addExternalLink(assigneeEdge: IAssigneeEdge): void {
        addOverlayData({
            id: assigneeEdge.elementId,
            item: {
                type: EOverlayType.EXTERNAL_LINK,
                position: EOverlayPosition.TOP,
                details: [
                    {
                        url: assigneeEdge.externalLink,
                    },
                ],
                settings: {
                    open: false,
                },
            },
        });
    }

    return {
        overlaysData,
        addOverlayData,
        clearOverlayData,
        resetStatusCommentByAll,

        addOverlay,
        deleteOverlaysBy,
        clearOverlays,

        createOverlayComment,
        changeStatusCommentByElement,
        chnageDataCommentByElement,

        addAsset,
        addAssignee,
        addComment,
        addDuration,
        addDescription,
        addExternalLink,
    };
});
