<template>
    <Teleport to="body">
        <div class="editor" id="modeler"></div>
    </Teleport>
</template>

<style scoped lang="scss">
.editor {
    position: absolute;
    top: 8.7rem;
    left: 8px;
    bottom: 10px;
    right: 290px;

    &_fullscreen {
        top: 3.6rem;
    }
    &_hiddenSideBar {
        right: 80px;
    }
    &_screenShot {
        top: 5rem;
        right: 0px;
    }
}
</style>
