export enum EOverlayType {
    DRILL_DOWN = "drilldown",
    LINTER = "linter",
    COMMENT = "comment",
    CREATE_COMMENT = "create-comment",
    ASSIGNEE = "assignee",
    ASSET = "asset",
    DURATION = "duration",
    DESCRIPTION = "description",
    EXTERNAL_LINK = "external-link",
}

export enum EOverlayPosition {
    TOP = "top",
    TOP_RIGHT = "topRight",
    BOTTOM = "bottom",
}

export interface IOverlayPosition {
    bottom?: number;
    left?: number;
    top?: number;
    right?: number;
}

export interface IAddOverlayParams {
    type: EOverlayType;
    position?: IOverlayPosition;
    scale?: boolean;
    html: HTMLElement;
}

export interface IOverlayDataItem<T> {
    type: EOverlayType;
    position: EOverlayPosition;
    details: T[];
    settings: {
        open: boolean;
    };
}
