import { httpClient } from "@/shared/api";

import { type ISettingRow } from "..";

export class GlobalSettingsApi {
    static async fetchAll(heartbeatAcknowledged: boolean): Promise<ISettingRow[]> {
        const response = await httpClient.get("/admin/global-settings", {
            headers: {
                "X-Heartbeat-Acknowledged": heartbeatAcknowledged ? "logoutRequired" : null,
            },
        });
        return response.data;
    }
}
