export enum ESidebarNavigationType {
    COMPARE = "COMPARE",
    ARCHITECTURE = "ARCHITECTURE",
    LINKS = "LINKS",
    COMMENTS = "COMMENTS",
    CAMUNDA = "CAMUNDA",
    BCM_FORM = "BCM_FORM",
}
export enum ESidebarNavigationName {
    COMPARE = "Сравнение версий",
    ARCHITECTURE = "Элементы архитектуры",
    LINKS = "Связи бизнес процесса",
    COMMENTS = "Комментарии",
    CAMUNDA = "Панель управления Camunda",
    BCM_FORM = "Свойства группы",
}

export interface ISidebarNavigation {
    icon: string;
    label: ESidebarNavigationName;
    value: ESidebarNavigationType;
    component: any;
}
