<template>
    <div class="flex flex-column">
        <div class="flex flex-column gap-2">
            <MeterGroup v-if="approvalsByDiagram.length > 0" :value="meterData" :max="approvalsByDiagram.length" class="mb-2" />
            <div
                class="flex flex-column gap-2 border-round border-1 p-3"
                :class="getClassCard(approval)"
                v-for="approval of approvalsByDiagram"
            >
                <div class="flex gap-2">
                    <b>Запрошено</b>
                    <span>{{ formatDateTime(approval.createdOn) }}</span>
                </div>
                <div class="flex gap-2">
                    <b>Согласующий</b>
                    <span>{{ approval.approverEmail }}</span>
                </div>
                <div class="flex gap-2">
                    <b>Версия</b>
                    <span>{{ approval.diagramVersion }}</span>
                </div>
                <div class="flex gap-2 align-items-center">
                    <b>Решения</b>
                    <span class="pi" :class="getIconByApproval(approval)"></span>
                    <span class="text-sm text-color-secondary" v-if="approval.status">{{ formatDateTime(approval.approvalTime) }}</span>
                </div>
                <div class="flex gap-2 align-items-start" v-if="approval.comment">
                    <b>Комментарий:</b><span>{{ approval.comment }}</span>
                </div>
                <div class="w-full text-right" v-if="isVisibleDelete(approval)">
                    <Button label="Удалить" size="small" outlined @click="() => deleteApproval(approval)" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from "vue";
import { storeToRefs } from "pinia";

import MeterGroup, { type MeterItem } from "primevue/metergroup";

import { formatDateTime } from "@/shared/lib/utils/date";

import { useApprovalStore, type IApproval, EApprovalStatus } from "@/entities/Approval/model";
import { useSessionStore } from "@/entities/Session";

const approvalStore = useApprovalStore();
const sessionStore = useSessionStore();

const { approvalsByDiagram } = storeToRefs(approvalStore);
const { userData } = storeToRefs(sessionStore);

const props = defineProps({
    diagramId: String,
});
const emits = defineEmits(["deleteApproval"]);

const meterData = computed(() => {
    const data: MeterItem[] = [
        {
            label: "PENDING",
            value: 0,
            color: "#bcc3cd",
            icon: "",
        },
        {
            label: "ACCEPTED",
            value: 0,
            color: "#38b44a",
            icon: "",
        },
        {
            label: "DECLINED",
            value: 0,
            color: "#df382c",
            icon: "",
        },
    ];

    for (let row of approvalsByDiagram.value) {
        const status = row.status || "PENDING";

        const finded = data.find(({ label }) => label === status);
        if (finded) finded.value += 1;
    }

    return data.filter(item => item.value > 0);
});

function getIconByApproval(approval: IApproval): string {
    if (approval.status === EApprovalStatus.ACCEPTED) return "pi-check-circle text-green-500";
    if (approval.status === EApprovalStatus.DECLINED) return "pi-times-circle text-red-500";
    return "pi-clock";
}
function getClassCard(approval: IApproval): string {
    if (approval.status === EApprovalStatus.ACCEPTED) return "border-green-500 bg-green-50";
    if (approval.status === EApprovalStatus.DECLINED) return "border-red-500 bg-red-50";
    return "surface-border";
}
function isVisibleDelete(approval: IApproval): boolean {
    if (approval.status) return false;
    if (approval.createdBy === userData.value.username) return true;
    return false;
}

async function deleteApproval(approval: IApproval) {
    emits("deleteApproval", approval);
}

onMounted(() => {
    if (props.diagramId) {
        approvalStore.fetchAllByDiagramId(props.diagramId);
    }
});
</script>

<style scoped></style>
