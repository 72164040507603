<template>
    <Button
        :icon="PrimeIcons.QUESTION_CIRCLE"
        text
        severity="contrast"
        class="navbar-button"
        v-tooltip.bottom="'Показать справку'"
        @click="openHelp(diagramType)"
    />
</template>

<script setup lang="ts">
import { PrimeIcons } from "primevue/api";

import { useDiagramHelp } from "../model";
import { ShareProcessTypes } from "@/shared/types";

interface IProps {
    diagramType?: ShareProcessTypes.EDiagramType;
}

withDefaults(defineProps<IProps>(), {
    diagramType: ShareProcessTypes.EDiagramType.BPMN,
});

const { openHelp } = useDiagramHelp();
</script>

<style scoped></style>
