export interface IApproval {
    approvalTime?: Date | string;
    approvalTimeStr: string;
    approverEmail: string;
    approverName: string;
    comment?: string;
    createdBy: string;
    createdOn: Date | string;
    createdOnStr: string;
    diagramId: string;
    diagramName: string;
    diagramVersion: number | string;
    id: string;
    status?: EApprovalStatus;
    direction?: any;
    importantComments?: any[];
}

export interface ICreateApproval {
    diagramId: string;
    users: string[];
}

export interface IApprovalList {
    content: IApproval[];
    pageable: IPageableApprovalList;
    totalElements: number;
    totalPages: number;
    last: boolean;
    sort: ISortApprovalList;
    first: boolean;
    numberOfElements: number;
    size: number;
    number: number;
    empty: boolean;
}

export interface IPageableApprovalList {
    sort: ISortApprovalList;
    pageNumber: number;
    pageSize: number;
    offset: number;
    paged: boolean;
    unpaged: boolean;
}

export interface ISortApprovalList {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
}

export enum EViewType {
    USER = "USER",
    TEAM = "TEAM",
}

export enum EApprovalStatus {
    PENDING = "PENDING",
    ACCEPTED = "ACCEPTED",
    DECLINED = "DECLINED",
    COMPLETED = "COMPLETED",
}
export enum EApprovalStatusTitle {
    PENDING = "На согласовании",
    ACCEPTED = "Согласован",
    DECLINED = "Отклонен",
    COMPLETED = "Завершен",
}
export interface IPayloadApprovals {
    view?: EViewType;
    isApprover?: boolean;
    createdOn?: string;
    approvedOn?: string;
    status?: EApprovalStatus;
    diagramName?: string;
    page: number;
    sort?: string;
}

export interface IApprovalsCount {
    incoming: number;
    outgoing: number;
}

export interface IPayloadApprovalsCount {
    isApprover: boolean;
    status: EApprovalStatus;
    view: EViewType;
}

export interface IPayloadCreateApproval {
    diagramIds: string[];
    emails: string[];
}

export interface IPayloadMassMarkingApproval {
    approvalIds: string[];
    status: EApprovalStatus;
    comment: string;
}
