<template>
    <TabView v-model:active-index="activePanelIndex" :pt="{ panelContainer: 'px-0 scroll' }">
        <TabPanel header="Элементы управления">
            <div class="row">
                <div class="flex flex-column gap-3">
                    <b>Клавиатура</b>
                    <div class="flex flex-column">
                        <div
                            class="flex align-items-center gap-2 border-round py-1 px-2 hover:surface-hover"
                            v-for="item of keyboardEvents"
                        >
                            <Divider v-if="item.divider" class="m-0" />
                            <template v-else>
                                <span>{{ item.label }}</span>
                                <Shortcut class="ml-auto">{{ item.key }}</Shortcut>
                            </template>
                        </div>
                    </div>
                </div>
                <Divider layout="vertical" class="m-0"></Divider>
                <div class="flex flex-column justify-content-start gap-3">
                    <b>Панель процесса</b>
                    <div class="flex flex-column">
                        <div class="flex align-items-center gap-2 border-round py-1 px-2 hover:surface-hover" v-for="item of panelElements">
                            <Divider v-if="item.divider" class="m-0" />
                            <template v-else>
                                <Shortcut class="text-base" :icon="item.icon" />
                                <span>{{ item.label }}</span>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </TabPanel>
        <TabPanel header="Описание и примеры элементов BPMN">
            <Block_bpmn_elements v-if="pageData" :data="pageData.blocks?.[0]" :is-element-link="false" />
        </TabPanel>
    </TabView>
</template>

<script setup lang="ts">
import { onMounted, ref, shallowRef } from "vue";
import { storeToRefs } from "pinia";

import { PrimeIcons } from "primevue/api";

import { Shortcut } from "@/shared/ui/shortcut";

import { useInterfaceStore } from "@/entities/Interface";

import { HelpData, HelpDiagramTypes } from "../model";

import { Block_bpmn_elements } from "@/widgets/marketing";

const interfaceStore = useInterfaceStore();
const { pageData } = storeToRefs(interfaceStore);

const activePanelIndex = ref<number>(0);

const keyboardEvents = shallowRef<HelpDiagramTypes.IKeyboardEvent[]>([
    HelpData.HOT_KEYS.undo,
    HelpData.HOT_KEYS.redo,

    { divider: true },

    HelpData.HOT_KEYS.zoom,

    { divider: true },

    HelpData.HOT_KEYS.verticalScroll,
    HelpData.HOT_KEYS.horizontalScroll,

    { divider: true },

    HelpData.HOT_KEYS.groupSelect,

    { divider: true },

    HelpData.HOT_KEYS.toggleAllOverlays,
    HelpData.HOT_KEYS.toggleAllOverlaysCollapse,

    { divider: true },

    HelpData.HOT_KEYS.toggleRoleOverlays,
    HelpData.HOT_KEYS.togglePositionOverlays,
    HelpData.HOT_KEYS.toggleSystemOverlays,
    HelpData.HOT_KEYS.toggleSystemOverlaysCollapse,
    HelpData.HOT_KEYS.toggleDocumentOverlays,
    HelpData.HOT_KEYS.toggleDocumentOverlaysCollapse,

    { divider: true },

    HelpData.HOT_KEYS.toggleDescriptionOverlays,
    HelpData.HOT_KEYS.toggleCommentsOverlays,
    HelpData.HOT_KEYS.toggleDurationOverlays,

    { divider: true },

    HelpData.HOT_KEYS.toggleIncomingConnections,
    HelpData.HOT_KEYS.toggleOutgoingConnections,
]);
const panelElements = shallowRef<HelpDiagramTypes.IPanelElement[]>([
    HelpData.ACTIONS.runTokenSimulation,

    { divider: true },

    HelpData.ACTIONS.fullscreen,

    { divider: true },

    HelpData.ACTIONS.openMiniMap,
    HelpData.ACTIONS.zoomIn,
    HelpData.ACTIONS.zoomOut,
    HelpData.ACTIONS.undo,
    HelpData.ACTIONS.redo,

    { divider: true },

    HelpData.ACTIONS.changeColor,
    HelpData.ACTIONS.copy,
    HelpData.ACTIONS.paste,

    { divider: true },

    HelpData.ACTIONS.qualityAssessment,
    HelpData.ACTIONS.autoNumbering,
    HelpData.ACTIONS.clearAutoNumbering,

    { divider: true },

    HelpData.ACTIONS.showHelp,
    HelpData.ACTIONS.hideDiagramHeader,
]);

onMounted(async () => {
    await interfaceStore.fetchAll("/bpmn/elements", { isSaveSeo: false, isGoToNotFound: false });
});
</script>

<style scoped>
.row {
    display: grid;
    grid-template-columns: 1fr 1rem 1fr;
    gap: 1rem;
}
</style>
