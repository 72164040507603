import ODRenderer from "./ODRenderer";
import TextRenderer from "./TextRenderer";

import PathMap from "./PathMap";

export default {
    __init__: ["odRenderer"],
    odRenderer: ["type", ODRenderer],
    textRenderer: ["type", TextRenderer],
    pathMap: ["type", PathMap],
};
