export function toBase64(input: string): string {
    const uint8Array = new TextEncoder().encode(input);
    const base64String = btoa(String.fromCharCode(...uint8Array));
    return base64String;
}

export function fromBase64(base64String: string): string {
    const binaryString = atob(base64String);
    const uint8Array = new Uint8Array([...binaryString].map(char => char.charCodeAt(0)));
    return new TextDecoder().decode(uint8Array);
}
