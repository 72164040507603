<template>
    <FormColumn title="Вашему домену предоставлен доступ к этим командам">
        <AllowedDomain v-for="item of allowedDomain" :allowedDomain="item" />
    </FormColumn>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";

import { AllowedDomain } from ".";

import { useTeamStore } from "@/entities/Team";

import { FormColumn } from "@/shared/ui/form";

const teamStore = useTeamStore();
const { allowedDomain } = storeToRefs(teamStore);
</script>

<style scoped></style>
