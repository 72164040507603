<template>
    <Button
        :icon="iconButton"
        class="navbar-button"
        severity="contrast"
        :text="!menuSettings.isCheckErrors"
        v-tooltip.bottom="textButton"
        @click="actionMenuStore.toggleMenuSettings('isCheckErrors')"
    />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { storeToRefs } from "pinia";

import { useDiagramActionMenuStore } from "@/entities/Process";
import { PrimeIcons } from "primevue/api";

const actionMenuStore = useDiagramActionMenuStore();

const { menuSettings } = storeToRefs(actionMenuStore);

const iconButton = computed<string>(() => (menuSettings.value.isCheckErrors ? PrimeIcons.TIMES_CIRCLE : PrimeIcons.CHECK_CIRCLE));
const textButton = computed<string>(() => (menuSettings.value.isCheckErrors ? "Убрать отображение ошибок" : "Проверять ошибки"));
</script>

<style scoped></style>
