import { ShareTagTypes } from "@/shared/types";

export interface IGeneralProcess {
    authorUsername: string;
    createdOn: string | Date;
    description: null | string;
    id: string;
    name: string;
    type: EDiagramType;
    tags: ShareTagTypes.ITag[];
    updatedOn: null | string | Date;
    userFolderName: null | string;
    processType: null | EProcessType;
    versionNumber: number;
    you: boolean;
    public: boolean;
    status: EProcessStatus;
    teamFolderId: null | number;
    teamFolderName: null | string;
    favoritesCount: number;
    linkedDiagramId: null | string;
    linkedDiagramName: null | string;
}

export interface IProcess extends IGeneralProcess {
    updatedBy: string;
    lastVersionComment: null | string;
    quality: number;
    secureCreateDate: null | Date;
    secureId: null | string;
    secureType: null | string;
    teamName: string;
    totalCommentsCount: null | number;
    unreadCommentsCountByYou: null | number;
}

export interface IDiagram extends IGeneralProcess {
    autosaveIndex: number;
    body: string;
    falseApprovals: null;
    favored: boolean;
    incommingLinks: null;
    outcommingLinks: null;
    totalApprovals: null | number;
    trueApprovals: null;
    userFolderId: null;
    updateBy: string;
}

export enum EProcessStatus {
    NEW = "NEW",
    IN_PROGRESS = "IN_PROGRESS",
    APPROVAL = "APPROVAL",
    DONE = "DONE",
}

export enum EProcessType {
    ASIS = "ASIS",
    TOBE = "TOBE",
}

export enum EDiagramType {
    BPMN = "BPMN",
    BCM = "BCM",
    BCM2 = "BCM2",
}
