import { some } from "min-dash";

/**
 * Is an element of the given od type?
 *
 * @param  {djs.model.Base|ModdleElement} element
 * @param  {String} type
 *
 * @return {Boolean}
 */
export function is(element, type) {
    var bo = getBusinessObject(element);

    return bo && typeof bo.$instanceOf === "function" && bo.$instanceOf(type);
}

/**
 * Return true if element has any of the given types.
 *
 * @param {Element|ModdleElement} element
 * @param {string[]} types
 *
 * @return {boolean}
 */
export function isAny(element, types) {
    return some(types, function (t) {
        return is(element, t);
    });
}

/**
 * Return the business object for a given element.
 *
 * @param  {djs.model.Base|ModdleElement} element
 *
 * @return {ModdleElement}
 */
export function getBusinessObject(element) {
    return (element && element.businessObject) || element;
}

/**
 * Return the di object for a given element.
 *
 * @param {Element} element
 *
 * @return {ModdleElement}
 */
export function getDi(element) {
    return element.businessObject && element.businessObject.di;
}

export const DEFAULT_GROUP_SIZE = {
    width: 100,
    height: 100,
};
