import { ref } from "vue";
import { defineStore, storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { ERoutesName } from "@/app/providers";
import {
    EViewType,
    EApprovalStatus,
    type IApproval,
    type IApprovalList,
    type IPayloadApprovals,
    type IApprovalsCount,
    type IPayloadApprovalsCount,
    type IPayloadCreateApproval,
    type IPayloadMassMarkingApproval,
} from ".";
import { useInterfaceStore, ETypeView } from "@/entities/Interface";
import { ApprovalApi } from "..";
import { useLoading, useNotification } from "@/shared/lib/composables";

export const useApprovalStore = defineStore("approval", () => {
    const { isLoading, startLoading, finishLoading } = useLoading();
    const { showSuccess, showError } = useNotification();
    const route = useRoute();
    const interfaceStore = useInterfaceStore();
    const { selectedTypeView } = storeToRefs(interfaceStore);

    const approvals = ref<IApproval[]>([]);
    const countApprovals = ref<number>(0);
    const approvalList = ref<IApprovalList>({
        content: [],
        pageable: {
            sort: {
                sorted: false,
                unsorted: false,
                empty: false,
            },
            pageNumber: 0,
            pageSize: 0,
            offset: 0,
            paged: false,
            unpaged: false,
        },
        totalElements: 0,
        totalPages: 0,
        last: false,
        sort: {
            sorted: false,
            unsorted: false,
            empty: false,
        },
        first: false,
        numberOfElements: 0,
        size: 0,
        number: 0,
        empty: false,
    });
    const approvalsCount = ref<IApprovalsCount>({
        incoming: 0,
        outgoing: 0,
    });

    const myApprovalByDiagram = ref<IApproval>();
    const approvalsByDiagram = ref<IApproval[]>([]);

    async function fetchAll(): Promise<void> {
        try {
            startLoading();

            approvals.value = [];
            approvals.value = await ApprovalApi.fetchAllMy();
            countApprovals.value = approvals.value.length;
        } catch (e: any) {
            showError(e.message);
        } finally {
            finishLoading();
        }
    }
    async function fetchMyByDiagramId(diagramId: string): Promise<void> {
        try {
            myApprovalByDiagram.value = undefined;
            const data: IApproval[] = await ApprovalApi.fetchAllMyByDiagramId(diagramId);
            myApprovalByDiagram.value = data[0];
        } catch (e: any) {
            if (e?.status !== 404) {
                showError(e?.message || e);
            }
        }
    }
    async function fetchAllByDiagramId(diagramId: string): Promise<void> {
        try {
            approvalsByDiagram.value = [];
            approvalsByDiagram.value = await ApprovalApi.fetchAllByDiagramId(diagramId);
        } catch (e: any) {
            if (e?.status !== 404) {
                showError(e?.message || e);
            }
        }
    }
    async function getApprovalCount(type: EViewType): Promise<void> {
        try {
            await Promise.all([getApprovalCountIncoming(type), getApprovalCountOutgoing(type)]);
        } catch (e: any) {
            if (e?.status !== 404) {
                showError(e?.message || e);
            }
        }
    }
    async function getApprovalCountIncoming(view: EViewType): Promise<void> {
        try {
            const payload: IPayloadApprovalsCount = {
                isApprover: true,
                status: EApprovalStatus.PENDING,
                view: view,
            };
            approvalsCount.value.incoming = await ApprovalApi.getApprovalCount(payload);
        } catch (e: any) {
            if (e?.status !== 404) {
                showError(e?.message || e);
            }
        }
    }
    async function getApprovalCountOutgoing(view: EViewType): Promise<void> {
        try {
            const payload: IPayloadApprovalsCount = {
                isApprover: false,
                status: EApprovalStatus.PENDING,
                view: view,
            };
            approvalsCount.value.outgoing = await ApprovalApi.getApprovalCount(payload);
        } catch (e: any) {
            if (e?.status !== 404) {
                showError(e?.message || e);
            }
        }
    }
    async function getApproval(payload: IPayloadApprovals): Promise<void> {
        try {
            startLoading();
            approvalList.value = await ApprovalApi.getApproval(payload);
        } catch (e: any) {
            if (e?.status !== 404) {
                showError(e?.message || e);
            }
        } finally {
            finishLoading();
        }
    }
    async function createApproval(payload: IPayloadCreateApproval): Promise<void> {
        try {
            const res = await ApprovalApi.createApproval(payload);
            console.log(res);
            let payloadGetApproval: IPayloadApprovals = {
                page: 0,
                view: selectedTypeView.value == ETypeView.PERSONAL ? EViewType.USER : EViewType.TEAM,
            };
            if (route.name == ERoutesName.APP_APPROVALS_ALL) {
                await getApproval(payloadGetApproval);
            } else if (route.name == ERoutesName.APP_APPROVALS_INCOMING) {
                payloadGetApproval.status = EApprovalStatus.PENDING;
                payloadGetApproval.isApprover = true;
                await getApproval(payloadGetApproval);
            } else {
                payloadGetApproval.status = EApprovalStatus.PENDING;
                payloadGetApproval.isApprover = false;
                await getApproval(payloadGetApproval);
            }
            showSuccess("Согласования отправлены");
        } catch (e: any) {
            if (e?.status !== 404) {
                showError(e?.message || e);
            }
        }
    }
    async function massMarkingApproval(payload: IPayloadMassMarkingApproval): Promise<void> {
        try {
            const res = await ApprovalApi.massMarkingApproval(payload);
            console.log(res);
            let payloadGetApproval: IPayloadApprovals = {
                page: 0,
                view: selectedTypeView.value == ETypeView.PERSONAL ? EViewType.USER : EViewType.TEAM,
            };
            if (route.name == ERoutesName.APP_APPROVALS_ALL) {
                await getApproval(payloadGetApproval);
            } else if (route.name == ERoutesName.APP_APPROVALS_INCOMING) {
                payloadGetApproval.status = EApprovalStatus.PENDING;
                payloadGetApproval.isApprover = true;
                await getApproval(payloadGetApproval);
            } else {
                payloadGetApproval.status = EApprovalStatus.PENDING;
                payloadGetApproval.isApprover = false;
                await getApproval(payloadGetApproval);
            }
            if (payload.status == EApprovalStatus.ACCEPTED) {
                showSuccess("Согласования согласованы");
            } else {
                showSuccess("Согласования отклонены");
            }
        } catch (e: any) {
            if (e?.status !== 404) {
                showError(e?.message || e);
            }
        }
    }
    async function deleteApproval(approvalIds: string[]): Promise<void> {
        try {
            await ApprovalApi.deleteApproval(approvalIds);
            let payloadGetApproval: IPayloadApprovals = {
                page: 0,
                view: selectedTypeView.value == ETypeView.PERSONAL ? EViewType.USER : EViewType.TEAM,
            };
            if (route.name == ERoutesName.APP_APPROVALS_ALL) {
                await getApproval(payloadGetApproval);
            } else if (route.name == ERoutesName.APP_APPROVALS_INCOMING) {
                payloadGetApproval.status = EApprovalStatus.PENDING;
                payloadGetApproval.isApprover = true;
                await getApproval(payloadGetApproval);
            } else {
                payloadGetApproval.status = EApprovalStatus.PENDING;
                payloadGetApproval.isApprover = false;
                await getApproval(payloadGetApproval);
            }
            showSuccess("Согласования удалены");
        } catch (e: any) {
            if (e?.status !== 404) {
                showError(e?.message || e);
            }
        }
    }

    return {
        approvals,
        countApprovals,
        myApprovalByDiagram,
        approvalsByDiagram,
        isLoading,
        approvalList,
        approvalsCount,

        fetchAll,
        fetchMyByDiagramId,
        fetchAllByDiagramId,
        getApprovalCount,
        getApproval,
        createApproval,
        massMarkingApproval,
        deleteApproval,
    };
});
