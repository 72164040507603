import { useLoading, useNotification } from "@/shared/lib/composables";
import { defineStore } from "pinia";
import { ref } from "vue";
import { TeamApi } from "../../";
import type { IContent } from "../../";

export const useAdminTeamStore = defineStore("adminTeamStore", () => {
    const { showError, showSuccess } = useNotification();
    const { isLoading, startLoading, finishLoading } = useLoading();

    const adminTeamList = ref<IContent[]>();

    async function getAdminTeam() {
        try {
            startLoading();
            const res = await TeamApi.getAdminTeam();
            adminTeamList.value = res.content;
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }

    async function promoteToAdmin(memberId: string) {
        try {
            startLoading();
            await TeamApi.promoteToAdmin(memberId);
            showSuccess("Пользователь сделан администратором");
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }
    async function addToTeam(userEmail: string, teamId: string | undefined) {
        try {
            startLoading();
            await TeamApi.addToTeam(userEmail, teamId);
            showSuccess("Пользователь добавлен в команду!");
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }

    async function searchByTeamName(filter: string) {
        try {
            startLoading();
            if (filter.length == 0) {
                getAdminTeam();
            } else {
                adminTeamList.value = await TeamApi.searchByTeamName(filter);
            }
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }
    return {
        adminTeamList,

        getAdminTeam,
        promoteToAdmin,
        addToTeam,
        searchByTeamName,
    };
});
