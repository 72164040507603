<template>
    <div class="flex px-1 align-items-center border-round-md text-900 text-sm" :style="tag.style">
        <span class="p-1">
            {{ tag.text }}
        </span>
        <i
            :class="PrimeIcons.TIMES"
            class="text-xs cursor-pointer text-700 hover:surface-400 hover:text-900 opacity-30 border-round"
            @click="emit('delTag', props.tag.id)"
        ></i>
    </div>
</template>

<script setup lang="ts">
import { PrimeIcons } from "primevue/api";

import { type ITag } from "@/entities/Tag";

const props = defineProps<{ tag: ITag }>();
const emit = defineEmits(["delTag"]);
</script>

<style scoped></style>
