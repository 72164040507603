export default function PriorityOverlay(eventBus, overlays, modeling) {
    // Карта для отслеживания оверлеев
    const overlayIds = new Map();

    // Обновление оверлея
    function updateOverlay(element) {
        const businessObject = element.businessObject;

        if (overlayIds.has(element.id)) {
            // Получаем ID текущего оверлея
            const overlayId = overlayIds.get(element.id);

            // Обновляем содержимое оверлея
            overlays.remove(overlayId);
        }

        const classPriority = {
            low: "text-green-500",
            normal: "text-yellow-500",
            high: "text-red-500",
        };

        const newOverlayId = overlays.add(element, "priority-overlay", {
            position: {
                top: 5,
                right: 34,
            },
            html: `<div class="p-2 bg-white border-round pi pi-star-fill ${classPriority[businessObject.priority]}" title=""></div>`,
        });

        // Сохраняем новый ID
        overlayIds.set(element.id, newOverlayId);
    }

    // Удаление оверлея
    function removeOverlay(element) {
        const overlayId = overlayIds.get(element.id);
        if (overlayId) {
            overlays.remove(overlayId);
            overlayIds.delete(element.id);
        }
    }

    // Обновление оверлея при изменении `priority`
    eventBus.on("element.changed", event => {
        const element = event.element;

        if (element.businessObject && typeof element.businessObject.priority !== "undefined") {
            updateOverlay(element);
        }
    });

    // Удаление оверлея при удалении элемента
    eventBus.on("shape.removed", event => {
        const element = event.element;
        removeOverlay(element);
    });
}

PriorityOverlay.$inject = ["eventBus", "overlays", "modeling"];
