export function getRandomColorTagProcess(colors: string[], usedColor: string[]): string {
    const availableColors: string[] = colors.filter(color => !usedColor.includes(color));
    if (availableColors.length === 0) {
        const letters: string = "0123456789ABCDEF";
        let color: string = "#";
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    const randomIndex = Math.floor(Math.random() * availableColors.length);
    return availableColors[randomIndex];
}
