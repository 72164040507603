import { httpClient } from "@/shared/api";
import type { AxiosResponse } from "axios";
import type { ITag } from "..";
import { type IProcess } from "@/entities/Process";

export class TagApi {
    static async fetchAll(): Promise<ITag[]> {
        const response: AxiosResponse = await httpClient.get("/diagram/tag");
        return response.data;
    }
    static async fetchForFilter(): Promise<ITag[]> {
        const response: AxiosResponse = await httpClient.get("/diagram-search/tag-list");
        return response.data;
    }
    static async updateByDiagramId(diagramId: string, tags: ITag[]): Promise<IProcess> {
        const response: AxiosResponse = await httpClient.post(`/diagram/tag/${diagramId}`, tags);
        return response.data;
    }
}
