<template>
    <Dialog v-model:visible="visible" :header="headerModal" modal :draggable="false" :style="{ width: '35vw' }" position="top">
        <FileUpload
            ref="uploadRef"
            :multiple="isMultiple"
            accept=".bpmn"
            customUpload
            :showUploadButton="false"
            chooseLabel="Выбрать файлы"
            cancelLabel="Очистить"
        >
            <template #empty> <p>Перетащите сюда файлы для загрузки</p></template></FileUpload
        >
        <template #footer>
            <Button label="Отмена" outlined @click="hide" />
            <Button label="Загрузить процессы" @click="upload" :disabled="!hasFiles" :loading="isLoading" />
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";

import Dialog from "primevue/dialog";
import Button from "primevue/button";
import FileUpload, { type FileUploadState } from "primevue/fileupload";

import { useDialog } from "@/shared/lib/composables";
import { readFile } from "@/shared/lib/utils/file";

import type { IDiagram, IProcess, IUploadDiagram } from "@/entities/Process/model";

import { useUploadDiagrams } from "..";
import { ID_FOLDER_DEFAULT } from "@/shared/config";
import type { IPromise, IReadFile } from "@/shared/lib/types";

const { visible, show, hide } = useDialog();
const { isLoading, uploadDiagram, uploadVersion, getNameDigram } = useUploadDiagrams();

interface IUploadDiagramsParams {
    folderId: number | undefined;
    diagramId: string | undefined;
}

const folderId = ref<number>();
const diagramId = ref<string>();
const promise = ref<IPromise | null>();

const uploadRef = ref<FileUploadState>();

const isMultiple = computed(() => !diagramId.value);
const hasFiles = computed(() => {
    const files = uploadRef.value?.files;
    return Array.isArray(files) ? files.length > 0 : !!files;
});
const headerModal = computed(() => (!!diagramId.value ? "Загрузить версию" : "Загружено с массовой загрузкой"));

async function uplaodOne(file: File) {
    const payload: IUploadDiagram = {
        body: null,
        comment: "Загружено с массовой загрузкой",
        description: "Загружено с массовой загрузкой",
        createdOn: null,
        name: "",
        public: true,
        updatedOn: null,
    };

    const data: IReadFile = await readFile(file);
    if (folderId.value && folderId.value !== ID_FOLDER_DEFAULT) {
        payload.folderId = folderId.value;
    }

    payload.body = data.result;
    payload.name = getNameDigram(data.name);

    let uploaded: IProcess | IDiagram | boolean = false;

    if (diagramId.value) {
        uploaded = await uploadVersion(diagramId.value, payload);
    } else {
        uploaded = await uploadDiagram(payload);
    }

    if (uploaded && uploadRef.value?.files) {
        uploadRef.value.files = uploadRef.value.files.filter((item: File) => {
            return item.name !== file.name;
        });
        uploadRef.value.uploadedFiles.push(file);
    }
}
async function upload() {
    const files = uploadRef.value?.files;

    if (files) {
        if (Array.isArray(files)) {
            await Promise.all(
                files.map(async (file: File) => {
                    await uplaodOne(file);
                }),
            );
        } else {
            await uplaodOne(files);
        }
    }

    hide();

    if (promise) promise.value?.resolve(true);
}

function beforeShow(data: IUploadDiagramsParams | null = null, promiseData: IPromise | null = null) {
    if (data) {
        folderId.value = data.folderId;
        diagramId.value = data.diagramId;
    }

    if (promiseData) promise.value = promiseData;
    show();
}

defineExpose({
    show: beforeShow,
});
</script>

<style scoped lang="scss">
@import "primeflex/primeflex.scss";

:deep(.p-fileupload-buttonbar) {
    .p-button {
        @include styleclass("bg-primary-reverse");
    }
}
</style>
