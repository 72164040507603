<template>
    <div class="flex gap-2 flex-wrap">
        <TagProccess v-for="tag of tags" :diagramId="diagramId" :tag="tag" @delTag="delTag" />
        <Button
            :icon="PrimeIcons.PLUS"
            outlined
            class="shadow-none"
            v-tooltip.bottom="'Добавить тег'"
            @click="openMenu"
            :pt="{ root: 'p-1 w-0' }"
        ></Button>
    </div>
    <OverlayPanel ref="panelAddTagRef" class="w-30rem">
        <TagAdd :tags="tags" @addTag="addTag" />
    </OverlayPanel>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { PrimeIcons } from "primevue/api";

import { ITag } from "../model";
import { TagAdd } from ".";

import { TagProccess } from "@/shared/ui/tag";

defineProps<{ tags: ITag[]; diagramId: string }>();
const emits = defineEmits(["addTag", "delTag"]);

const panelAddTagRef = ref();

function openMenu(event: Event): void {
    panelAddTagRef.value.toggle(event);
}
function addTag(tag: ITag): void {
    emits("addTag", tag);
}
function delTag(id: number): void {
    emits("delTag", id);
}
</script>

<style scoped></style>
