export default function PriorityOverlay(eventBus, overlays, modeling) {
    // Карта для отслеживания оверлеев
    const overlayIds = new Map();

    // Обновление оверлея
    function updateOverlay(element) {
        const businessObject = element.businessObject;

        removeOverlay(element);

        if (Object.keys(businessObject.diagramEntity).length === 0) return;

        const statusNames = {
            NEW: "Новый",
            IN_PROGRESS: "В работе",
            APPROVAL: "На согласовании",
            DONE: "Готов",
        };
        const statusClass = {
            NEW: "bg-bluegray-500",
            IN_PROGRESS: "bg-indigo-500",
            APPROVAL: "bg-orange-500",
            DONE: "bg-green-500",
        };

        const { status, name, id, type } = businessObject.diagramEntity;

        const link = new URL(`/app/${type.includes("BCM") ? "bcm" : "diagram"}/${id}`, window.location.origin);

        const width = businessObject.di.bounds.width;

        const newOverlayId = overlays.add(element, "priority-overlay", {
            position: {
                bottom: 33,
                left: 5,
            },
            html: `<div class="px-2 py-1 bg-white border-round flex gap-2 align-items-center text-xs" style="max-width: ${width - 10}px">
                <div class="py-1 px-2 border-round white-space-nowrap text-white ${statusClass[status]}">${statusNames[status]}</div>
                <span class="px-2 py-1 border-round surface-200">${type}</span>
                <a href="${link}" target="_blank" class="link white-space-nowrap text-overflow-ellipsis overflow-hidden" title="${name}">${name}</a>
            </div>`,
        });

        // Сохраняем новый ID
        overlayIds.set(element.id, newOverlayId);
    }

    // Удаление оверлея
    function removeOverlay(element) {
        const overlayId = overlayIds.get(element.id);
        if (overlayId) {
            overlays.remove(overlayId);
            overlayIds.delete(element.id);
        }
    }

    // Обновление оверлея при изменении `priority`
    eventBus.on("element.changed", event => {
        const element = event.element;

        if (element.businessObject && typeof element.businessObject.priority !== "undefined") {
            updateOverlay(element);
        }
    });

    // Удаление оверлея при удалении элемента
    eventBus.on("shape.removed", event => {
        const element = event.element;
        removeOverlay(element);
    });
}

PriorityOverlay.$inject = ["eventBus", "overlays", "modeling"];
