<template>
    <div class="flex gap-2">
        <AutoComplete
            ref="autocomplete"
            v-model="createTagValue"
            optionLabel="text"
            :suggestions="suggestionsTags"
            @complete="searchTag"
            @item-select="addTag"
            @keydown.enter="createTag"
            placeholder="Введите название тега"
            :pt="{
                root: 'w-full',
                input: 'w-full',
                item: 'p-0',
            }"
        >
            <template #option="slotProps">
                <div class="px-4 py-3" :style="slotProps.option.style">
                    {{ slotProps.option.text }}
                </div>
            </template>
            <template #empty>
                <div class="px-4 py-3 text-sm font-italic">
                    Тега не существует.<br />
                    Нажмите <b>Enter</b> чтобы создать тег <b>{{ createTagValue }}</b>
                </div>
            </template>
        </AutoComplete>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import type { AutoCompleteCompleteEvent, AutoCompleteItemSelectEvent } from "primevue/autocomplete";
import { storeToRefs } from "pinia";

import { useTagStore, type ITag } from "../model";

import { debounce } from "@/shared/lib/utils/timer";
import { getRandomColorTagProcess } from "@/shared/lib/utils/color";
import { colorsTag } from "@/shared/constants/colors";

const props = defineProps<{
    tags: ITag[];
}>();
const emits = defineEmits(["addTag"]);

const tagStore = useTagStore();
const { tags } = storeToRefs(tagStore);

const autocomplete = ref();
const tagsProcess = ref<ITag[]>(props.tags);
const suggestionsTags = ref<ITag[]>();
const createTagValue = ref<string>("");

const searchTag = debounce((event: AutoCompleteCompleteEvent): void => {
    if (!event.query.trim().length) {
        suggestionsTags.value = [...tags.value];
    } else {
        const eventToLowerCase = event.query.trim().toLowerCase();
        suggestionsTags.value = tags.value.filter(tag => {
            const x = !tagsProcess.value.some(tagProcess => tag.text === tagProcess.text);
            const y = tag.text.toLowerCase().includes(eventToLowerCase);
            return x && y;
        });
    }
}, 250);
async function addTag(event: AutoCompleteItemSelectEvent): Promise<void> {
    emits("addTag", event.value);
    createTagValue.value = "";
}
async function createTag(): Promise<void> {
    function getUsedColor(): string[] {
        const colors: string[] = [];
        for (let tag of tags.value) {
            if (tag.color && !colors.includes(tag.color)) {
                colors.push(tag.color);
            }
        }
        return colors;
    }

    if (createTagValue.value) {
        const createTagValueLowerCaseTrim = createTagValue.value.toLowerCase().trim();
        const tag = tags.value.find(item => item.text.toLowerCase() === createTagValueLowerCaseTrim);
        if (tag) {
            emits("addTag", tag);
        } else {
            const color: string = getRandomColorTagProcess(colorsTag, getUsedColor());
            const newTag: ITag = {
                text: createTagValue.value,
                id: Math.random(),
                style: "background-color: " + color,
                color: color,
            };
            emits("addTag", newTag);
        }
        createTagValue.value = "";
    }
}

onMounted(() => {
    autocomplete.value.$el.querySelector("input").focus();
});
</script>

<style scoped></style>
