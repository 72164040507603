<template>
    <div class="w-full h-full flex gap-2 p-2 bg-white relative">
        <div class="flex-auto flex flex-column gap-2">
            <slot name="menubar"></slot>
            <div class="modeler flex-auto flex w-full">
                <slot name="editor"></slot>
            </div>
        </div>
        <slot name="right-sidebar"></slot>
    </div>
</template>
