<template>
    <Dialog v-model:visible="visible" header="Сохранение процесса" modal :draggable="false" :style="{ width: '30vw' }" position="top">
        <div class="flex flex-column gap-3">
            <FormColumn title="Название">
                <InputText id="name" v-model="state.name" placeholder="Введите название" autofocus />
            </FormColumn>
            <FormColumn title="Теги">
                <TagProcess :diagramId="diagramId" :tags="state.tags" @delTag="delTag" @addTag="addTag" />
            </FormColumn>
            <FormColumn title="Название папки">
                <template v-if="!isTeamPlan" #label-additional>
                    <FormAdditionalLabel @click="openModalAdvertising">Почему недоступно</FormAdditionalLabel>
                </template>
                <div class="w-full flex gap-2">
                    <FolderSearch v-model="state.folder" class="w-full" />
                    <Button icon="pi pi-plus" outlined @click="createFolder" :disabled="!isTeamPlan" />
                </div>
            </FormColumn>
            <FormColumn v-if="isBPMN" title="Тип процесса">
                <template #label-additional>
                    <span
                        class="pi pi-info-circle p-1 hover:surface-100 border-round"
                        v-tooltip.top="'Укажите тип процесса и связанный процесс, чтобы быстро переключаться между ними на презентациях'"
                    ></span>
                </template>
                <div class="w-full flex align-items-center gap-4">
                    <SelectButton
                        v-model="state.processType"
                        :options="processTypeItems"
                        optionLabel="label"
                        optionValue="value"
                        optionDisabled="disabled"
                        id="type"
                    />
                    <Button
                        v-if="state.processType"
                        text
                        :label="textDuplicateProcess"
                        v-tooltip.top="'Дублировать данный процесс в связанный, чтобы сравнивать визуально'"
                    />
                </div>
            </FormColumn>
            <FormColumn v-if="state.processType" title="Связанный процесс">
                <Dropdown
                    v-model="state.linkedDiagramId"
                    :options="diagramCallActivitySearch"
                    option-label="name"
                    option-value="id"
                    placeholder="Название процесса"
                    :filter="diagramCallActivitySearch.length > 5"
                    showClear
                    filter-icon="pi pi-search"
                    filter-placeholder="Поиск"
                />
            </FormColumn>
            <FormColumn title="Комментарий к версии">
                <Textarea v-model="state.comment" autoResize :rows="1" placeholder="Комментарий к версии..." />
            </FormColumn>
            <Scoring v-if="isBPMN" />
        </div>
        <template #footer>
            <Button label="Отменить" outlined @click="hide" />
            <Button :label="saveButtonText" @click="save" />
        </template>
    </Dialog>

    <CreateFolder ref="createFolderRef" />
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";

import { useDialog } from "@/shared/lib/composables";
import { DEFAULT_DIAGRAM_NAME } from "@/shared/config";
import { FormAdditionalLabel, FormColumn } from "@/shared/ui/form";

import { FolderSearch } from "@/entities/Folder";
import { type ITag, useTagStore, TagProcess } from "@/entities/Tag";
import { useDiagramStore, EProcessType, type IDiagram, type IUploadDiagram, EProcessSecure } from "@/entities/Process";
import { useBpmnModelerStore } from "@/entities/BpmnModeler";
import type { IFolder } from "@/entities/Folder/model";
import { useSessionStore } from "@/entities/Session";
import { BcmModel } from "@/entities/BcmModeler";

import { CreateFolder } from "@/features/Folder/create";
import { useUploadDiagrams } from "@/features/Process/upload-diagrams";
import { Scoring, useScoringStore } from "@/features/Diagram-editor/scoring";
import { useUpgradePlan } from "@/features/Payment/upgrade-plan";

interface IState {
    name: string;
    folder?: number;
    tags: ITag[];
    processType?: EProcessType;
    comment?: string;
    linkedDiagramId?: string;
}

const { openModalAdvertising } = useUpgradePlan();
const diagramStore = useDiagramStore();
const bpmnModelerStore = useBpmnModelerStore();
const tagStore = useTagStore();
const scoringStore = useScoringStore();
const { score } = storeToRefs(scoringStore);
const sessionStore = useSessionStore();
const { isTeamPlan, isAuth } = storeToRefs(sessionStore);

const { visible, show, hide } = useDialog();
const { diagram, diagramId, isBPMN, diagramPermissionByMe, diagramCallActivitySearch } = storeToRefs(diagramStore);
const { uploadVersion } = useUploadDiagrams();

const { getDiagramData: getBcmDiagramData } = BcmModel.useBcmModeler();

const state = ref<IState>({
    name: diagram.value?.name || DEFAULT_DIAGRAM_NAME,
    tags: [],
});
const createFolderRef = ref();

const processTypeItems = ref([
    { label: EProcessType.ASIS, value: EProcessType.ASIS },
    { label: EProcessType.TOBE, value: EProcessType.TOBE },
]);

const textDuplicateProcess = computed(
    () => "Дублировать в" + ` ${state.value.processType === EProcessType.ASIS ? EProcessType.TOBE : EProcessType.ASIS}`,
);
const saveButtonText = computed<string>(() => {
    if (diagram.value) {
        if (diagramPermissionByMe.value === EProcessSecure.EDIT && isAuth.value) {
            return "Сохранить как версию";
        }
        if (diagramPermissionByMe.value !== EProcessSecure.EDIT && isAuth.value) {
            return "Сделать копию в свои процессы";
        }
    }
    return "Сохранить";
});

function delTag(id: number): void {
    state.value.tags = state.value.tags.filter(item => item.id != id);
}
function addTag(tag: ITag): void {
    const tagLowerCaseTrim: string = tag.text.toLowerCase().trim();
    if (!state.value.tags.some(item => item.text.toLowerCase() === tagLowerCaseTrim)) {
        state.value.tags.push(tag);
    }
}

async function createFolder() {
    const result: null | IFolder = await new Promise((resolve, reject) => {
        createFolderRef.value.show(null, { promiseValue: { resolve, reject }, forSearch: true });
    });

    if (result) {
        state.value.folder = result.id;
    }
}
async function save() {
    const { body, svg } = isBPMN.value ? await bpmnModelerStore.getDiagramData() : await getBcmDiagramData();

    const unsavedDiagram: IUploadDiagram = {
        name: state.value.name,
        public: true,
        comment: state.value.comment,
        body,
        svg,
        folderId: state.value.folder,
        tags: state.value.tags,
        processType: state.value.processType,
        linkedDiagramId: state.value.linkedDiagramId,
        quality: isBPMN ? score.value : undefined,
    };

    if (diagram.value) {
        const data = await uploadVersion(diagram.value.id, unsavedDiagram);
        diagramStore.setDiagram(data as unknown as IDiagram);
    } else {
        await diagramStore.createDiagram(unsavedDiagram);
    }

    hide();
}
async function beforeShow() {
    if (diagram.value) {
        state.value.folder = Number(diagram.value.teamFolderId) > 0 ? Number(diagram.value.teamFolderId) : undefined;
        state.value.tags = diagram.value.tags ? diagram.value.tags : [];
        state.value.processType = diagram.value.processType || undefined;
        state.value.linkedDiagramId = diagram.value.linkedDiagramId || undefined;
        state.value.comment = diagram.value.description || undefined;
    }

    const promises = [tagStore.fetchAll()];

    if (!diagramCallActivitySearch.value) {
        promises.push(diagramStore.fetchCallActivitySearch());
    }

    await Promise.all(promises);

    show();
}

defineExpose({
    show: () => beforeShow(),
});
</script>

<style scoped></style>
