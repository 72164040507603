import { ref } from "vue";
import { defineStore } from "pinia";
import { useLocalStorage } from "@/shared/lib/browser";
import { COLLAPSED_SIDEBAR_MENU_KEY } from "@/shared/config";
interface IMenuSettings {
    isToken: boolean;
    isFullscreen: boolean;
    isCamundaPanel: boolean;
    isMinimap: boolean;
    isCheckErrors: boolean;
    isHiddenMenu: boolean;
    isHiddenSideBar: boolean;
    [key: string]: boolean;
}

export const useDiagramActionMenuStore = defineStore("diagram-action-menu", () => {
    const { setLSValue, value } = useLocalStorage<boolean>(COLLAPSED_SIDEBAR_MENU_KEY);

    const menuSettings = ref<IMenuSettings>({
        isCamundaPanel: false,
        isCheckErrors: false,
        isFullscreen: false,
        isHiddenMenu: false,
        isMinimap: false,
        isToken: false,
        isHiddenSideBar: value,
    });

    function toggleMenuSettings(key: string): void {
        if (key in menuSettings.value) menuSettings.value[key] = !menuSettings.value[key];
    }

    function toggleMinimap(): void {
        document.querySelector(".djs-minimap")?.classList.toggle("hidden");
        toggleMenuSettings("isMinimap");
    }
    function toggleFullscreen(): void {
        if (document.fullscreenElement) {
            document.querySelector(".editor")?.classList.remove("editor_fullscreen");
            document.querySelector(".p-menubar")?.classList.remove("hidden");

            const element = document.querySelector(".diagram-container");
            if (element) {
                element.classList.remove("h-screen");
                element.classList.add("content");
            }

            document.exitFullscreen();
        } else {
            document.body.requestFullscreen();
            document.querySelector(".editor")?.classList.add("editor_fullscreen");
            document.querySelector(".p-menubar")?.classList.add("hidden");

            const element = document.querySelector(".diagram-container");
            if (element) {
                element.classList.add("h-screen");
                element.classList.remove("content");
            }
        }

        toggleMenuSettings("isFullscreen");
    }
    function toggleHeaderVisible(): void {
        toggleMenuSettings("isHiddenMenu");

        if (menuSettings.value.isHiddenMenu) {
            document.querySelector(".editor")?.classList.add("editor_fullscreen");
        } else {
            document.querySelector(".editor")?.classList.remove("editor_fullscreen");
        }
    }

    function toggleSideBarVisible(): void {
        toggleMenuSettings("isHiddenSideBar");

        setLSValue(menuSettings.value.isHiddenSideBar);

        if (menuSettings.value.isHiddenSideBar) {
            document.querySelector(".editor")?.classList?.add("editor_hiddenSideBar");
        } else {
            document.querySelector(".editor")?.classList?.remove("editor_hiddenSideBar");
        }
    }

    function resetMenu(): void {
        for (const key in menuSettings.value) {
            menuSettings.value[key] = false;
        }
    }

    return {
        toggleMinimap,
        toggleFullscreen,
        toggleHeaderVisible,
        toggleSideBarVisible,

        resetMenu,

        menuSettings,
        toggleMenuSettings,
    };
});
