import { defineStore } from "pinia";
import { computed, ref } from "vue";

import Modeler from "bpmn-js/lib/Modeler";
import gridModule from "diagram-js-grid";
import CamundaBpmnModdle from "camunda-bpmn-moddle/resources/camunda.json";
import { BpmnPropertiesPanelModule, BpmnPropertiesProviderModule, CamundaPlatformPropertiesProviderModule } from "bpmn-js-properties-panel";
import minimapModule from "diagram-js-minimap";
import TokenSimulationModule from "bpmn-js-token-simulation";
import SimulationSupportModule from "bpmn-js-token-simulation/lib/simulation-support";

import { qaExtention } from "@/entities/Process";
import { CustomContextPad, ResizeTask, StormReplaceElementBehaviour, BpmnKeyboardBindings, CustomTranslate } from "@/entities/BpmnModeler";

export const useBpmnModelerStore = defineStore("bpmn-modeler", () => {
    const modeler = ref();

    const modelerCanvas = computed(() => (modeler.value ? modeler.value.get("canvas") : null));
    const modelerEventBus = computed(() => modeler.value.get("eventBus"));
    const modelerKeyboard = computed(() => modeler.value.get("keyboard"));
    const modelerModeling = computed(() => modeler.value.get("modeling"));
    const modelerSelection = computed(() => (modeler.value ? modeler.value.get("selection") : null));
    const modelerOverlays = computed(() => modeler.value.get("overlays"));
    const modelerElementRegistry = computed(() => (modeler.value ? modeler.value.get("elementRegistry") : null));
    const modelerContextPad = computed(() => modeler.value.get("contextPad"));
    const modelerZoomScroll = computed(() => modeler.value.get("zoomScroll"));
    const simulationSupport = computed(() => modeler.value.get("simulationSupport"));

    function initiateModeler() {
        modeler.value = new Modeler({
            container: "#modeler",
            propertiesPanel: {
                parent: "#properties",
            },
            moddleExtensions: { qa: qaExtention, camunda: CamundaBpmnModdle },
            additionalModules: [
                ResizeTask,
                minimapModule,
                gridModule,
                BpmnPropertiesPanelModule,
                BpmnPropertiesProviderModule,
                CamundaPlatformPropertiesProviderModule,
                TokenSimulationModule,
                SimulationSupportModule,
                CustomContextPad,
                StormReplaceElementBehaviour,
                BpmnKeyboardBindings,
                CustomTranslate,
            ],
            taskResizingEnabled: true,
        });
    }

    async function saveXML(): Promise<string> {
        const data = await modeler.value.saveXML({ format: true });
        return data.xml;
    }
    async function saveSVG(): Promise<string> {
        const data = await modeler.value.saveSVG({ format: true });
        return data.svg;
    }
    async function getDiagramData() {
        const result = await Promise.all([saveXML(), saveSVG()]);

        const body = result[0];
        const svg = btoa(unescape(encodeURIComponent(result[1])));

        return {
            body,
            svg,
        };
    }

    return {
        modeler,
        modelerEventBus,
        modelerKeyboard,
        modelerModeling,
        modelerSelection,
        modelerOverlays,
        modelerElementRegistry,
        modelerContextPad,
        modelerCanvas,
        modelerZoomScroll,
        simulationSupport,
        initiateModeler,
        getDiagramData,
        saveXML,
        saveSVG,
    };
});
