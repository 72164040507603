import { httpClient } from "@/shared/api";
import {
    EViewType,
    type IApproval,
    type ICreateApproval,
    type IApprovalList,
    type IPayloadApprovals,
    type IPayloadApprovalsCount,
    type IPayloadCreateApproval,
    type IPayloadMassMarkingApproval,
} from "../model";
import type { Axios, AxiosResponse } from "axios";

export class ApprovalApi {
    static async fetchAllMy(): Promise<IApproval[]> {
        const response: AxiosResponse = await httpClient.get("/approval/my");
        return response.data;
    }
    static async fetchAllMyByDiagramId(diagramId: string): Promise<IApproval[]> {
        const response: AxiosResponse = await httpClient.get(`/approval/${diagramId}`);
        return response.data;
    }
    static async fetchAllByDiagramId(diagramId: string): Promise<IApproval[]> {
        const response: AxiosResponse = await httpClient.get(`/approval/all/${diagramId}`);
        return response.data;
    }
    static async setDecision(approval: IApproval): Promise<void> {
        await httpClient.post("/approval/decision", approval);
    }
    static async create(payload: ICreateApproval): Promise<void> {
        await httpClient.post("/approval", payload);
    }
    static async delete(approvalId: string): Promise<void> {
        await httpClient.delete(`/approval/${approvalId}`);
    }
    static async getApprovalCount(params: IPayloadApprovalsCount): Promise<number> {
        const response: AxiosResponse = await httpClient.get("/approvals/count", { params });
        return response.data;
    }
    static async getApproval(params: IPayloadApprovals): Promise<IApprovalList> {
        const response: AxiosResponse = await httpClient.get("/approvals", { params });
        return response.data;
    }
    // any потому что заготовки и бэк не готов
    static async createApproval(payload: IPayloadCreateApproval): Promise<any> {
        const response: AxiosResponse = await httpClient.post("/approvals", payload);
        return response.data;
    }
    static async massMarkingApproval(payload: IPayloadMassMarkingApproval): Promise<any> {
        const response: AxiosResponse = await httpClient.post("/approvals/decision", payload);
        return response.data;
    }
    static async deleteApproval(approvalIds: string[]): Promise<any> {
        const params = { approvalIds };
        const response: AxiosResponse = await httpClient.delete("/approvals/", { params });
        return response.data;
    }
}
