<template>
    <div v-if="route.query.screenshot" class="flex align-items-center justify-content-center">
        <h1>{{ diagramName }}</h1>
    </div>
    <div class="w-full h-screen flex flex-column">
        <template v-if="!menuSettings.isHiddenMenu && !route.query.screenshot">
            <AppHeader v-if="isAuth" class="fixed top-0 left-0 right-0 z-2">
                <template v-if="diagramAccessAllowed">
                    <ProcessHeaderSkeleton v-if="loading" />
                    <ProcessHeaderInfo v-else />
                </template>
                <template v-else>
                    <h2>Нет доступа</h2>
                </template>
            </AppHeader>
            <MainHeader v-else>
                <template v-if="diagramAccessAllowed">
                    <ProcessHeaderSkeleton v-if="loading" />
                    <ProcessHeaderInfo v-else />
                </template>
                <template v-else>
                    <h2>Нет доступа</h2>
                </template>
            </MainHeader>
        </template>
        <div
            class="diagram-container w-full flex h-full"
            :class="{
                content: isAuth && !menuSettings.isHiddenMenu,
                'content_no-header': menuSettings.isHiddenMenu,
                'pt-6': !isAuth && !diagramAccessAllowed,
            }"
        >
            <main class="overflow-auto w-full">
                <template v-if="loading">
                    <div class="w-full text-center">
                        <ProgressSpinner />
                    </div>
                </template>
                <template v-else>
                    <RouterView v-if="diagramAccessAllowed"></RouterView>
                    <ProcessAccessDenied v-else :diagramid="diagramId" />
                </template>
            </main>
        </div>
    </div>

    <CommentMenu />

    <Galleria
        class="max-h-screen"
        v-model:visible="visible"
        v-model:active-index="customIndex"
        :value="files"
        :responsiveOptions="responsiveOptions"
        :numVisible="9"
        containerStyle="max-width: 50%"
        :circular="true"
        :fullScreen="true"
        :showItemNavigators="true"
        :pt="{
            itemWrapper: 'h-60vh',
            thumbnailContainer: 'justify-content-center',
            thumbnailItemsContainer: 'w-auto',
            thumbnailItems: 'gap-3',
            thumbnailItem: 'w-5rem h5rem',
        }"
    >
        <template #item="{ item }">
            <img :src="item.url" style="width: 100%; display: block; height: 70vh" />
        </template>
        <template #thumbnail="{ item }">
            <img :src="item.preview" style="display: block" />
        </template>
    </Galleria>
</template>

<script setup lang="ts">
import { onBeforeMount, computed, watch } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

import { setTitlePage } from "@/shared/lib/browser";
import { useGalleria } from "@/shared/lib/composables";

import { useDiagramStore, ProcessHeaderSkeleton, ProcessAccessDenied, useDiagramActionMenuStore } from "@/entities/Process";
import { useSessionStore } from "@/entities/Session";
import { useTeamStore } from "@/entities/Team";
import { useCommentStore } from "@/entities/Comment";
import { useBpmnModelerOverlayStore } from "@/entities/BpmnModeler";
import { useAssigneeStore } from "@/entities/Assignee";
import { usePaymentStore } from "@/entities/Payment";

import { CommentMenu } from "@/features/Comment/menu";

import { AppHeader, MainHeader } from "@/widgets/header";
import { ProcessHeaderInfo } from "@/widgets/app/process";
import { useAssetByDiagramStore } from "@/entities/Asset";

const diagramStore = useDiagramStore();
const sessionStore = useSessionStore();
const teamStore = useTeamStore();
const commentStore = useCommentStore();
const bpmnOverlay = useBpmnModelerOverlayStore();
const assigneeStore = useAssigneeStore();
const assetByDiagramStore = useAssetByDiagramStore();
const actionMenuStore = useDiagramActionMenuStore();
const paymentStore = usePaymentStore();

const { files, responsiveOptions, visible, customIndex } = useGalleria();

const { diagram, diagramAccessAllowed, isLoading: loading, isBPMN, diagramName } = storeToRefs(diagramStore);
const { isAuth } = storeToRefs(sessionStore);
const { menuSettings } = storeToRefs(actionMenuStore);

const route = useRoute();

const diagramId = computed<undefined | string>(() => route.params.id as string);

async function fetchData(): Promise<void> {
    diagramStore.resetDiagram();
    bpmnOverlay.clearOverlayData();

    if (diagramId.value) {
        await diagramStore.fetchById(diagramId.value);
    }

    const promises: Promise<any>[] = [teamStore.fetchSettings(), paymentStore.getCurrentPlan()];

    if (diagramId.value) {
        promises.push(commentStore.getCommentsBy(diagramId.value));

        if (isBPMN.value) {
            promises.push(
                commentStore.getCountByDiagram(diagramId.value),
                assigneeStore.fetchByDiagramId(diagramId.value),
                assetByDiagramStore.fetchByDiagramId(diagramId.value),
                assetByDiagramStore.fetchCustomByDiagramId(diagramId.value),
            );
            if (!route.query.screenshot) {
                promises.push(diagramStore.fetchOverlaySettingsById(diagramId.value));
            }
        }
    }

    await Promise.all(promises);

    if (diagram.value) {
        setTitlePage(diagram.value.name);
    }
}

watch(
    () => route.params.id,
    async (newValue, oldValue) => {
        if (newValue && oldValue) {
            await fetchData();
        }
    },
);

watch([files, customIndex], () => {
    if (files.value.length <= customIndex.value) {
        customIndex.value = 0;
    }
});

onBeforeMount(() => {
    fetchData();
});
</script>

<style lang="scss" scoped>
.content {
    margin-top: 5rem;
    min-height: calc(100vh - 5rem);
}
.content_no-header {
    margin-top: 0rem;
    min-height: 100vh;
}
</style>
