import { httpClient } from "@/shared/api";
import type { AxiosResponse } from "axios";
import type {
    ITeam,
    ITeamListSearch,
    ITeamSetting,
    IGetAdminTeam,
    IContent,
    ISendInvitedColleague,
    IPayloadChangeRights,
    IDiagramOption,
    IMyTemplate,
    IPayloadUpdateAllowedDomain,
    IPayloadSaveNewSettingsQuality,
    ITeamForm,
    IGroup,
    IPayloadRenameGroup,
    IAllowedDomain,
    IPayloadDownloadTemplate,
} from "..";

export class TeamApi {
    static async create(payload: ITeamForm): Promise<void> {
        await httpClient.post("/team", payload);
    }
    static async fetchForFilter(): Promise<ITeamListSearch[]> {
        const response: AxiosResponse = await httpClient.get("/diagram-search/team-list");
        return response.data;
    }
    static async fetchMy(): Promise<ITeam> {
        const response: AxiosResponse = await httpClient.get("/team");
        return response.data;
    }
    static async fetchSettings(): Promise<ITeamSetting[]> {
        const response: AxiosResponse = await httpClient.get("/team/settings");
        return response.data;
    }
    static async getAdminTeam(): Promise<IGetAdminTeam> {
        const response: AxiosResponse = await httpClient.get("/admin/teams");
        return response.data;
    }
    static async promoteToAdmin(memberId: string): Promise<IContent> {
        const response: AxiosResponse = await httpClient.post("/admin/promoteToAdmin?memberId=" + memberId);
        return response.data;
    }
    static async addToTeam(userEmail: string, teamId: string | undefined): Promise<IContent> {
        const response: AxiosResponse = await httpClient.post("/admin/addUserToTeam?userEmail=" + userEmail + "&teamId=" + teamId);
        return response.data;
    }
    static async searchByTeamName(filter: string): Promise<IContent[]> {
        const response: AxiosResponse = await httpClient.get("/admin/team/search?name=" + filter);
        return response.data;
    }
    static async makeAuthorProcessesParticipants(payload: IPayloadChangeRights): Promise<void> {
        await httpClient.post("/team/changeOwnership", payload);
    }
    static async changeRightsParticipants(payload: IPayloadChangeRights): Promise<ITeam> {
        const response: AxiosResponse = await httpClient.post("/team/changeMembersRights/", payload);
        return response.data;
    }
    static async deleteParticipants(teamId: string, userId: string): Promise<void> {
        await httpClient.delete(`/team/${teamId}/member/${userId}`);
    }
    static async addUserTeam(teamId: string, payload: ISendInvitedColleague): Promise<void> {
        await httpClient.post("/team/" + teamId + "/member", payload);
    }
    static async getOnlyDiagrams(): Promise<IDiagramOption[]> {
        const response: AxiosResponse = await httpClient.get("/diagram/only");
        return response.data;
    }
    static async getMyTemplates(): Promise<IMyTemplate[]> {
        const response: AxiosResponse = await httpClient.get("/document-template");
        return response.data;
    }
    static async deleteTemplate(id: string): Promise<void> {
        await httpClient.delete("/document-template?templateId=" + id);
    }
    static async saveTemplate(payload: any): Promise<IMyTemplate> {
        const response: AxiosResponse = await httpClient.post("/document-template?templateId=", payload);
        return response.data;
    }
    static async updateSettingsTeam(payload: IPayloadUpdateAllowedDomain): Promise<ITeam> {
        const response: AxiosResponse = await httpClient.post("/team/update", payload);
        return response.data;
    }
    static async saveNewSettingsQuality(payload: IPayloadSaveNewSettingsQuality[]): Promise<ITeamSetting[]> {
        const response: AxiosResponse = await httpClient.post("/team/settings", payload);
        return response.data;
    }
    static async getAccessGroup(): Promise<IGroup[]> {
        const response: AxiosResponse = await httpClient.get("/groups");
        return response.data;
    }
    static async addUserGroup(id: string, membersId: string[]): Promise<IGroup> {
        const response: AxiosResponse = await httpClient.post(`/groups/${id}/members`, { members: membersId });
        return response.data;
    }
    static async deleteUserGroup(id: string, membersId: string): Promise<IGroup> {
        const response: AxiosResponse = await httpClient.delete(`/groups/${id}/members`, { data: { members: [membersId] } });
        return response.data;
    }
    static async deleteGroup(id: string): Promise<void> {
        await httpClient.delete(`/groups/${id}`);
    }
    static async deletePrivilege(id: string, privilege: string): Promise<IGroup> {
        const response: AxiosResponse = await httpClient.delete(`/groups/${id}/privileges`, { data: { privileges: [privilege] } });
        return response.data;
    }
    static async addPrivilege(id: string, privilege: string): Promise<IGroup> {
        const response: AxiosResponse = await httpClient.post(`/groups/${id}/privileges`, { privileges: [privilege] });
        return response.data;
    }
    static async createAccessGroup(name: string, members: string[]): Promise<IGroup> {
        const response: AxiosResponse = await httpClient.post(`/groups`, { name, members: members, privileges: [] });
        return response.data;
    }
    static async renameGroup(group: IPayloadRenameGroup): Promise<IGroup> {
        const response: AxiosResponse = await httpClient.put(`/groups/${group.id}`, group);
        return response.data;
    }
    static async getAllowedDomain(allowedDomain: string): Promise<IAllowedDomain[]> {
        const response: AxiosResponse = await httpClient.get("/team/allowedTeams?domain=" + allowedDomain);
        return response.data;
    }
    static async joinAllowedDomainTeam(id: string): Promise<void> {
        await httpClient.post("/team/" + id + "/join");
    }
    static async downloadTemplate(payload: IPayloadDownloadTemplate): Promise<File> {
        const response: AxiosResponse = await httpClient.post("/document-template/process/generate", payload);
        return response.data;
    }
}
