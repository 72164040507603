export enum ERoutesName {
    HOME = "Home",
    SIGN_UP = "SignUp",
    LOGIN = "Login",
    LOGIN_YANDEX = "LoginYandex",
    REQUEST_PASSWORD_RESTORE = "RequestPasswordRestore",
    PASSWORD_RESTORE = "PasswordRestore",
    NOT_FOUND = "NotFound",
    CHECK_EMAIL = "CheckEmail",
    TEAM = "Team",
    MARKETING = "Marketing",
    CONTACT_SALES = "ContactSales",
    BLOG = "Blog",
    BLOG_SUBTITLE = "BlogSabtitle",
    ARTICLE = "Article",
    BPMN_ELEMENT = "BpmnElement",

    APP_BCM_DIAGRAM = "AppBcmDiagram",
    APP_BCM_DIAGRAM_ITEM = "AppBcmDiagramItem",

    APP_DIAGRAM = "AppDiagram",
    APP_DIAGRAM_ITEM = "AppDiagramItem",
    APP_DIAGRAM_ITEM_VERSION = "AppDiagramItemVersion",
    APP_DIAGRAM_EMBEDDED_CARD = "AppDiagramEmbeddedCard",
    APP_SEARCH = "AppSearch",
    APP_MY_PROCESSES = "AppMyProcesses",
    APP_TEAM_PROCESS = "AppTeamProcess",
    APP_SHARE_WITH_ME = "AppShareWithMe",
    APP_FOLDER = "AppFolder",
    APP_FOLDER_EMBEDDED = "AppFolderEmbedded",
    APP_PROCESS_MAP = "AppProcessMap",
    APP_VERTEX = "AppVertex",
    APP_BASKET = "AppBasket",
    APP_DIFF_PLANT = "AppPlantDiff",
    APP_DIFF_DIAGRAM = "AppDiagramDiff",
    APP_GENERATE_INVOICE = "AppGenerateInvoice",
    APP_ADMIN = "AppAdmin",
    APP_TEAM = "AppTeam",
    APP_TEAM_PARTICIPANTS = "AppTeamParticipants",
    APP_TEAM_SETTING = "AppTeamSetting",
    APP_TEAM_IMPORT = "AppTeamImport",
    APP_TEAM_REGULATION = "AppTeamRegulation",

    APP_TEAM_ASSIGNEES = "AppAssignees",
    APP_TEAM_ASSIGNEE = "AppAssignee",
    APP_TEAM_ASSETS = "AppAssets",
    APP_TEAM_ASSET = "AppAsset",
    APP_TEAM_ACCESS_GROUP = "AppTeamAccessGroup",

    APP_APPROVALS_INCOMING = "AppApprovalsIncoming",
    APP_APPROVALS_OUTGOING = "AppApprovalsOutgoing",
    APP_APPROVALS_ALL = "AppApprovalsAll",

    APP_GUESTS_MY = "AppGuestsMy",
    APP_GUESTS_TEAM = "AppGuestsTeam",

    APP_PROFILE = "AppProfile",

    APP_UPGRADE_PLAN = "AppUpgrade",

    APP_BILLING = "AppBilling",

    ACTIVATION = "Activataion",
}
