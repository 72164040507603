import { computed, reactive, ref, watch } from "vue";

import { PrimeIcons } from "primevue/api";

import { EOverlaySettingsName, IOverlayCollapse } from "@/entities/Process";

interface IOverlayList {
    name?: string;
    hotKey?: number;
    key?: string;
    divider?: boolean;
    icon?: string;
    collapse?: boolean;
}

const visibilityOverlays = computed<IOverlayList[]>(() => {
    return [
        { name: getNameSetting("assignees"), hotKey: 1, key: "assignees" },
        { name: getNameSetting("positions"), hotKey: 2, key: "positions" },
        { name: getNameSetting("systems"), hotKey: 3, key: "systems", icon: PrimeIcons.DATABASE, collapse: true },
        { name: getNameSetting("documents"), hotKey: 4, key: "documents", icon: PrimeIcons.FILE, collapse: true },
        { divider: true },
        { name: getNameSetting("description"), hotKey: 5, key: "description", icon: PrimeIcons.FILE_EDIT },
        { name: getNameSetting("comments"), hotKey: 6, key: "comments" },
        { name: getNameSetting("duration"), hotKey: 7, key: "duration", icon: PrimeIcons.CLOCK },
        { divider: true },
        { name: getNameSetting("incomingLinks"), hotKey: 8, key: "incomingLinks" },
        { name: getNameSetting("links"), hotKey: 9, key: "links" },
    ];
});

const collapseOverlays = reactive<IOverlayCollapse>({
    systems: false,
    documents: false,
    custom: false,
});

function getNameSetting(name: unknown): string {
    const key = name as keyof typeof EOverlaySettingsName;
    return EOverlaySettingsName[key];
}

export function useToggleOverlays() {
    const collapseOverlaysLocale = reactive<IOverlayCollapse>({
        systems: false,
        documents: false,
        custom: false,
    });

    function changeCollapseOverlay(key: string, status?: boolean): void {
        if (key in collapseOverlaysLocale) {
            collapseOverlaysLocale[key] = status === undefined ? !collapseOverlaysLocale[key] : status;
        }
    }

    function changeCollapseOverlaysGlobal(key: string, status?: boolean): void {
        if (key in collapseOverlays) {
            collapseOverlays[key] = status === undefined ? !collapseOverlays[key] : status;
        }
    }

    watch(collapseOverlays, value => {
        Object.keys(value).map(key => {
            if (collapseOverlaysLocale[key] !== value[key]) {
                changeCollapseOverlay(key, value[key]);
            }
        });
    });

    return {
        visibilityOverlays,
        collapseOverlays,
        collapseOverlaysLocale,

        changeCollapseOverlay,
        changeCollapseOverlaysGlobal,

        getNameSetting,
    };
}
