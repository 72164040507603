<template>
    <div v-if="!!myApprovalByDiagram" class="flex flex-column">
        <div class="flex flex-column gap-3">
            <span class="text-lg font-medium">{{ myApprovalByDiagram.createdBy }} запросил ваше согласование</span>
            <small class="text-sm text-color-secondary">
                {{ formatDateTime(myApprovalByDiagram.createdOn) }}, версия {{ myApprovalByDiagram.diagramVersion }}
            </small>
            <div class="flex flex-column gap-2">
                <label>Комментарий к решению</label>
                <Textarea v-model="comment" auto-resize style="resize: none" />
                <div class="w-full flex gap-2 justify-content-end">
                    <Button label="Отклонить" size="small" outlined @click="() => setApprovalDecision(EApprovalStatus.DECLINED)" />
                    <Button
                        label="Согласовать"
                        size="small"
                        outlined
                        severity="success"
                        @click="() => setApprovalDecision(EApprovalStatus.ACCEPTED)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";

import { formatDateTime } from "@/shared/lib/utils/date";

import { ApprovalModel } from "@/entities/Approval";

import { useSetDecision } from "..";
import { EApprovalStatus } from "@/entities/Approval/model";

const { setDecision } = useSetDecision();

const approvalStore = ApprovalModel.useApprovalStore();
const { myApprovalByDiagram } = storeToRefs(approvalStore);

const props = defineProps({
    diagramId: { type: String },
});

const comment = ref<string>("");

async function setApprovalDecision(status: EApprovalStatus) {
    if (myApprovalByDiagram.value) {
        myApprovalByDiagram.value.status = status;
        myApprovalByDiagram.value.comment = comment.value;

        await setDecision(myApprovalByDiagram.value);
        if (props.diagramId) {
            await approvalStore.fetchMyByDiagramId(props.diagramId);
            await approvalStore.fetchAllByDiagramId(props.diagramId);
        }

        comment.value = "";
    }
}

onMounted(() => {
    if (props.diagramId) {
        approvalStore.fetchMyByDiagramId(props.diagramId);
    }
});
</script>

<style scoped></style>
