export function createURL(origin: string, pathURL: string, params = {}): string {
    if (!origin || !pathURL) {
        throw new Error("Ошибка при создании URL - отсутствуют параметры origin или path");
    }

    const url = new URL(pathURL, origin);

    try {
        url.search = new URLSearchParams(params).toString();
    } catch (error: any) {
        throw new Error(`Ошибка при создании URL: ${error.message}`);
    }

    return url.toString();
}
