import { computed } from "vue";
import { storeToRefs } from "pinia";

import { useLocalStorage } from "@/shared/lib/browser";
import { CLIPBOARD_BPMN_KEY } from "@/shared/config";

import { useBpmnModelerStore } from "@/entities/BpmnModeler";
import { useNotification } from "@/shared/lib/composables";

export function useCopyPaste() {
    const modelerStore = useBpmnModelerStore();
    const { modelerSelection, modeler } = storeToRefs(modelerStore);
    const { showSuccess } = useNotification();

    const modelerModdle = computed(() => (modeler.value ? modeler.value.get("moddle") : null));
    const modelerCopyPaste = computed(() => (modeler.value ? modeler.value.get("copyPaste") : null));
    const modelerClipboard = computed(() => (modeler.value ? modeler.value.get("clipboard") : null));

    function copy(): void {
        const { setLSValue } = useLocalStorage(CLIPBOARD_BPMN_KEY);

        const elements = modelerSelection.value.get();
        const tree = modelerCopyPaste.value.copy(elements);
        setLSValue(tree);

        showSuccess("Скопировано");
    }
    function paste(): void {
        const { value } = useLocalStorage(CLIPBOARD_BPMN_KEY);

        const parsedCopy = JSON.parse(JSON.stringify(value), createReviver(modelerModdle.value));
        modelerClipboard.value.set(parsedCopy);
        modelerCopyPaste.value.paste();
    }

    function createReviver(moddle: any): any {
        const elCache: { [key: string]: any } = {};

        return function (key: string, object: any) {
            if (typeof object === "object" && typeof object.$type === "string") {
                const objectId = object.id;

                if (objectId && elCache[objectId]) {
                    return elCache[objectId];
                }

                const type = object.$type;
                const attrs = Object.assign({}, object);

                delete attrs.$type;

                const newEl = moddle.create(type, attrs);

                if (objectId) {
                    elCache[objectId] = newEl;
                }

                return newEl;
            }

            return object;
        };
    }

    return {
        copy,
        paste,
    };
}
