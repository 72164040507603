import { assign, isArray } from "min-dash";

import { hasPrimaryModifier } from "diagram-js/lib/util/Mouse";

/**
 * A provider for od elements context pad.
 */
export default function ContextPadProvider(config, injector, eventBus, contextPad, modeling, rules, translate) {
    config = config || {};

    contextPad.registerProvider(this);

    this._contextPad = contextPad;

    this._modeling = modeling;

    this._rules = rules;
    this._translate = translate;

    if (config.autoPlace !== false) {
        this._autoPlace = injector.get("autoPlace", false);
    }

    eventBus.on("create.end", 250, function (event) {
        let context = event.context,
            shape = context.shape;

        if (!hasPrimaryModifier(event) || !contextPad.isOpen(shape)) {
            return;
        }

        let entries = contextPad.getEntries(shape);

        if (entries.replace) {
            entries.replace.action.click(event, shape);
        }
    });
}

ContextPadProvider.$inject = ["config.contextPad", "injector", "eventBus", "contextPad", "modeling", "rules", "translate"];

ContextPadProvider.prototype.getContextPadEntries = function (element) {
    const { _rules: rules, _modeling: modeling, _translate: translate } = this;

    let actions = {};

    if (element.type === "label") {
        return actions;
    }

    createDeleteEntry(actions);

    return actions;

    function removeElement() {
        modeling.removeElements([element]);
    }

    function createDeleteEntry(actions) {
        // delete element entry, only show if allowed by rules
        let deleteAllowed = rules.allowed("elements.delete", {
            elements: [element],
        });

        if (isArray(deleteAllowed)) {
            // was the element returned as a deletion candidate?
            deleteAllowed = deleteAllowed[0] === element;
        }

        if (deleteAllowed) {
            assign(actions, {
                delete: {
                    group: "edit",
                    className: "bpmn-icon-trash",
                    title: translate("Remove"),
                    action: {
                        click: removeElement,
                    },
                },
            });
        }
    }
};
