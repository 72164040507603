<template>
    <div class="flex flex-column w-5rem px-2 gap-2 border-200 surface-50 border-round border-1 align-items-center justify-content-start">
        <div class="w-full py-1 border-bottom-1 border-200 text-center">
            <slot name="toggle-sidebar"></slot>
        </div>
        <slot name="body"></slot>
    </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
