import { useBpmnModelerOverlayStore } from "..";
import { useExternalSettingsModal } from "@/entities/Process";

class CustomContextPad {
    create;
    elementFactory;
    translate;
    autoPlace;
    contextPad;
    static $inject: string[];

    constructor(config: any, contextPad: any, create: any, elementFactory: any, injector: any, translate: any) {
        this.create = create;
        this.elementFactory = elementFactory;
        this.translate = translate;
        this.contextPad = contextPad;

        if (config.autoPlace !== false) {
            this.autoPlace = injector.get("autoPlace", false);
        }

        contextPad.registerProvider(this);
    }

    getContextPadEntries() {
        const bpmnModelerOverlayStore = useBpmnModelerOverlayStore();
        const { toggleOpenModal } = useExternalSettingsModal();

        return {
            "edit-description": {
                group: "activity",
                className: "text-xl pi pi-pencil",
                title: "Дополнительные настройки",
                action: {
                    click: () => {
                        toggleOpenModal(true);
                    },
                },
            },
            comments: {
                group: "activity",
                className: "text-xl pi pi-comment",
                title: "Комментрировать",
                action: {
                    click: (event: any, element: any) => {
                        this.contextPad.close();
                        bpmnModelerOverlayStore.createOverlayComment(element);
                    },
                },
            },
        };
    }
}

CustomContextPad.$inject = ["config", "contextPad", "create", "elementFactory", "injector", "translate"];

export default {
    __init__: ["CustomContextPad"],
    CustomContextPad: ["type", CustomContextPad],
};
