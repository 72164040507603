import { useDialog } from "primevue/usedialog";

import { ConnectToAllovedDomain } from "../ui";

import { useTeamStore } from "@/entities/Team";
import { useSessionStore } from "@/entities/Session";

import { MODAL_PARAMS } from "@/shared/constants/modal";

export function useConnectToTeam() {
    const teamStore = useTeamStore();
    const sessionStore = useSessionStore();
    const dialog = useDialog();

    async function canJoinTeam(): Promise<void> {
        const res: boolean = await teamStore.getAllowedDomain();
        if (res) {
            dialog.open(ConnectToAllovedDomain, {
                props: {
                    header: "Выберите команду",
                    ...MODAL_PARAMS,
                },
            });
        }
        sessionStore.setIsFirstLogin(false);
    }

    return {
        canJoinTeam,
    };
}
