import { computed, ref, watch } from "vue";
import { defineStore, storeToRefs } from "pinia";
import { useRoute } from "vue-router";

import { ID_FOLDER_DEFAULT } from "@/shared/config";
import { useNotification, useLoading } from "@/shared/lib/composables";

import {
    EProcessSecure,
    EProcessStatus,
    EProcessStatusName,
    type IDiagram,
    type IOnePorcessResponse,
    type IProcess,
    type IProcessQuery,
    type IProcessResponse,
    type ISearchProcessQuery,
    type ISearchProcessResponse,
} from "..";
import { ProcessApi } from "../../api";
import type { TProcessStatus } from "../../lib";

import { useSessionStore } from "@/entities/Session";
import { ETypeView, useInterfaceStore } from "@/entities/Interface";
import { usePaymentStore } from "@/entities/Payment";

import { ERoutesName } from "@/app/providers";

export const useProcessStore = defineStore("process", () => {
    const { showError } = useNotification();
    const { isLoading: loading, startLoading, finishLoading } = useLoading();
    const route = useRoute();

    const sessionStore = useSessionStore();
    const { isTeamPlan } = storeToRefs(sessionStore);
    const interfaceStore = useInterfaceStore();
    const paymentStore = usePaymentStore();

    const diagram = ref<IDiagram>();
    const diagramPermissionByMe = ref<EProcessSecure>();
    const diagramAccessAllowed = ref(true);

    const processes = ref<IProcess[]>([]);
    const totalProcesses = ref<number>(0);
    const activeProcess = ref<IProcess>();
    const selectedProcesses = ref<IProcess[]>([]);

    const hasProcesses = computed(() => processes.value.length > 0);
    const countSelectedProcesses = computed(() => selectedProcesses.value.length);
    const hasSelectedProcesses = computed(() => countSelectedProcesses.value > 0);

    const apiKey = computed(() => route.query.apiKey as string);

    const processStatuses = computed(() =>
        Object.keys(EProcessStatus).map(item => {
            const key = item as TProcessStatus;

            const label = EProcessStatusName[key];
            const value = EProcessStatus[key];

            return {
                label,
                value,
            };
        }),
    );

    function setActiveProcess(process: IProcess) {
        activeProcess.value = process;
    }
    function clearSelectedProcesses() {
        selectedProcesses.value = [];
    }
    function resetDiagram() {
        diagram.value = undefined;
        diagramPermissionByMe.value = EProcessSecure.EDIT;
    }
    function localeDeleteByIds(ids: string[]) {
        processes.value = processes.value.filter(item => !ids.includes(item.id));
    }
    function localeChangePublicByIds(ids: string[]) {
        for (let process of processes.value) {
            if (ids.includes(process.id)) {
                process.public = !process.public;
            }
        }
    }
    function setStatusById(id: string, status: EProcessStatus) {
        const finded = processes.value.find(item => item.id === id);
        if (finded) {
            finded.status = status;
        }
        if (diagram.value) {
            diagram.value.status = status;
        }
    }

    async function fetchById(id: string) {
        try {
            startLoading();
            const data: IOnePorcessResponse = await ProcessApi.fetchById(id);
            diagram.value = data.diagram;
            diagramPermissionByMe.value = data.permission;

            diagramAccessAllowed.value = true;
        } catch (e: any) {
            if (e.status === 403) {
                diagramAccessAllowed.value = false;
            } else {
                showError(e?.message || e);
            }
        } finally {
            finishLoading();
        }
    }

    async function fetchProcessesBy(page: number = 0, folderId?: number | undefined): Promise<void> {
        try {
            startLoading();
            const selectedTypeView = interfaceStore.selectedTypeView;
            const enterpriseAccess = paymentStore.isEnterprisePlan;

            const isTeam = selectedTypeView === ETypeView.TEAM;
            const isDirectAccess = route.name === ERoutesName.APP_SHARE_WITH_ME;
            processes.value = [];

            const query: IProcessQuery = {
                team: isTeam,
                directAccess: isDirectAccess,
                enterpriseAccess: enterpriseAccess,
                apiKey: apiKey.value,
                page: page,
            };

            if (folderId) {
                if (folderId && folderId !== ID_FOLDER_DEFAULT) {
                    query.folderId = folderId;
                } else query.emptyFolder = true;
            }

            // Защита от получения даннх, если нет подписки
            if ((folderId || query.team) && !isTeamPlan.value) return;

            const data: IProcessResponse = await ProcessApi.fetchAll(query);
            processes.value = data.returnDiagrams;
            totalProcesses.value = data.totalElements;
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }

    async function fetchUnpublic(): Promise<void> {
        try {
            startLoading();
            processes.value = [];
            processes.value = await ProcessApi.fetchUnpublic();
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }

    async function search(query: ISearchProcessQuery): Promise<void> {
        try {
            startLoading();
            processes.value = [];

            const data: ISearchProcessResponse = await ProcessApi.search(query);
            processes.value = data.content;
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }
    function updateProcess(process: IProcess): void {
        const index: number = processes.value.findIndex(item => item.id === process.id);
        if (index !== -1) {
            processes.value[index] = process;
        }
    }

    watch(route, () => {
        clearSelectedProcesses();
    });

    return {
        loading,
        diagram,
        diagramPermissionByMe,
        processes,
        hasProcesses,
        activeProcess,
        selectedProcesses,
        countSelectedProcesses,
        hasSelectedProcesses,
        processStatuses,
        diagramAccessAllowed,

        setStatusById,
        search,
        setActiveProcess,
        clearSelectedProcesses,
        localeDeleteByIds,
        localeChangePublicByIds,
        fetchById,
        fetchUnpublic,
        resetDiagram,

        totalProcesses,
        fetchProcessesBy,

        updateProcess,
    };
});
