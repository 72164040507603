import { storeToRefs } from "pinia";

import { useCommentStore } from "@/entities/Comment";
import { useBpmnModelerOverlayStore, useBpmnModelerStore } from "@/entities/BpmnModeler";
import { IAssigneeEdge, useAssigneeStore } from "@/entities/Assignee";
import { IAssetEdge, useAssetByDiagramStore } from "@/entities/Asset";
import { useDiagramStore } from "@/entities/Process";
import { useToggleOverlays } from "@/features/Diagram-editor/toggle-overlays";
import { computed } from "vue";
import { useRoute } from "vue-router";

export function useInstallationOverlays() {
    const route = useRoute();
    const commentStore = useCommentStore();
    const bpmnOverlayStore = useBpmnModelerOverlayStore();
    const bpmnModelerStore = useBpmnModelerStore();
    const assigneeStore = useAssigneeStore();
    const assetByDiagramStore = useAssetByDiagramStore();
    const diagramStore = useDiagramStore();
    const { collapseOverlays, changeCollapseOverlaysGlobal } = useToggleOverlays();

    const { commentIndicators } = storeToRefs(commentStore);
    const { modelerElementRegistry } = storeToRefs(bpmnModelerStore);
    const { assigneesByDiagram } = storeToRefs(assigneeStore);
    const { assetsByDiagram, customAssetsByDiagram } = storeToRefs(assetByDiagramStore);
    const { overlaySettings } = storeToRefs(diagramStore);

    const isEnabledAssets = computed<boolean>(() => {
        return (
            overlaySettings.value?.documents ||
            overlaySettings.value?.systems ||
            customAssetsByDiagram.value.some(item => item.overlaySettings)
        );
    });

    async function installationOverlays(): Promise<void> {
        await bpmnOverlayStore.clearOverlays(true);

        const {
            assignees: enabledAssignees,
            duration: enabledDuration,
            description: enabledDescription,
            comments: enabledComments,
        } = overlaySettings.value;

        const overlayHandlers = [
            {
                condition: enabledComments,
                data: commentIndicators.value,
                getElementId: (row: any) => row.elementId,
                handler: (row: any) => bpmnOverlayStore.addComment(row),
            },
            {
                condition: enabledAssignees || enabledDuration || enabledDescription,
                data: assigneesByDiagram.value,
                getElementId: (assignee: IAssigneeEdge) => assignee.elementId,
                handler: (assignee: IAssigneeEdge) => {
                    if (enabledAssignees && assignee.assigneeId) bpmnOverlayStore.addAssignee(assignee);
                    if (enabledDuration && assignee.duration) bpmnOverlayStore.addDuration(assignee);
                    if (enabledDescription && assignee.description) bpmnOverlayStore.addDescription(assignee);
                    if (enabledDescription && assignee.externalLink) bpmnOverlayStore.addExternalLink(assignee);
                },
            },
            {
                condition: isEnabledAssets.value,
                data: assetsByDiagram.value,
                getElementId: (asset: IAssetEdge) => asset.fromItemId,
                handler: (asset: IAssetEdge) => bpmnOverlayStore.addAsset(asset),
            },
        ];

        for (const { condition, data, getElementId, handler } of overlayHandlers) {
            if (!condition) continue;

            for (const item of data) {
                const elementId = getElementId(item);
                const element = modelerElementRegistry.value.get(elementId);
                if (element) {
                    handler(item);
                }
            }
        }
    }

    async function setOverlays(): Promise<void> {
        if (route.query.overlays) {
            const urlOverlays = route.query.overlays?.toString() as string;
            const overlays = JSON.parse(atob(urlOverlays));

            for (const key in overlaySettings.value) {
                overlaySettings.value[key] = overlays.visible[key];
            }

            await installationOverlays();

            for (const key in collapseOverlays) {
                changeCollapseOverlaysGlobal(key, overlays.collaps[key]);
            }
        } else {
            await installationOverlays();
        }

        if (route.query.screenshot) {
            window.overlayReady = true;
        }
    }

    return {
        installationOverlays,
        setOverlays,
    };
}
