export interface IRequestLogin {
    username: string;
    password: string;
    locale: string;
    anonymous_id?: string;
}

export interface IRequestRegistration {
    username: string;
    email?: string;
    password: string;
    inviteId?: string | null;
    recaptchaToken?: string;
    locale?: string | null;
    ad_attribution?: string | null;
    create_team?: boolean;
    anonymous_id?: string;
}

export interface IRequestRestorePassword {
    restoreToken: string;
    email: string;
    newPassword: string;
}

interface IRole {
    authority: string;
}
interface ILicense {
    membersCount: number;
    on: boolean;
    validTo: Date | string;
    licenseType: string;
    enableCloudMetrics: boolean;
    enableHelpDesk: boolean;
    enableBaseRegister: boolean;
    ssoLoginText: string;
}
export interface IUserData {
    accessToken: string;
    roles: IRole[];
    username: string;
    inTeam: boolean;
    teamId: string | null;
    teamName: string | null;
    plan: string;
    fullAccess: boolean;
    validTo: Date | string;
    enabledIntegration: boolean | null;
    license: ILicense | null;
    teamRights: string | null;
    userId: string | null;
}

export class UserDataDto implements IUserData {
    accessToken: string;
    roles: IRole[];
    username: string;
    inTeam: boolean;
    teamId: string | null;
    teamName: string | null;
    plan: string;
    fullAccess: boolean;
    validTo: string | Date;
    enabledIntegration: boolean | null;
    license: ILicense | null;
    teamRights: string | null;
    userId: string | null;

    constructor(data: any) {
        this.accessToken = data?.accessToken || "";
        this.roles = data?.roles || [];
        this.username = data?.username || "";
        this.inTeam = data?.inTeam || false;
        this.teamId = data?.teamId || null;
        this.teamName = data?.teamName || null;
        this.plan = data?.plan || "";
        this.fullAccess = data?.fullAccess || false;
        this.validTo = data?.validTo || "";
        this.enabledIntegration = data?.enableBaseRegister || false;
        this.license = data?.license || null;
        this.teamRights = data?.teamRights || null;
        this.userId = data?.userId || "";
    }
}

export interface IPayloadClickStream {
    anonId: string;
    pageUrl: string;
}
