import { ref } from "vue";
import { defineStore } from "pinia";
import { TagApi, type ITag } from "..";

import { type IProcess, useProcessStore } from "@/entities/Process";

import { useLoading, useNotification } from "@/shared/lib/composables";

export const useTagStore = defineStore("tag", () => {
    const processStore = useProcessStore();
    const { showError } = useNotification();
    const { isLoading, startLoading, finishLoading } = useLoading();

    const tagByFilter = ref<ITag[]>([]);
    const tags = ref<ITag[]>([]);

    async function fetchForFilter(): Promise<void> {
        try {
            startLoading();
            tagByFilter.value = await TagApi.fetchForFilter();
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }
    async function fetchAll(): Promise<void> {
        try {
            startLoading();

            tags.value = [];
            tags.value = await TagApi.fetchAll();
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }
    async function updateByDiagramId(diagramId: string, tags: ITag[]) {
        try {
            const res: IProcess = await TagApi.updateByDiagramId(diagramId, tags);
            processStore.updateProcess(res);
            await fetchAll();
            return true;
        } catch (e: any) {
            showError(e?.message || e);
            return false;
        }
    }

    return {
        isLoading,
        tagByFilter,
        tags,
        fetchForFilter,
        fetchAll,
        updateByDiagramId,
    };
});
