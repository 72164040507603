import { assign } from "min-dash";

import Moddle from "./BcmModdle";

import descriptors from "./resources/od.json" with { type: "json" };
import diDescriptors from "./resources/odDi.json" with { type: "json" };
import dcDescriptors from "./resources/dc.json" with { type: "json" };

var packages = {
    od: descriptors,
    odDi: diDescriptors,
    dc: dcDescriptors,
};

export default function (additionalPackages, options) {
    var pks = assign({}, packages, additionalPackages);

    return new Moddle(pks, options);
}
