<template>
    <div class="p-4 border-2 border-round-lg surface-border">
        <div class="flex flex-column gap-2">
            <b class="text-md">
                <Tag :icon="PrimeIcons.USERS" class="surface-100 text-primary text-md">
                    <span class="no-wrap">
                        {{ allowedDomain.countOfUsers }}
                    </span>
                </Tag>
                {{ allowedDomain.name }}
            </b>
            <div class="flex flex-column gap-2">
                <div class="flex gap-2 align-items-center">
                    <Avatar :username="allowedDomain.admin" :size-img="ESizeImg.SMALL" />
                    <span class="text-400">{{ allowedDomain.admin }}</span>
                </div>
                <span class="text-base">
                    <Tag :icon="PrimeIcons.USER_PLUS" class="surface-100 text-primary">
                        <span class="no-wrap">
                            {{ countOfFreeSeats }}
                        </span>
                    </Tag>
                </span>
            </div>
            <span v-tooltip.bottom="{ value: 'В этой команде нет свободных мест', disabled: allowedDomain.countOfFreeSeats >= 1 }">
                <Button
                    label="Присоединиться"
                    outlined
                    @click="joinAllowedDomainTeam"
                    :disabled="allowedDomain.countOfFreeSeats <= 0"
                    class="w-full"
                />
            </span>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, inject, type Ref } from "vue";
import { PrimeIcons } from "primevue/api";

import { useTeamStore, type IAllowedDomain } from "@/entities/Team";

import { Avatar } from "@/shared/ui/avatar";
import { ESizeImg } from "@/shared/lib/types";
import { declensionNumerals } from "@/shared/lib/utils/formater";

const props = defineProps<{
    allowedDomain: IAllowedDomain;
}>();

const teamStore = useTeamStore();

const dialogRef = inject<Ref>("dialogRef");

const countOfFreeSeats = computed<string>(() => {
    return (
        props.allowedDomain.countOfFreeSeats +
        declensionNumerals(props.allowedDomain.countOfFreeSeats, [" cвободное место", " cвободных места", " cвободных мест"])
    );
});

async function joinAllowedDomainTeam(): Promise<void> {
    await teamStore.joinAllowedDomainTeam(props.allowedDomain.teamId);

    if (dialogRef) {
        dialogRef.value.close();
    }
}
</script>

<style scoped lang="scss">
.p-button-disabled-tooltip {
    pointer-events: none;
}
</style>
