import { DEFAULT_GROUP_SIZE } from "../../../util/ModelUtil";

export default class CustomResizeBehavior {
    constructor(eventBus) {
        eventBus.on("resize.start", 1500, event => {
            const context = event.context;

            context.minDimensions = {
                width: DEFAULT_GROUP_SIZE.width,
                height: DEFAULT_GROUP_SIZE.height,
            };
        });
    }
}

CustomResizeBehavior.$inject = ["eventBus"];
