import { computed, ref } from "vue";

import { useLoading, useNotification } from "@/shared/lib/composables";

import { ProcessApi } from "@/entities/Process/api";
import { EProcessSecure, type IProcessSecure, type IProcessSetSecure } from "@/entities/Process/model";

export function useSecureProcess() {
    const { isLoading, startLoading, finishLoading } = useLoading();
    const { showError, showSuccess } = useNotification();

    const secureAccessLevel = ref([
        { label: "Просмотр", value: EProcessSecure.VIEW },
        { label: "Редактирование", value: EProcessSecure.EDIT },
    ]);

    const sourceSharedList = ref<IProcessSecure[]>([]);
    const sharedList = ref<IProcessSecure[]>([]);
    const isAnonymous = ref<boolean>(false);

    const hasSharedList = computed<boolean>(() => sharedList.value.length > 0);
    const hasChanged = computed<boolean>(() => {
        for (let i = 0; i < sourceSharedList.value.length; i++) {
            if (sourceSharedList.value[i].settings !== sharedList.value[i].settings) return true;
        }
        return false;
    });

    function setSharedList(data: IProcessSecure[]): void {
        const indexAnonymous = data.findIndex(item => item.anonymousSettings);
        if (indexAnonymous >= 0) {
            isAnonymous.value = true;
            data.splice(indexAnonymous, 1);
        } else {
            isAnonymous.value = false;
        }

        sharedList.value = data;
        sourceSharedList.value = JSON.parse(JSON.stringify(data));
    }
    function cancelChanges(): void {
        setSharedList(sourceSharedList.value);
    }

    async function fetchAllByFolderId(folderId: number): Promise<void> {
        try {
            startLoading();

            const data: IProcessSecure[] = await ProcessApi.fetchSecureByFolderId(folderId);
            setSharedList(data);
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }
    async function fetchAllByProcessId(procesId: string): Promise<void> {
        try {
            startLoading();

            const data: IProcessSecure[] = await ProcessApi.fetchSecure(procesId);
            setSharedList(data);
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }
    async function saveChanges(): Promise<void> {
        for (let i = 0; i < sourceSharedList.value.length; i++) {
            if (sourceSharedList.value[i].settings !== sharedList.value[i].settings) {
                const current: IProcessSecure = sharedList.value[i];
                await setSecure({
                    settings: current.settings || EProcessSecure.VIEW,
                    folderId: current.folderId,
                    userEmails: [current.userEmail],
                    anonymousSettings: current.anonymousSettings,
                    diagramId: current.diagramId,
                });
            }
        }
    }
    async function setSecure(payload: IProcessSetSecure): Promise<void> {
        try {
            startLoading();

            await ProcessApi.setSecure(payload);
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
            showSuccess("Права доступа установлены");
        }
    }
    async function deleteSecure(secureId: string): Promise<void> {
        try {
            startLoading();

            await ProcessApi.deleteSecure(secureId);
            const data = sharedList.value.filter(item => item.id !== secureId);
            setSharedList(data);
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }

    return {
        isLoading,
        sharedList,
        hasSharedList,
        hasChanged,
        secureAccessLevel,
        isAnonymous,
        fetchAllByFolderId,
        fetchAllByProcessId,
        setSecure,
        deleteSecure,
        cancelChanges,
        saveChanges,
    };
}
