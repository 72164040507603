export function extractIds(data: any): any[] {
    if (!data) return [];
    if (Array.isArray(data)) {
        return data.map(item => item.id);
    }
    return [data.id];
}

export function isSameArrays(firstArray: any[], secondaryArray: any[]): boolean {
    if (firstArray.length !== secondaryArray.length) return false;

    for (const element of firstArray) {
        if (!secondaryArray.every(item => item === element)) return false;
    }

    return true;
}

export function populateObject(target: { [key: string]: any }, source: { [key: string]: any }): void {
    Object.keys(target).forEach(key => {
        if (typeof target[key] === "object" && target[key] !== null && !Array.isArray(target[key])) {
            // Если свойство — объект, рекурсивно копируем данные
            if (source[key] && typeof source[key] === "object") {
                populateObject(target[key], source[key]);
            }
        } else if (source[key] !== undefined) {
            // Копируем значение, если оно существует в источнике
            target[key] = source[key];
        }
    });
}
