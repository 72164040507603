import { ShareHttpResponse } from "../types";
import { httpClient } from "./http-client";

import { AxiosResponse } from "axios";

export class FileApi {
    static async deleteById(fileId: string) {
        await httpClient.delete(`/files/${fileId}`);
    }
    static async upload(payload: FormData): Promise<ShareHttpResponse.IUploadedFile> {
        const response: AxiosResponse = await httpClient.post("/files", payload);
        return response.data;
    }
}
