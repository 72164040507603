export const API_URL = import.meta.env.VITE_API_URL;
export const BASE_URL = import.meta.env.VITE_BASE_URL;
export const APP_NAME = "StormBPMN";
export const TOKEN_KEY = "user-token";
export const USER_DATA_KEY = "user-data";
export const AD_ATTRIBUTION_KEY = "ad_attribution";
export const PROCESS_VIEW_KEY = "process-view";
export const TYPE_VIEW_KEY = "type-view";
export const LAST_ROUTER_NAME_KEY = "last-router-name";
export const CLIPBOARD_BPMN_KEY = "clipboard";
export const CLIPBOARD_BCM_KEY = "clipboard-bcm";
export const SELECTED_FOLDER_KEY = "selected-folder";
export const OPENED_SIDEBAR_MENU_KEY = "opened-sidebar-menu";
export const COLLAPSED_SIDEBAR_MENU_KEY = "collapsed-sidebar-menu";
export const FIRST_LOGIN_KEY = "is-first-login";
export const ANONYMOUS_ID_KEY = "anonymous-id";

export const YANDEX_CAPCHA_URL = "https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-with-polyfills-latest.js";
export const YANDEX_SUGGEST_TOKEN_URL = "https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-token-with-polyfills-latest.js";
export const YANDEX_CLIENT_ID = "079f48428b814741955d6b068da5488e";
export const YANDEX_METRIKA_ID = "97684282";

export const ID_FOLDER_DEFAULT = 999999999;
export const ID_FOLDER_UNPUBLIC = -1;

export const DEFAULT_DIAGRAM_NAME = "Черновик";
export const MAX_COUNT_BY_AUTONUMERATE = 200;

export const API_DIRECTUS = "https://cms.stormbpmn.com/items/";
export const IMG_DIRECTUS = "https://cms.stormbpmn.com/assets/";

export const SENTRY_DSN = "https://b53df9cfa1833cd8bfd7a66b42df8e6c@sentry.stormbpmn.com/5";
export const SENTRY_ENEBLED = import.meta.env.PROD;

export const TOKEN_DADATA = "026f163e1c27c7d8fe8626857a318db1f97cc4cd";
export const DADATA_CAPCHA_URL = "https://suggestions.dadata.ru/suggestions/api/4_1/rs";

export const DOWNLOAD_NAME = "СЧЕТ_STORMBPMN";

export const MAUTIC_URL = "https://marketing.stormbpmn.com";

export * as PrimeLocale from "./prime-locale";
export * as ModelerConfig from "./modeler";

declare global {
    interface Window {
        overlayReady: boolean;
    }
}
