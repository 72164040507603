import { type IDiagramShort } from "@/entities/Process";

export interface IGroupGeneral {
    name: string;
    description: string;
    level: EGroupLevel;
    priority: EGroupPriority;
    diagramEntity: undefined | IDiagramShort;
}

export interface IGroupForm extends IGroupGeneral {
    color: string;
    fromDiagram?: string;
}

export enum EGroupLevel {
    STRATEGY = "strategy",
    CONTROL = "control",
    EXECUTION = "execution",
}
export enum EGroupPriorityValue {
    low = "Низкий",
    normal = "Средний",
    high = "Высокий",
}

export enum EGroupPriority {
    LOW = "low",
    NORMAL = "normal",
    HIGH = "high",
}
export enum EGroupLevelValue {
    strategy = "Стратегия",
    control = "Контроль",
    execution = "Исполнение",
}
