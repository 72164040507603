import { defineStore } from "pinia";

import { useNotification } from "@/shared/lib/composables";
import { computed, ref } from "vue";
import type { IPlan, IUpgradePrice, IPaymentUpgrade, IPayloadUpgradePrice, IFeatures, IFeaturesValid } from "./types";
import { PaymentApi } from "../api";
import { EPlan } from "@/shared/lib/types";

export const usePaymentStore = defineStore("payment", () => {
    const { showError, showInfo } = useNotification();

    const currentPlanData = ref<IPlan>({
        externalId: null,
        createdOn: null,
        validTo: null,
        amount: 0,
        teamName: null,
        card: null,
        invoiceStatus: null,
        membersCount: null,
        currentMembersCount: null,
        paymentPlan: EPlan.PERSONAL,
        paymentInterval: null,
    });
    const сurrentSubscription = ref<IPlan>();
    const invoices = ref<IPlan[]>([]);
    const upgradePrice = ref<IUpgradePrice>({
        totalPrice: 0,
        pricePerMember: 0,
        totalMembers: 0,
        discountedPrice: 0,
        priceWithoutDiscount: 0,
    });

    const features: IFeatures[] = [
        {
            name: "∞ процессов",
            plans: [EPlan.PERSONAL, EPlan.PERSONAL_PRO, EPlan.TEAM_TRIAL, EPlan.TEAM, EPlan.ENTERPRISE],
        },
        {
            name: "∞ гостей",
            plans: [EPlan.PERSONAL_PRO, EPlan.TEAM_TRIAL, EPlan.TEAM, EPlan.ENTERPRISE],
        },
        {
            name: "Папки",
            plans: [EPlan.TEAM_TRIAL, EPlan.TEAM, EPlan.ENTERPRISE],
        },
        {
            name: "Выгрузка регламентов",
            plans: [EPlan.TEAM_TRIAL, EPlan.TEAM, EPlan.ENTERPRISE],
        },
        {
            name: "Роли",
            plans: [EPlan.TEAM_TRIAL, EPlan.TEAM, EPlan.ENTERPRISE],
        },
        {
            name: "Элементы архитектуры",
            plans: [EPlan.TEAM_TRIAL, EPlan.TEAM, EPlan.ENTERPRISE],
        },
        {
            name: "Оргструктура",
            plans: [EPlan.TEAM_TRIAL, EPlan.TEAM, EPlan.ENTERPRISE],
        },
        {
            name: "Карта процессов",
            plans: [EPlan.TEAM_TRIAL, EPlan.TEAM, EPlan.ENTERPRISE],
        },
        {
            name: "Rest api",
            plans: [EPlan.ENTERPRISE],
        },
        {
            name: "Интеграция с Camunda",
            plans: [EPlan.ENTERPRISE],
        },
        {
            name: "Установка на свои сервера",
            plans: [EPlan.ENTERPRISE],
        },
        {
            name: "Интеграция с BI",
            plans: [EPlan.ENTERPRISE],
        },
    ];

    const currentPlan = computed<EPlan>(() => currentPlanData.value.paymentPlan);
    const isPersonalPlan = computed<boolean>(() => [EPlan.PERSONAL, EPlan.PERSONAL_PRO].includes(currentPlan.value));
    const isTeamPlan = computed<boolean>(() => [EPlan.TEAM_TRIAL, EPlan.TEAM, EPlan.ENTERPRISE].includes(currentPlan.value));
    const isEnterprisePlan = computed<boolean>(() => EPlan.ENTERPRISE === currentPlan.value);

    function getFeaturesByTarif(tarif: EPlan) {
        const newFeatures: IFeaturesValid = {
            valid: [],
            notValid: [],
        };

        for (let item of features) {
            if (item.plans.includes(tarif)) {
                newFeatures.valid.push(item);
            } else {
                newFeatures.notValid.push(item);
            }
        }
        return newFeatures;
    }

    async function getCurrentPlan(heartbeatAcknowledged: boolean = false): Promise<void> {
        try {
            currentPlanData.value = await PaymentApi.getCurrentPlan(heartbeatAcknowledged);
        } catch (e: any) {
            if (e.status !== 404) {
                showError(e?.message);
            }
        }
    }
    async function getCurrentSubscription(): Promise<void> {
        try {
            const res = await PaymentApi.getCurrentSubscription();
            if (typeof res != "string") {
                сurrentSubscription.value = res;
            }
        } catch (e: any) {
            if (e.status !== 404) {
                showError(e?.message);
            }
        }
    }
    async function getInvoices(): Promise<void> {
        try {
            invoices.value = await PaymentApi.getInvoices();
        } catch (e: any) {
            if (e.status !== 404) {
                showError(e?.message);
            }
        }
    }
    async function calculateUpgradePrice(payload: IPayloadUpgradePrice): Promise<void> {
        try {
            upgradePrice.value = await PaymentApi.calculateUpgradePrice(payload);
        } catch (e: any) {
            showError(e?.message);
        }
    }
    async function paymentUpgrade(payload: IPayloadUpgradePrice): Promise<IPaymentUpgrade | undefined> {
        try {
            return await PaymentApi.paymentUpgrade(payload);
        } catch (e: any) {
            showError(e?.message);
        }
    }
    async function cancelSubscription(selectedCancelOptionts: string): Promise<void> {
        try {
            await PaymentApi.cancelSubscription(selectedCancelOptionts);
            showInfo("Ваша подписка приостановлена");
        } catch (e: any) {
            showError(e?.message);
        }
    }

    return {
        currentPlan,
        currentPlanData,
        сurrentSubscription,
        invoices,
        isPersonalPlan,
        isTeamPlan,
        isEnterprisePlan,
        upgradePrice,

        getCurrentPlan,
        calculateUpgradePrice,
        paymentUpgrade,
        getFeaturesByTarif,
        getCurrentSubscription,
        getInvoices,
        cancelSubscription,
    };
});
