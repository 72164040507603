import formbricks from "@formbricks/js/app";
import { storeToRefs } from "pinia";
import { IUserData } from "@/entities/Session";
import { useSessionStore } from "@/entities/Session";
import { API_URL } from "@/shared/config";

export async function importFormbricks(userData: IUserData): Promise<void> {
    if (typeof window !== "undefined") {
        await formbricks.init({
            environmentId: "cm1c1kq73000b10d7g2219udx",
            apiHost: "https://survey.stormbpmn.com",
            userId: String(userData.userId),
            attributes: {
                email: userData.username,
                avatar_url: API_URL + "files/username/" + userData.username,
                plan: userData.plan,
                teamId: userData.teamId || "",
                teamName: userData.teamName || "",
                validTo: String(userData.validTo),
            },
        });
    }
}

export async function stopFormbricks(): Promise<void> {
    await formbricks.reset();
    await formbricks.logout();
}

export async function formbricksRegisterRouteChange(): Promise<void> {
    const sessionStore = useSessionStore();
    const { userData } = storeToRefs(sessionStore);

    if (typeof formbricks !== "undefined" && userData.value?.userId) {
        formbricks.registerRouteChange();
    }
}
