<template>
    <div class="sidebar surface-50 border-1 border-200 border-round flex flex-column gap-3 w-21rem py-3">
        <div class="w-full flex justify-content-center">
            <SelectButton v-model="selectedTypeView" :options="typeViews" :allow-empty="false" :pt="{ button: 'p-2' }" />
        </div>
        <div class="overflow-y-auto scroll-invisible relative h-full">
            <ul class="list-none p-2 pt-0 m-0 flex flex-column gap-1 absolute top-0 left-0 right-0 bottom-0">
                <template v-if="isLoading">
                    <Skeleton class="surface-200" width="100%" height="2.55rem" />
                    <li v-for="item of 8">
                        <Skeleton class="surface-200 ml-4" width="92%" height="2.55rem" />
                    </li>
                    <Skeleton class="surface-200" width="100%" height="2.55rem" />
                </template>
                <template v-else>
                    <li v-for="item of menuItems">
                        <SidebarItem v-bind="item" />
                    </li>
                </template>
            </ul>
        </div>
        <div class="w-full flex justify-content-center pt-0">
            <ProcessChangeView />
        </div>
        <div
            v-if="!isEnterprisePlan"
            class="mx-3 p-3 border-1 border-round-lg flex flex-column gap-2 bg-white"
            :class="[isNearExpired ? 'border-red-500' : 'surface-border']"
        >
            <div class="mx-auto flex gap-1 align-items-center">
                <b class="text-xl">{{ planName }}</b>
                <span
                    class="text-color-secondary px-1 cursor-pointer"
                    :class="PrimeIcons.QUESTION_CIRCLE"
                    @mouseover="(event: Event) => toggleOP(event)"
                />
            </div>
            <span
                v-if="currentPlanData.paymentPlan === EPlan.PERSONAL_PRO || currentPlanData.paymentPlan === EPlan.TEAM || isTeamTrial"
                class="text-sm text-center"
                :class="[isNearExpired ? 'text-red-500' : 'text-color-secondary']"
            >
                до {{ formatDate(currentPlanData?.validTo) }}
            </span>
            <router-link
                v-if="currentPlanData.paymentPlan === EPlan.PERSONAL && !isInTeam"
                :to="{ name: ERoutesName.APP_TEAM_PARTICIPANTS }"
            >
                <Button label="Включить все функции бесплатно на 14 дней" severity="success" outlined />
            </router-link>
            <router-link v-else-if="isUpgradePlan" :to="{ name: ERoutesName.APP_UPGRADE_PLAN }">
                <Button class="w-full" label="Улучшить тариф" severity="success" size="small" outlined />
            </router-link>
        </div>
    </div>
    <OverlayPanel ref="descriptionCurrentPlanRef" class="w-20rem">
        <div>
            <li class="p-1 flex items-center gap-2" v-for="item in featuresPlan.valid">
                <i class="pi pi-check text-green-500 text-lg" />
                <span class="text-surface-800 dark:text-surface-100 leading-6"> {{ item.name }}</span>
            </li>
            <li class="p-1 flex items-center gap-2" v-for="item in featuresPlan.notValid">
                <i class="pi pi-times text-300 text-lg" />
                <span class="text-300 dark:text-surface-100 leading-6"> {{ item.name }}</span>
            </li>
        </div>
    </OverlayPanel>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useRouter, useRoute, RouteRecordName } from "vue-router";
import { storeToRefs } from "pinia";

import { PrimeIcons } from "primevue/api";

import { useLoading } from "@/shared/lib/composables";
import { useLocalStorage } from "@/shared/lib/browser";
import { LAST_ROUTER_NAME_KEY, TYPE_VIEW_KEY } from "@/shared/config";
import { formatDate } from "@/shared/lib/utils/date";
import { EPlan } from "@/shared/lib/types";

import { ProcessChangeView } from "@/features/Process/change-view";

import SidebarItem from "./SidebarItem.vue";
import type { IMenuItem } from "../model";

import { ERoutesName } from "@/app/providers";

import { useAssetStore } from "@/entities/Asset";
import { usePaymentStore, type IFeaturesValid } from "@/entities/Payment";
import { useSessionStore } from "@/entities/Session";
import { EViewType, useApprovalStore } from "@/entities/Approval/model";
import { useInterfaceStore, ETypeView } from "@/entities/Interface";

const assetStore = useAssetStore();
const { selectedButton } = storeToRefs(assetStore);
const paymentStore = usePaymentStore();
const { currentPlanData, isEnterprisePlan } = storeToRefs(paymentStore);
const sessionStore = useSessionStore();
const { isInTeam } = storeToRefs(sessionStore);
const approvalStore = useApprovalStore();
const { approvalsCount } = storeToRefs(approvalStore);
const interfaceStore = useInterfaceStore();
const { selectedTypeView } = storeToRefs(interfaceStore);

const featuresPlan = computed<IFeaturesValid>(() => {
    return paymentStore.getFeaturesByTarif(currentPlanData.value.paymentPlan);
});

const router = useRouter();
const route = useRoute();

const { isLoading, startLoading, finishLoading } = useLoading();
const { setLSValue, value } = useLocalStorage(TYPE_VIEW_KEY);

const descriptionCurrentPlanRef = ref();

const typeViews = ref(Object.values(ETypeView));

const isUpgradePlan = computed(() => isTeamTrial.value || (currentPlanData.value.paymentPlan === EPlan.PERSONAL && isInTeam.value));
const personalMenuItems = computed<IMenuItem[]>(() => [
    {
        label: "Процессы",
        icon: PrimeIcons.BRIEFCASE,
        children: [
            {
                label: "Создать",
                icon: PrimeIcons.PLUS,
                route: { name: ERoutesName.APP_DIAGRAM },
            },
            {
                label: "Поиск",
                icon: PrimeIcons.SEARCH,
                route: { name: ERoutesName.APP_SEARCH },
            },
            {
                label: "Все",
                icon: PrimeIcons.LIST,
                route: { name: ERoutesName.APP_MY_PROCESSES },
            },
            {
                label: "Поделились со мной",
                icon: PrimeIcons.FILE_IMPORT,
                route: { name: ERoutesName.APP_SHARE_WITH_ME },
            },
            {
                label: "Поделился я",
                icon: PrimeIcons.FILE_EXPORT,
                route: { name: ERoutesName.APP_GUESTS_MY },
            },
            {
                label: "Избранные",
                icon: PrimeIcons.STAR,
                disabled: true,
            },
            {
                label: "Корзина",
                icon: PrimeIcons.TRASH,
                route: { name: ERoutesName.APP_BASKET },
            },
        ],
    },
    {
        label: "Согласования",
        icon: PrimeIcons.CHECK_CIRCLE,
        children: [
            {
                label: "Входящие",
                icon: PrimeIcons.ARROW_RIGHT,
                route: { name: ERoutesName.APP_APPROVALS_INCOMING },
                badge: approvalsCount.value.incoming,
            },
            {
                label: "Исходящие",
                icon: PrimeIcons.ARROW_LEFT,
                route: { name: ERoutesName.APP_APPROVALS_OUTGOING },
                badge: approvalsCount.value.outgoing,
            },
            {
                label: "Все",
                icon: PrimeIcons.BOOK,
                route: { name: ERoutesName.APP_APPROVALS_ALL },
            },
        ],
    },
]);
const teamMenuItems = computed<IMenuItem[]>(() => [
    {
        label: "Процессы",
        icon: PrimeIcons.BRIEFCASE,
        children: [
            {
                label: "Создать",
                icon: PrimeIcons.PLUS,
                route: { name: ERoutesName.APP_DIAGRAM },
            },
            {
                label: "Поиск",
                icon: PrimeIcons.SEARCH,
                route: { name: ERoutesName.APP_SEARCH },
            },
            {
                label: "Все",
                icon: PrimeIcons.LIST,
                route: { name: ERoutesName.APP_TEAM_PROCESS },
            },
            {
                label: "Папки",
                icon: PrimeIcons.FOLDER,
                route: { name: ERoutesName.APP_FOLDER },
            },
            {
                label: "Гости команды",
                icon: PrimeIcons.FILE_EXPORT,
                route: { name: ERoutesName.APP_GUESTS_TEAM },
            },
            {
                label: "Реестр",
                icon: PrimeIcons.BOX,
                disabled: true,
            },
            {
                label: "Корзина",
                icon: PrimeIcons.TRASH,
                route: { name: ERoutesName.APP_BASKET },
            },
        ],
    },
    {
        label: "Согласования",
        icon: PrimeIcons.CHECK_CIRCLE,
        children: [
            {
                label: "Входящие",
                icon: PrimeIcons.ARROW_RIGHT,
                route: { name: ERoutesName.APP_APPROVALS_INCOMING },
                badge: approvalsCount.value.incoming,
            },
            {
                label: "Исходящие",
                icon: PrimeIcons.ARROW_LEFT,
                route: { name: ERoutesName.APP_APPROVALS_OUTGOING },
                badge: approvalsCount.value.outgoing,
            },
            {
                label: "Все",
                icon: PrimeIcons.BOOK,
                route: { name: ERoutesName.APP_APPROVALS_ALL },
            },
        ],
    },
    {
        label: "Роли",
        icon: PrimeIcons.USERS,
        children: [
            {
                label: "Создать",
                icon: PrimeIcons.PLUS,
                route: { name: ERoutesName.APP_TEAM_ASSIGNEES, query: { create: "true" } },
                cannotByActive: true,
            },
            {
                label: "Все роли",
                icon: PrimeIcons.USERS,
                route: { name: ERoutesName.APP_TEAM_ASSIGNEES },
            },
        ],
    },
    {
        label: "Элементы архитектуры",
        icon: PrimeIcons.OBJECTS_COLUMN,
        children: [
            {
                label: "Создать",
                icon: PrimeIcons.PLUS,
                route: { name: ERoutesName.APP_TEAM_ASSETS, query: { tab: "0", create: "true", type: selectedButton.value } },
                cannotByActive: true,
            },
            {
                label: "Создать определение",
                icon: PrimeIcons.FILE_EDIT,
                route: { name: ERoutesName.APP_TEAM_ASSETS, query: { tab: "1", create: "true" } },
                cannotByActive: true,
            },
            {
                label: "Все элементы",
                icon: PrimeIcons.TABLE,
                route: { name: ERoutesName.APP_TEAM_ASSETS },
            },
        ],
    },
    {
        label: "Оргструктура",
        icon: PrimeIcons.BUILDING,
        disabled: true,
        children: [
            {
                label: "Схема",
                icon: PrimeIcons.SITEMAP,
                disabled: true,
            },
            {
                label: "Сотрудники",
                icon: PrimeIcons.USERS,
                disabled: true,
            },
        ],
    },
    {
        label: "Архитектура",
        icon: PrimeIcons.MICROCHIP,
        disabled: true,
    },
    {
        label: "Аналитика",
        icon: PrimeIcons.CHART_BAR,
        disabled: true,
    },
    {
        label: "Camunda",
        icon: PrimeIcons.CODE,
        disabled: true,
        children: [
            {
                label: "Серверы",
                icon: PrimeIcons.SERVER,
                disabled: true,
            },
            {
                label: "Управление",
                icon: PrimeIcons.COG,
                disabled: true,
            },
        ],
    },
]);
const menuItems = computed(() => {
    switch (selectedTypeView.value) {
        case ETypeView.PERSONAL:
            return personalMenuItems.value;
        case ETypeView.TEAM:
            return teamMenuItems.value;
        default:
            return personalMenuItems.value;
    }
});

function findRouteName(arr: IMenuItem[], name: RouteRecordName): boolean {
    let status = false;
    for (const item of arr) {
        if (item.children) {
            status = findRouteName(item.children, name);
        } else {
            if (item.route?.name === name) return true;
        }

        if (status) return true;
    }

    return false;
}

function defineTypeView(routeName: RouteRecordName) {
    if (selectedTypeView.value === ETypeView.PERSONAL) {
        const isPersonal = findRouteName(personalMenuItems.value, routeName);
        if (!isPersonal) selectedTypeView.value = ETypeView.TEAM;
    } else {
        const isTeam = findRouteName(teamMenuItems.value, routeName);
        if (!isTeam) selectedTypeView.value = ETypeView.PERSONAL;
    }
}
const isNearExpired = computed<boolean>(() => {
    if (!currentPlanData.value.validTo) return false;
    const dateNow = new Date();
    const validTo = new Date(currentPlanData.value.validTo);
    const days = Math.abs((dateNow.getTime() - validTo.getTime()) / (1000 * 3600 * 24));

    return days < 5;
});

const planName = computed<string>(() => {
    const planName: string = String(
        currentPlanData.value.paymentPlan === EPlan.TEAM_TRIAL ? "Тестовый период" : currentPlanData.value.paymentPlan,
    ).toLowerCase();
    return `${planName.charAt(0).toUpperCase()}${planName.substring(1)}`;
});
const isTeamTrial = computed<boolean>(() => currentPlanData.value.paymentPlan === EPlan.TEAM_TRIAL);

function toggleOP(event: Event) {
    descriptionCurrentPlanRef.value.toggle(event);
}

watch(selectedTypeView, async value => {
    startLoading();

    setLSValue(value);

    switch (value) {
        case ETypeView.PERSONAL:
            await router.push({ name: ERoutesName.APP_MY_PROCESSES });
            await approvalStore.getApprovalCount(EViewType.USER);
            break;
        case ETypeView.TEAM:
            await router.push({ name: ERoutesName.APP_TEAM_PROCESS });
            await approvalStore.getApprovalCount(EViewType.TEAM);
            break;
        default:
            await router.push({ name: ERoutesName.APP_MY_PROCESSES });
    }
    finishLoading();
});

watch(
    route,
    value => {
        if (!value.name) return;
        const { setLSValue: setLSRouterName, value: routeName } = useLocalStorage(LAST_ROUTER_NAME_KEY);

        if (routeName !== value.name) {
            setLSRouterName(value.name);
            defineTypeView(value.name);
        }
    },
    {
        immediate: true,
    },
);
onMounted(() => {
    setLSValue(selectedTypeView.value);
    paymentStore.getCurrentPlan();
    const typeApproval = selectedTypeView.value === ETypeView.PERSONAL ? EViewType.USER : EViewType.TEAM;
    approvalStore.getApprovalCount(typeApproval);
});
</script>
<style lang="scss" scoped>
.sidebar {
    min-width: 21rem;
}
</style>
