<template>
    <div class="row">
        <div class="flex flex-column gap-3">
            <b>Клавиатура</b>
            <div class="flex flex-column">
                <div class="flex align-items-center gap-2 border-round py-1 px-2 hover:surface-hover" v-for="item of keyboardEvents">
                    <Divider v-if="item.divider" class="m-0" />
                    <template v-else>
                        <span>{{ item.label }}</span>
                        <Shortcut class="ml-auto">{{ item.key }}</Shortcut>
                    </template>
                </div>
            </div>
        </div>
        <Divider layout="vertical" class="m-0"></Divider>
        <div class="flex flex-column justify-content-start gap-3">
            <b>Панель процесса</b>
            <div class="flex flex-column">
                <div class="flex align-items-center gap-2 border-round py-1 px-2 hover:surface-hover" v-for="item of panelElements">
                    <Divider v-if="item.divider" class="m-0" />
                    <template v-else>
                        <Shortcut class="text-base" :icon="item.icon" />
                        <span>{{ item.label }}</span>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { shallowRef } from "vue";

import { Shortcut } from "@/shared/ui/shortcut";

import { HelpData, HelpDiagramTypes } from "../model";

const keyboardEvents = shallowRef<HelpDiagramTypes.IKeyboardEvent[]>([
    HelpData.HOT_KEYS.undo,
    HelpData.HOT_KEYS.redo,

    { divider: true },

    HelpData.HOT_KEYS.zoom,

    { divider: true },

    HelpData.HOT_KEYS.verticalScroll,
    HelpData.HOT_KEYS.horizontalScroll,

    { divider: true },

    HelpData.HOT_KEYS.groupSelect,
]);
const panelElements = shallowRef<HelpDiagramTypes.IPanelElement[]>([
    HelpData.ACTIONS.fullscreen,

    { divider: true },

    HelpData.ACTIONS.zoomIn,
    HelpData.ACTIONS.zoomOut,
    HelpData.ACTIONS.undo,
    HelpData.ACTIONS.redo,

    { divider: true },

    HelpData.ACTIONS.copy,
    HelpData.ACTIONS.paste,

    { divider: true },

    HelpData.ACTIONS.showHelp,
    HelpData.ACTIONS.hideDiagramHeader,
]);
</script>

<style scoped>
.row {
    display: grid;
    grid-template-columns: 1fr 1rem 1fr;
    gap: 1rem;
}
</style>
