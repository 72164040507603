<template>
    <div class="flex gap-2 px-2 py-1 border-round surface-50 border-1 border-200 justify-content-between">
        <div class="flex gap-2">
            <slot name="start"></slot>
        </div>
        <div class="flex gap-2">
            <slot name="end"></slot>
        </div>
    </div>
</template>
