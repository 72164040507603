export function randomInteger(min: number = 0, max: number = 1): number {
    const rand: number = min + Math.random() * (max + 1 - min);
    return Math.floor(rand);
}

export function generateRandomSequence(length: number = 10): string {
    const characters: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength: number = characters.length;
    let result: string = "";

    for (let i: number = 0; i < length; i++) {
        const randomIndex: number = Math.floor(Math.random() * charactersLength);
        result += characters[randomIndex];
    }

    return result;
}
