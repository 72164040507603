import { httpClient } from "@/shared/api";

import type { AxiosResponse } from "axios";
import { type IResponseHeartbeat } from "../model/types";

export class HeartbeatApi {
    static async fetchHeartbeat(): Promise<IResponseHeartbeat> {
        const response: AxiosResponse = await httpClient.get("/heartbeat");
        return response.data;
    }
}
