import { computed, ref } from "vue";

import gridModule from "diagram-js-grid";
import { Shape } from "diagram-js/lib/model";

import { CLIPBOARD_BCM_KEY, ModelerConfig } from "@/shared/config";
import { debounce } from "@/shared/lib/utils/timer";
import { toBase64, useLocalStorage } from "@/shared/lib/browser";
import { useNotification } from "@/shared/lib/composables";

import Modeler from "@/entities/BcmModeler/lib/Modeler";

import { IGroupForm, IGroupGeneral } from "../types";
import { IDiagram } from "@/entities/Process";

const modeler = ref();

const selectedElements = ref<Shape[]>([]);

const unsavedChanges = ref<boolean>(false);
const scaleValue = ref<number>(0.5);
const disabledRedo = ref<boolean>(false);
const disabledUndo = ref<boolean>(false);

export function useBcmModeler() {
    const { showSuccess } = useNotification();

    const modeling = computed(() => (modeler.value ? modeler.value.get("modeling") : null));
    const elementRegistry = computed(() => (modeler.value ? modeler.value.get("elementRegistry") : null));
    const modelerZoomScroll = computed(() => (modeler.value ? modeler.value.get("zoomScroll") : null));
    const modelerCanvas = computed(() => (modeler.value ? modeler.value.get("canvas") : null));
    const modelerCommandStack = computed(() => (modeler.value ? modeler.value.get("commandStack") : null));
    const modelerSelection = computed(() => (modeler.value ? modeler.value.get("selection") : null));
    const modelerModdle = computed(() => (modeler.value ? modeler.value.get("moddle") : null));
    const modelerCopyPaste = computed(() => (modeler.value ? modeler.value.get("copyPaste") : null));
    const modelerClipboard = computed(() => (modeler.value ? modeler.value.get("clipboard") : null));

    const hasSelectedElements = computed<boolean>(() => selectedElements.value.length > 0);

    async function init(diagram: undefined | IDiagram, isZoom: boolean): Promise<void> {
        modeler.value = new Modeler({
            container: "#modeler",
            keyboard: {
                bindTo: document,
            },
            additionalModules: [gridModule],
        });

        await modeler.value.createDiagram();

        if (diagram) modeler.value.importJSON(diagram.body);

        if (isZoom) await modeler.value.get("canvas").zoom("fit-viewport");

        subscribeToEvents();
    }
    function destroy(): void {
        const keyboard = modeler.value.get("keyboard");
        if (keyboard) {
            keyboard.unbind();
        }
    }

    function subscribeToEvents(): void {
        const eventBus = modeler.value.get("eventBus");

        // Нужно узнать начальное состояние commandStackState
        checkStateCommandStack();

        const checkStateCommandStackDebounce = debounce(checkStateCommandStack, 300);
        eventBus.on(ModelerConfig.EVENTS_FOR_SAVE, () => {
            unsavedChanges.value = true;
            checkStateCommandStackDebounce();
        });

        eventBus.on(ModelerConfig.EVENT_SELECTION, (event: any) => {
            const { newSelection } = event;
            selectedElements.value = [...newSelection];
        });

        const setScaleValueDebounce = debounce(setScaleValue, 300);
        eventBus.on(ModelerConfig.EVENT_SCALE_CHANGE, () => {
            setScaleValueDebounce();
        });
    }

    function updateProperties(properties: IGroupForm): void {
        if (properties.color) changeColor(properties.color);

        changeProperties({
            name: properties.name,
            level: properties.level,
            priority: properties.priority,
            description: properties.description,
            diagramEntity: properties.diagramEntity,
        });

        unsavedChanges.value = true;
    }
    function changeColor(color: string): void {
        const firstSelected = selectedElements.value[0];
        const element = elementRegistry.value.get(firstSelected.id);

        modeling.value.setColor(element, { fill: color });
    }
    function changeProperties(data: IGroupGeneral): void {
        const firstSelected = selectedElements.value[0];
        const element = elementRegistry.value.get(firstSelected.id);

        const properties = { ...data, diagramEntity: {} };

        // Чтобы не записалось undefined
        if (data.diagramEntity) {
            properties.diagramEntity = data.diagramEntity;
        }

        modeling.value.updateProperties(element, properties);
    }

    // Получение данных определенного формата
    async function saveJSON(): Promise<string> {
        const data = await modeler.value.saveJSON();
        return data;
    }
    async function saveSVG(): Promise<string> {
        const data = await modeler.value.saveSVG({ format: true });
        return data.svg;
    }
    async function getDiagramData(): Promise<{ svg: string; body: string }> {
        const result = await Promise.all([saveJSON(), saveSVG()]);

        const body = JSON.stringify(result[0]);
        const svg = toBase64(result[1]);

        return {
            body,
            svg,
        };
    }

    function resetUnsavedChanges(): void {
        unsavedChanges.value = false;
    }

    // scale
    function setScaleValue(): void {
        scaleValue.value = modelerCanvas.value.zoom(false);
    }

    // undo / redo
    function checkStateCommandStack(): void {
        disabledUndo.value = !modelerCommandStack.value.canUndo();
        disabledRedo.value = !modelerCommandStack.value.canRedo();
    }

    // copy-paste
    function copy(): void {
        const { setLSValue } = useLocalStorage(CLIPBOARD_BCM_KEY);

        const elements = modelerSelection.value.get();
        const tree = modelerCopyPaste.value.copy(elements);
        setLSValue(tree);

        showSuccess("Скопировано");
    }
    function paste(): void {
        const { value } = useLocalStorage(CLIPBOARD_BCM_KEY);

        const parsedCopy = JSON.parse(JSON.stringify(value), createReviver(modelerModdle.value));
        modelerClipboard.value.set(parsedCopy);
        modelerCopyPaste.value.paste();
    }
    function createReviver(moddle: any): any {
        const elCache: { [key: string]: any } = {};

        return function (key: string, object: any) {
            if (typeof object === "object" && typeof object.$type === "string") {
                const objectId = object.id;

                if (objectId && elCache[objectId]) {
                    return elCache[objectId];
                }

                const type = object.$type;
                const attrs = Object.assign({}, object);

                delete attrs.$type;

                const newEl = moddle.create(type, attrs);

                if (objectId) {
                    elCache[objectId] = newEl;
                }

                return newEl;
            }

            return object;
        };
    }

    return {
        init,
        destroy,

        updateProperties,

        getDiagramData,

        selectedElements,

        unsavedChanges,
        resetUnsavedChanges,

        saveSVG,
        saveJSON,

        modelerZoomScroll,
        modelerCanvas,
        modelerCommandStack,

        scaleValue,
        setScaleValue,

        disabledRedo,
        disabledUndo,
        checkStateCommandStack,

        hasSelectedElements,
        copy,
        paste,
    };
}
