import { useDialog } from "primevue/usedialog";
import { HelpModalBpmn, HelpModalBcm } from "../ui";
import { MODAL_PARAMS } from "@/shared/constants/modal";
import { ShareProcessTypes } from "@/shared/types";

export function useDiagramHelp() {
    const dialog = useDialog();

    function openHelp(diagramType: ShareProcessTypes.EDiagramType = ShareProcessTypes.EDiagramType.BPMN): void {
        const helpComponent = diagramType === ShareProcessTypes.EDiagramType.BPMN ? HelpModalBpmn : HelpModalBcm;

        dialog.open(helpComponent, {
            props: {
                header: "Справка по элементам управления",
                ...MODAL_PARAMS,
                style: { width: "100%", maxWidth: "1200px" },
            },
        });
    }

    return {
        openHelp,
    };
}

export * as HelpData from "./constants";
export * as HelpDiagramTypes from "./types";
