import { httpClient } from "@/shared/api";
import type { AxiosPromise, AxiosResponse } from "axios";
import type { IRequestLogin, IRequestRegistration, IRequestRestorePassword, IUserData, IPayloadClickStream } from "../lib";
import * as Sentry from "@sentry/vue";
import { type CacheRequestConfig } from "axios-cache-interceptor";

export class SessionApi {
    static async signIn(payload: IRequestLogin | IRequestRegistration, config?: CacheRequestConfig): Promise<IUserData> {
        const response = await httpClient.post("/auth/signin", payload, config);
        return response.data;
    }
    static async signUp(payload: IRequestRegistration): Promise<void> {
        await httpClient.post("/auth/signup", payload);
    }
    static async requestRestorePassword(email: string): AxiosPromise<any> {
        const response = await httpClient.get("/auth/requestRestore", {
            params: { email },
        });
        return response.data;
    }
    static async restorePassword(payload: IRequestRestorePassword): AxiosPromise<any> {
        const response = await httpClient.post("/auth/restorePassword", payload);
        return response.data;
    }
    static async checkActivationToken(activationToken: string): AxiosPromise<any> {
        const response = await httpClient.get("/auth/activation", {
            params: {
                activationToken: activationToken,
            },
        });
        return response.data;
    }
    static async clickStream(params: IPayloadClickStream): Promise<void> {
        await httpClient.post("click-stream", null, { params });
    }
    static async updateToken(heartbeatAcknowledged: boolean): Promise<string> {
        const response: AxiosResponse = await httpClient.post("/auth/update-token", null, {
            headers: {
                "X-Heartbeat-Acknowledged": heartbeatAcknowledged ? "tokenUpdateRequired" : null,
            },
        });
        return response.data.accessToken;
    }
}
