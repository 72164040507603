import { ref } from "vue";

const isOpenedModal = ref<boolean>(false);

export function useExternalSettingsModal() {
    function toggleOpenModal(status: boolean): void {
        isOpenedModal.value = status;
    }

    return {
        isOpenedModal,
        toggleOpenModal,
    };
}
