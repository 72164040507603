import { ref } from "vue";
import { defineStore } from "pinia";
import { NoticeApi } from "../api";
import { useNotification } from "@/shared/lib/composables";
import type { INotice } from "./types";

export const useNoticeStore = defineStore("notice", () => {
    const { showError } = useNotification();

    const noticeCount = ref<number>(0);
    const notice = ref<INotice[]>([]);

    function saveNoticeCount(count: number): void {
        noticeCount.value = count;
    }

    async function getNoticeCount(): Promise<void> {
        try {
            noticeCount.value = await NoticeApi.getNoticeCount();
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function getNotice(readed: boolean, page: number): Promise<void> {
        try {
            notice.value = await NoticeApi.getNotice(readed, page);
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function getNoticeForPage(readed: boolean, page: number): Promise<void> {
        try {
            const res = await NoticeApi.getNotice(readed, page);
            notice.value.push(...res);
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function markReadNotice(): Promise<void> {
        try {
            await NoticeApi.markReadNotice();
            await getNotice(false, 0);
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function deleteNotice(): Promise<void> {
        try {
            await NoticeApi.deleteNotice();
            notice.value = [];
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    return {
        noticeCount,
        notice,

        getNoticeCount,
        getNotice,
        getNoticeForPage,
        markReadNotice,
        deleteNotice,
        saveNoticeCount,
    };
});
