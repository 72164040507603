import inherits from "inherits-browser";

import { assign, isObject } from "min-dash";

import { append as svgAppend, attr as svgAttr, classes as svgClasses, create as svgCreate, select } from "tiny-svg";

import BaseRenderer from "diagram-js/lib/draw/BaseRenderer";

import { getBusinessObject, is } from "../util/ModelUtil";

import { getFillColor, getRectPath, getSemantic, getStrokeColor } from "./ODRendererUtil";

var HIGH_FILL_OPACITY = 1;

export default function ODRenderer(config, eventBus, styles, textRenderer, priority) {
    BaseRenderer.call(this, eventBus, priority);

    var defaultFillColor = config && config.defaultFillColor,
        defaultStrokeColor = config && config.defaultStrokeColor;

    var computeStyle = styles.computeStyle;

    function drawRect(parentGfx, width, height, r, offset, attrs) {
        if (isObject(offset)) {
            attrs = offset;
            offset = 0;
        }

        offset = offset || 0;

        attrs = computeStyle(attrs, {
            stroke: "black",
            strokeWidth: 2,
            fill: "white",
        });

        var rect = svgCreate(
            "rect",
            assign(
                {
                    x: offset,
                    y: offset,
                    width: width - offset * 2,
                    height: height - offset * 2,
                    rx: r,
                    ry: r,
                },
                attrs,
            ),
        );

        svgAppend(parentGfx, rect);

        return rect;
    }

    function renderLabel(parentGfx, label, options) {
        options = assign({}, options);

        var text = textRenderer.createText(label || "", options);

        svgClasses(text).add("djs-label");

        svgAppend(parentGfx, text);

        return text;
    }

    function renderTitelLabel(parentGfx, element) {
        let semantic = getSemantic(element);
        let text = "";
        if (semantic.name) {
            text = semantic.name;
        }
        renderLabel(parentGfx, text, {
            box: {
                height: element.height,
                width: element.width,
            },
            padding: 5,
            align: "center-top",
            style: {
                fill: getContrastingTextColor(semantic.di.fill),
                fontSize: 16,
            },
        });
    }

    this.handlers = {
        "od:Object": function (parentGfx, element, attrs) {
            const rect = drawRect(
                parentGfx,
                element.width,
                element.height,
                5,
                assign(
                    {
                        fill: getFillColor(element, defaultFillColor),
                        fillOpacity: HIGH_FILL_OPACITY,
                        stroke: getStrokeColor(element, defaultStrokeColor),
                    },
                    attrs,
                ),
            );

            renderTitelLabel(parentGfx, element);

            return rect;
        },
    };
}

inherits(ODRenderer, BaseRenderer);

ODRenderer.$inject = ["config.odm", "eventBus", "styles", "textRenderer"];

ODRenderer.prototype.canRender = function (element) {
    return is(element, "od:BoardElement");
};

ODRenderer.prototype.drawShape = function (parentGfx, element) {
    var type = element.type;
    var h = this.handlers[type];

    /* jshint -W040 */
    return h(parentGfx, element);
};

ODRenderer.prototype.getShapePath = function (element) {
    return getRectPath(element);
};

// helpers ///////////////
function getContrastingTextColor(bgColor = "#FFFFFF") {
    const rgb = bgColor.match(/\w\w/g).map(c => parseInt(c, 16));
    const brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
    return brightness > 128 ? "#000000" : "#FFFFFF";
}
