import { defineStore } from "pinia";

import { HeartbeatApi } from "../api";
import { type IResponseHeartbeat } from "./types";

import { useNotification } from "@/shared/lib/composables";
import { useSessionStore } from "@/entities/Session";
import { useNoticeStore } from "@/entities/Notice";
import { usePaymentStore } from "@/entities/Payment";
import { useGlobalSettingStore } from "@/entities/GlobalSettings";

export function useHeartbeat() {
    const sessionStore = useSessionStore();
    const noticeStore = useNoticeStore();
    const paymentStore = usePaymentStore();
    const globalSettingStore = useGlobalSettingStore();
    const { showError } = useNotification();

    async function fetchHeartbeat(): Promise<void> {
        try {
            const res: IResponseHeartbeat = await HeartbeatApi.fetchHeartbeat();
            if (res.logoutRequired) {
                await globalSettingStore.fetchAll(true);
                sessionStore.logout();
                return;
            }
            if (res.notificationCount) {
                noticeStore.saveNoticeCount(res.notificationCount);
            }
            if (res.tokenUpdateRequired) {
                sessionStore.updateToken(true);
            }
            if (res.paymentPlanUpdated) {
                paymentStore.getCurrentPlan(true);
            }
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    return {
        fetchHeartbeat,
    };
}
