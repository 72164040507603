import { type IDecodeToken } from "@/shared/types/http-response";

export function decodeJWT(token: string): IDecodeToken {
    const base64Url: string = token.split(".")[1];
    const base64: string = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload: string = decodeURIComponent(
        atob(base64)
            .split("")
            .map(c => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
            .join(""),
    );

    return JSON.parse(jsonPayload);
}
