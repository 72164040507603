<template>
    <Menubar
        :pt="{
            start: 'w-full',
            root: 'border-top-none border-x-none border-noround',
        }"
        class="max-h-5rem h-5rem menu"
    >
        <template #start>
            <div class="flex align-items-center justify-content-start gap-2">
                <RouterLink :to="{ name: lastRouteName ?? ERoutesName.APP_MY_PROCESSES }">
                    <img src="@/shared/assets/images/svg/favicon.svg" style="height: 40px; width: auto"
                /></RouterLink>
                <slot>
                    <h1 class="white-space-nowrap">{{ headerTitle }}</h1>
                </slot>
            </div>
        </template>
        <template #end>
            <div class="min-w-max flex gap-3 align-items-center select-none">
                <span
                    v-if="noticeCount > 0"
                    v-badge.secondary="noticeCount"
                    class="pi pi-bell text-xl cursor-pointer"
                    @click="toggleMenuBell"
                ></span>
                <span v-else class="pi pi-bell text-xl cursor-pointer" @click="toggleMenuBell"></span>
                <div class="p-2 hover:surface-hover border-round flex gap-2 align-items-center cursor-pointer">
                    <RouterLink :to="{ name: ERoutesName.APP_MY_PROCESSES }"> <span>Мои процессы</span></RouterLink>
                </div>
                <div
                    class="p-2 hover:surface-hover border-round flex gap-2 align-items-center cursor-pointer"
                    :class="{ 'surface-hover': menuNewProcessRef?.overlayVisible }"
                    aria-haspopup="true"
                    aria-controls="overlay_menu_new_process"
                    @click="toggleMenuNewProcess"
                >
                    <span class="pi pi-plus"></span>
                    <span>Новый процесс</span>
                    <span class="pi pi-angle-down"></span>
                </div>
                <div
                    class="p-2 hover:surface-hover border-round flex gap-2 align-items-center cursor-pointer"
                    :class="{ 'surface-hover': menuProfileRef?.overlayVisible }"
                    aria-haspopup="true"
                    aria-controls="overlay_menu_profile"
                    @click="toggleMenuProfile"
                >
                    <Avatar :username="userData.username" icon="pi pi-user" shape="circle" :size-img="ESizeImg.SMALL" />
                    <span>{{ userData.username }}</span>
                    <span class="pi pi-angle-down"></span>
                </div>
            </div>
            <Menu ref="menuProfileRef" id="overlay_menu_profile" :model="menuProfileItems" :popup="true">
                <template #item="{ item, props }">
                    <router-link
                        class="flex align-items-center"
                        :class="item?.class"
                        v-bind="props?.action"
                        :to="item.link || ''"
                        v-if="!item.hidden && item.type != 'external'"
                    >
                        <span :class="item.icon" />
                        <span class="ml-2">{{ item.label }}</span>
                    </router-link>
                    <a
                        class="flex align-items-center"
                        :class="item?.class"
                        v-bind="props?.action"
                        :href="item.link || ''"
                        v-if="!item.hidden && item.type == 'external'"
                    >
                        <span :class="item.icon" />
                        <span class="ml-2">{{ item.label }}</span>
                    </a>
                </template>
            </Menu>
            <Menu ref="menuNewProcessRef" id="overlay_menu_new_process" :model="menuNewProcessItems" :popup="true" />
            <UploadDiagrams ref="uploadDiagramsRef" />
            <OverlayPanel ref="menuBellRef" class="w-30rem">
                <div class="flex flex-column gap-3">
                    <div class="flex justify-content-between">
                        <div class="flex justify-content-center surface-ground gap-2 overlay-panel__external">
                            <span
                                v-for="item of typeViewNotice"
                                class="p-2 cursor-pointer font-medium text-sm overlay-panel__internal"
                                :class="{ 'bg-white': item == selectedTypeView }"
                                @click="selectedTypeView = item"
                            >
                                {{ item }}
                            </span>
                        </div>
                        <div class="flex gap-3 align-items-center">
                            <span
                                class="cursor-pointer text-lg"
                                :class="PrimeIcons.EYE"
                                v-tooltip.bottom="'Прочитать все'"
                                @click="noticeStore.markReadNotice()"
                            ></span>
                            <span
                                class="cursor-pointer text-lg"
                                :class="PrimeIcons.TRASH"
                                v-tooltip.bottom="'Удалить все'"
                                @click="noticeStore.deleteNotice()"
                            ></span>
                        </div>
                    </div>
                    <div class="flex flex-column gap-3">
                        <div v-if="notice.length > 0" class="notice-list overflow-y-scroll scroll" ref="noticeListRef" @scroll="scrollBell">
                            <Notice v-for="item of notice" :notice="item" />
                        </div>
                        <div v-else class="">Список уведомлений пуст</div>
                    </div>
                </div>
            </OverlayPanel>
        </template>
    </Menubar>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";

import Menubar from "primevue/menubar";
import Menu from "primevue/menu";
import { Avatar } from "@/shared/ui/avatar/";
import { PrimeIcons } from "primevue/api";
import { ESizeImg } from "@/shared/lib/types";

import { UploadDiagrams } from "@/features/Process/upload-diagrams";
import { useRouter } from "vue-router";
import { ERoutesName } from "@/app/providers";
import { useSessionStore } from "@/entities/Session";
import { useInterfaceStore } from "@/entities/Interface";
import { useProcessStore } from "@/entities/Process";
import { useNoticeStore, Notice } from "@/entities/Notice";
import { debounce } from "@/shared/lib/utils/timer";
import type { MenuItem } from "primevue/menuitem";
import { useLocalStorage } from "@/shared/lib/browser";
import { LAST_ROUTER_NAME_KEY } from "@/shared/config";
import { useHeartbeat } from "@/entities/Heartbeat";

enum ETypeView {
    UNREAD = "Непрочитанные",
    ALL = "Все",
}
const route = useRoute();
const router = useRouter();

const sessionStore = useSessionStore();
const processStore = useProcessStore();
const interfaceStore = useInterfaceStore();
const noticeStore = useNoticeStore();
const heartbeat = useHeartbeat();
const { userData, isEnterprisePlan } = storeToRefs(sessionStore);
const { menuProfileItems } = storeToRefs(interfaceStore);
const { notice, noticeCount } = storeToRefs(noticeStore);

const { value: lastRouteName } = useLocalStorage(LAST_ROUTER_NAME_KEY);

const headerTitle = computed(() => !route.meta.hideTitle && route.meta.title);

const menuNewProcessRef = ref();
const uploadDiagramsRef = ref();
const noticeListRef = ref();
const selectedTypeView = ref<ETypeView>(ETypeView.UNREAD);
const pageNotice = ref<number>(0);
const heartbeatInterval = ref();
const menuProfileRef = ref();
const menuBellRef = ref();
const debounceScroll = debounce(async (event: any): Promise<void> => {
    const scrollHeight = event.target.scrollHeight - event.target.clientHeight;
    const scrollPosition = event.target.scrollTop;
    if (scrollHeight - Math.abs(scrollPosition) <= 10) {
        pageNotice.value += 1;
        await noticeStore.getNoticeForPage(selectedTypeView.value == ETypeView.ALL, pageNotice.value);
    }
}, 100);

const typeViewNotice = ref(Object.values(ETypeView));
const menuNewProcessItems = computed<MenuItem[]>(() => {
    return [
        { label: "BPMN", icon: PrimeIcons.BOX, disabled: false, command: () => goToNewDiagramBPMN() },
        { label: "Загрузить BPMN", icon: PrimeIcons.FILE_IMPORT, command: () => showUploadDiagrams() },
        { label: "Группа процессов", icon: PrimeIcons.EXPAND, command: () => router.push({ name: ERoutesName.APP_BCM_DIAGRAM }) },
        { label: "PlantUML", icon: PrimeIcons.CODE, disabled: true },
        { label: "Загрузить с Camunda", icon: PrimeIcons.SERVER, visible: isEnterprisePlan.value },
    ];
});

function toggleMenuNewProcess(event: Event): void {
    menuNewProcessRef.value.toggle(event);
}
function toggleMenuProfile(event: Event): void {
    menuProfileRef.value.toggle(event);
}
function toggleMenuBell(event: Event): void {
    menuBellRef.value.toggle(event);
    pageNotice.value = 0;
    noticeStore.getNotice(selectedTypeView.value === ETypeView.ALL, pageNotice.value);
}
function scrollBell(event: any): void {
    debounceScroll(event);
}

async function goToNewDiagramBPMN() {
    router.push({ name: ERoutesName.APP_DIAGRAM });
}
async function showUploadDiagrams() {
    const result = await new Promise((resolve, reject) => {
        uploadDiagramsRef.value.show(null, { resolve, reject });
    });

    if (result) await processStore.fetchProcessesBy();
}
async function showNotice() {
    pageNotice.value = 0;
    noticeStore.getNotice(selectedTypeView.value === ETypeView.ALL, 0);
}

watch(selectedTypeView, () => {
    showNotice();
});
onMounted(() => {
    heartbeat.fetchHeartbeat();
    heartbeatInterval.value = setInterval(() => heartbeat.fetchHeartbeat(), 10000);
});
onUnmounted(() => {
    clearInterval(heartbeatInterval.value);
});
</script>

<style scoped lang="scss">
.priority {
    color: var(--primary-color);
}
.menu {
    padding: 0 calc(20px - (100vw - 100%)) 0 1.75rem;
}
:deep(.p-highlight) {
    background-color: #6b7280;
    border-color: #6b7280;
}
.notice-list {
    max-height: 300px;
}

.overlay-panel {
    &__external {
        border-radius: 20px;
        padding: 5px;
    }
    &__internal {
        border-radius: 15px;
    }
}
</style>
