<template>
    <Dialog v-model:visible="visible" modal :draggable="false" :style="{ width: '35vw' }" header="Скачать процесс" position="top">
        <TabView v-if="isBPMN">
            <TabPanel header="Файлы">
                <DownloadFiles />
            </TabPanel>
            <TabPanel header="Регламенты">
                <FormColumn title="">
                    <Button @click="uploadTemplate" outlined label="Загрузить свой шаблон" />
                    <Dropdown
                        v-model="settingsDownloadTemplate.templateId"
                        :options="myTemplates"
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Выберите регламент для скачивания"
                        :filter="myTemplates.length > 5"
                    />
                    <div class="flex gap-2 align-items-center">
                        <Checkbox v-model="settingsDownloadTemplate.removeEmptyNames" inputId="removeEmptyNames" :binary="true" />
                        <label for="removeEmptyNames">Убрать элементы с пустыми именами</label>
                    </div>
                    <div class="flex gap-2 align-items-center">
                        <Checkbox v-model="settingsDownloadTemplate.removeExceptActivity" inputId="removeExceptActivity" :binary="true" />
                        <label for="removeExceptActivity">Убрать все элементы, кроме задач</label>
                    </div>
                    <div class="flex gap-2 justify-content-end">
                        <Button
                            @click="downloadTemplate(true)"
                            outlined
                            label="Скачать регламент с базовым шаблоном"
                            :disabled="!settingsDownloadTemplate.templateId"
                        />
                        <Button @click="downloadTemplate()" label="Скачать регламент" :disabled="!settingsDownloadTemplate.templateId" />
                    </div>
                </FormColumn>
            </TabPanel>
        </TabView>
        <DownloadFiles v-else />
    </Dialog>
    <UploadTemplate ref="uploadTemplateRef" />
</template>

<script setup lang="ts">
import { ref } from "vue";
import { storeToRefs } from "pinia";

import { FormColumn } from "@/shared/ui/form";
import { useDialog } from "@/shared/lib/composables";

import { useDiagramStore } from "@/entities/Process";
import { useTeamStore, type IPayloadDownloadTemplate } from "@/entities/Team";

import { UploadTemplate } from "@/widgets/template";

import DownloadFiles from "./DownloadFiles.vue";

interface ISettingsDownloadTemplate {
    templateId: string;
    removeEmptyNames: boolean;
    removeExceptActivity: boolean;
}

const diagramStore = useDiagramStore();
const teamStore = useTeamStore();
const { myTemplates } = storeToRefs(teamStore);
const { isBPMN, diagram } = storeToRefs(diagramStore);
const { visible, show } = useDialog();
const uploadTemplateRef = ref();
const settingsDownloadTemplate = ref<ISettingsDownloadTemplate>({
    templateId: "",
    removeEmptyNames: false,
    removeExceptActivity: false,
});

function downloadTemplate(baseTemplate: boolean = false): void {
    if (diagram.value?.id) {
        const payload: IPayloadDownloadTemplate = {
            baseTemplate: baseTemplate,
            diagramId: diagram.value.id,
            flowElements: [],
            ...settingsDownloadTemplate.value,
        };
        teamStore.downloadTemplate(payload, diagram.value.name);
    }
}
function uploadTemplate() {
    uploadTemplateRef.value.show();
}

defineExpose({
    show: () => {
        show();
        if (isBPMN.value) teamStore.getMyTemplates();
    },
});
</script>

<style scoped></style>
