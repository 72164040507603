import { ref } from "vue";
import { defineStore } from "pinia";
import { PrimeIcons } from "primevue/api";

import { TeamApi } from "../../api";
import { ETitleAccessGroups, type IGroup, type IPayloadRenameGroup } from "../types";

import { useNotification, useLoading } from "@/shared/lib/composables";

export const useAccessGroupTeamStore = defineStore("accessGroupTeamStore", () => {
    const { showError, showSuccess } = useNotification();
    const { isLoading, startLoading, finishLoading } = useLoading();

    interface IAccessGroups {
        title: ETitleAccessGroups;
        icon: string;
    }

    const accessGroups = ref<IAccessGroups[]>([
        {
            title: ETitleAccessGroups.FOLDER,
            icon: PrimeIcons.FOLDER,
        },
        {
            title: ETitleAccessGroups.REGISTRY,
            icon: PrimeIcons.BUILDING_COLUMNS,
        },
    ]);
    const groups = ref<IGroup[]>([]);

    function updateGroups(newData: IGroup): void {
        groups.value = groups.value.map(item => {
            if (item.id == newData.id) {
                return newData;
            }
            return item;
        });
    }

    async function getAccessGroup(): Promise<void> {
        try {
            startLoading();
            groups.value = await TeamApi.getAccessGroup();
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }
    async function addUserGroup(id: string, membersId: string[]): Promise<void> {
        try {
            const res: IGroup = await TeamApi.addUserGroup(id, membersId);
            updateGroups(res);
            showSuccess("Участники добавлены в группу");
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function deleteUserGroup(id: string, membersId: string): Promise<void> {
        try {
            const res: IGroup = await TeamApi.deleteUserGroup(id, membersId);
            updateGroups(res);
            showSuccess("Участник удален из группы");
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function deleteGroup(id: string): Promise<void> {
        try {
            await TeamApi.deleteGroup(id);
            groups.value = groups.value.filter(item => item.id != id);
            showSuccess("Группа удалена");
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function deletePrivilege(id: string, privilege: string): Promise<void> {
        try {
            const res: IGroup = await TeamApi.deletePrivilege(id, privilege);
            updateGroups(res);
            showSuccess("Привилегия отключена");
        } catch (e: any) {
            showError(e?.message || e);
        }
    }

    async function addPrivilege(id: string, privilege: string): Promise<void> {
        try {
            const res: IGroup = await TeamApi.addPrivilege(id, privilege);
            updateGroups(res);
            showSuccess("Привилегия включена");
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function createAccessGroup(groupName: string, members: string[]): Promise<void> {
        try {
            const res: IGroup = await TeamApi.createAccessGroup(groupName, members);
            groups.value.push(res);
            showSuccess("Группа успешно создана");
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function renameGroup(group: IGroup): Promise<void> {
        try {
            const members: string[] = [];
            for (let member of group.members) {
                members.push(member.id);
            }
            const payload: IPayloadRenameGroup = {
                id: group.id,
                members: members,
                name: group.name,
                privileges: group.privileges,
            };
            const res: IGroup = await TeamApi.renameGroup(payload);
            updateGroups(res);
            showSuccess("Название группы успешно изменена");
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    deleteGroup;
    return {
        groups,
        isLoading,
        accessGroups,

        getAccessGroup,
        addUserGroup,
        deleteUserGroup,
        deleteGroup,
        deletePrivilege,
        addPrivilege,
        createAccessGroup,
        renameGroup,
    };
});
