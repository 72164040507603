<template>
    <div class="flex flex-column justify-content-between border-round border-1 border-300 hover:border-primary">
        <DiagramLink :process="process">
            <div class="p-3 w-full h-12rem flex align-items-center justify-content-center border-round-top cursor-pointer">
                <ImageStorm v-if="imageExist" :src="imageUrl" @error="imageExist = false" />
                <span :class="PrimeIcons.EXCLAMATION_TRIANGLE" class="text-7xl text-300" v-else></span>
            </div>
        </DiagramLink>
        <div class="px-4">
            <Divider class="m-0" />
        </div>
        <div class="flex-auto flex flex-column gap-3 p-4">
            <div class="flex flex-column gap-2 align-items-start">
                <div class="flex w-full justify-content-between align-items-center mb-2">
                    <slot name="status"></slot>
                    <Button
                        :icon="PrimeIcons.STAR"
                        v-if="process.quality"
                        severity="success"
                        outlined
                        :label="String(process.quality)"
                        class="px-2 py-1 cursor-auto shadow-none"
                    ></Button>
                    <span v-else>Нет оценки</span>
                </div>
                <div class="w-full flex gap-3 align-items-center justify-content-between">
                    <DiagramLink class="text-primary no-underline hover:bg-primary-100 cursor-pointer px-1 border-round" :process="process">
                        <h3>{{ process.name }}</h3>
                    </DiagramLink>
                    <slot name="menu">
                        <span :class="PrimeIcons.ELLIPSIS_H"></span>
                    </slot>
                </div>
            </div>
            <div class="flex flex-column gap-2 align-items-start text-500">
                <div class="flex gap-2 align-items-center">
                    <Avatar :username="process.authorUsername" :size-img="ESizeImg.SMALL" />
                    {{ process.authorUsername }}
                </div>
                <div class="flex gap-2">
                    <span class="pi pi-calendar"></span>
                    {{ formatDateTime(process.updatedOn) }}
                </div>
                <div class="flex gap-2 align-items-center">
                    <span class="pi pi-folder"></span>
                    <FolderLabel v-if="process.teamFolderId" :label="getFolderName()" :show-icon="false" />
                    <span v-else>Без папки</span>
                </div>
                <TagProcess :diagramId="process.id" :tags="tagsProcess" @delTag="delTag" @addTag="addTag" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";

import { PrimeIcons } from "primevue/api";

import { formatDateTime } from "@/shared/lib/utils/date";
import { API_URL } from "@/shared/config";
import { Avatar } from "@/shared/ui/avatar/";
import { ESizeImg } from "@/shared/lib/types";
import { DiagramLink } from "@/shared/ui/diagram";
import { ImageStorm } from "@/shared/ui/image";

import { useTagStore, TagProcess, type ITag } from "@/entities/Tag";
import { type IProcess } from "@/entities/Process/model";
import { FolderLabel } from "@/entities/Folder";

interface IProps {
    process: IProcess;
}

const props = defineProps<IProps>();

const tagStore = useTagStore();

const imageExist = ref<boolean>(true);
const tagsProcess = ref<ITag[]>(props.process.tags);

const imageUrl = computed<string>(() => `${API_URL}files/${props.process.id}_preview.png`);

function getFolderName(): string {
    return props.process.teamFolderName as string;
}
function delTag(id: number): void {
    tagsProcess.value = tagsProcess.value.filter(item => item.id != id);
    tagStore.updateByDiagramId(props.process.id, tagsProcess.value);
}

async function addTag(tag: ITag): Promise<void> {
    const tagLowerCaseTrim: string = tag.text.toLowerCase().trim();
    if (!tagsProcess.value.some(item => item.text.toLowerCase() === tagLowerCaseTrim)) {
        tagsProcess.value.push(tag);
        await tagStore.updateByDiagramId(props.process.id, tagsProcess.value);
    }
}
</script>

<style scoped lang="scss"></style>
