import { PrimeIcons } from "primevue/api";

export const HOT_KEYS = {
    undo: { label: "Отменить последнее действие", key: "Ctrl+Z" },
    redo: { label: "Выполнить последнее действие", key: "Ctrl+Shift+Z" },
    zoom: { label: "Изменение масштаба", key: "Ctrl+Scroll" },
    verticalScroll: { label: "Перемещение по вертикали", key: "Scroll" },
    horizontalScroll: { label: "Перемещение по горизонтали", key: "Shift+Scroll" },
    groupSelect: { label: "Выделять элементы по одному в группу", key: "Shift+Click" },
    toggleAllOverlays: { label: "Изменение видимости всех оверлеев", key: "Alt+A" },
    toggleAllOverlaysCollapse: { label: "Изменение состояния свернутости всех оверлеев", key: "Alt+Shift+A" },
    toggleRoleOverlays: { label: "Изменение видимости оверлеев типа «Роль»", key: "Alt+1" },
    togglePositionOverlays: { label: "Изменение видимости оверлеев типа «Должность»", key: "Alt+2" },
    toggleSystemOverlays: { label: "Изменение видимости оверлеев типа «Системы»", key: "Alt+3" },
    toggleSystemOverlaysCollapse: { label: "Изменение состояния свернутости оверлеев типа «Системы»", key: "Alt+Shift+3" },
    toggleDocumentOverlays: { label: "Изменение видимости оверлеев типа «Документы»", key: "Alt+4" },
    toggleDocumentOverlaysCollapse: { label: "Изменение состояния свернутости оверлеев типа Документы", key: "Alt+Shift+4" },
    toggleDescriptionOverlays: { label: "Изменение видимости оверлеев типа «Описание»", key: "Alt+5" },
    toggleCommentsOverlays: { label: "Изменение видимости оверлеев типа «Комментарии»", key: "Alt+6" },
    toggleDurationOverlays: { label: "Изменение видимости оверлеев типа «Длительность»", key: "Alt+7" },
    toggleIncomingConnections: { label: "Изменение видимости оверлеев типа «Связи (входящие)»", key: "Alt+8" },
    toggleOutgoingConnections: { label: "Изменение видимости оверлеев типа «Связи (исходящие)»", key: "Alt+9" },
};

export const ACTIONS = {
    runTokenSimulation: { label: "Запустить симуляцию токена", icon: PrimeIcons.PLAY_CIRCLE },
    fullscreen: { label: "Во весь экран", icon: PrimeIcons.ARROW_UP_RIGHT_AND_ARROW_DOWN_LEFT_FROM_CENTER },
    openMiniMap: { label: "Открыть мини-карту", icon: PrimeIcons.MAP },
    zoomIn: { label: "Увеличить масштаб", icon: PrimeIcons.SEARCH_PLUS },
    zoomOut: { label: "Уменьшить масштаб", icon: PrimeIcons.SEARCH_MINUS },
    undo: { label: "Отменить последнее действие", icon: PrimeIcons.UNDO },
    redo: { label: "Вернуть последнее действие", icon: PrimeIcons.REFRESH },
    changeColor: { label: "Изменить цвет выделенных элементов", icon: PrimeIcons.PALETTE },
    copy: { label: "Копировать выделенные элементы", icon: PrimeIcons.COPY },
    paste: { label: "Вставить скопированное (работает между вкладками)", icon: PrimeIcons.CLIPBOARD },
    qualityAssessment: { label: "Запустить оценку качества процесса", icon: PrimeIcons.CHECK_CIRCLE },
    autoNumbering: { label: "Запустить автонумерацию элементов", icon: PrimeIcons.LIST_CHECK },
    clearAutoNumbering: { label: "Очистить автонумерацию элементов", icon: PrimeIcons.BAN },
    showHelp: { label: "Показать справочную информацию", icon: PrimeIcons.QUESTION_CIRCLE },
    hideDiagramHeader: { label: "Скрыть шапку диаграммы", icon: PrimeIcons.ANGLE_UP },
};
