<template>
    <FormColumn title="">
        <div class="flex gap-2">
            <Button label="SVG" outlined @click="downloadSVG" />
            <Button v-if="isBPMN" label="BPMN" severity="secondary" outlined @click="downloadXML" />
            <Button v-else label="JSON" severity="secondary" outlined @click="donwloadJSON" />
            <Button label="PNG" severity="success" outlined @click="downloadPNG" />
            <Button label="PDF" severity="help" outlined @click="downloadPDF" />
        </div>
    </FormColumn>
</template>

<script setup lang="ts">
import { FormColumn } from "@/shared/ui/form";

import { useDiagramStore } from "@/entities/Process";

import { useDownload } from "@/features/Process/download";
import { storeToRefs } from "pinia";

const { downloadSVG, downloadXML, downloadPNG, donwloadJSON, downloadPDF } = useDownload();
const diagramStore = useDiagramStore();
const { isBPMN } = storeToRefs(diagramStore);
</script>

<style scoped></style>
