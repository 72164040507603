import inherits from "inherits-browser";

import KeyboardBindings from "diagram-js/lib/features/keyboard/KeyboardBindings";

/**
 * OD specific keyboard bindings.
 *
 * @param {Injector} injector
 */
export default function ODKeyboardBindings(injector) {
    injector.invoke(KeyboardBindings, this);
}

inherits(ODKeyboardBindings, KeyboardBindings);

ODKeyboardBindings.$inject = ["injector"];

/**
 * Register available keyboard bindings.
 *
 * @param {Keyboard} keyboard
 * @param {EditorActions} editorActions
 */
ODKeyboardBindings.prototype.registerBindings = function (keyboard, editorActions) {
    // inherit default bindings
    //KeyboardBindings.prototype.registerBindings.call(this, keyboard, editorActions);

    /**
     * Add keyboard binding if respective editor action
     * is registered.
     *
     * @param {String} action name
     * @param {Function} fn that implements the key binding
     */
    function addListener(action, fn) {
        if (editorActions.isRegistered(action)) {
            keyboard.addListener(fn);
        }
    }

    // undo
    // (CTRL|CMD) + Z
    addListener("undo", function (context) {
        const { keyEvent: event } = context;

        if (isUndo(event, keyboard)) {
            editorActions.trigger("undo");

            return true;
        }
    });

    // redo
    // CTRL + Y
    // CMD + SHIFT + Z
    addListener("redo", function (context) {
        const { keyEvent: event } = context;

        if (isRedo(event, keyboard)) {
            editorActions.trigger("redo");

            return true;
        }
    });

    // copy
    // CTRL/CMD + C
    addListener("copy", function (context) {
        const { keyEvent: event } = context;

        if (isKey(["KeyC"], event) && keyboard.isCmd(event)) {
            editorActions.trigger("copy");

            return true;
        }
    });

    // paste
    // CTRL/CMD + V
    addListener("paste", function (context) {
        const { keyEvent: event } = context;

        if (isKey(["KeyV"], event) && keyboard.isCmd(event)) {
            editorActions.trigger("paste");

            return true;
        }
    });

    // select all elements
    // CTRL + A
    addListener("selectElements", function (context) {
        const { keyEvent: event } = context;

        if (isKey(["KeyA"], event) && keyboard.isCmd(event)) {
            editorActions.trigger("selectElements");

            return true;
        }
    });

    // activate space tool
    // S
    addListener("spaceTool", function (context) {
        const { keyEvent: event } = context;

        if (keyboard.hasModifier(event)) {
            return;
        }

        if (isKey(["KeyS"], event)) {
            editorActions.trigger("spaceTool");

            return true;
        }
    });

    // activate lasso tool
    // L
    addListener("lassoTool", function (context) {
        const { keyEvent: event } = context;

        if (keyboard.hasModifier(event)) {
            return;
        }

        if (isKey(["KeyL"], event)) {
            editorActions.trigger("lassoTool");

            return true;
        }
    });

    // activate hand tool
    // H
    addListener("handTool", function (context) {
        const { keyEvent: event } = context;

        if (keyboard.hasModifier(event)) {
            return;
        }

        if (isKey(["KeyH"], event)) {
            editorActions.trigger("handTool");

            return true;
        }
    });

    // activate direct editing
    // E
    addListener("directEditing", function (context) {
        const { keyEvent: event } = context;

        if (keyboard.hasModifier(event)) {
            return;
        }

        if (isKey(["F2", "KeyE"], event)) {
            editorActions.trigger("directEditing");

            return true;
        }
    });

    // delete selected element
    // DEL
    addListener("removeSelection", function (context) {
        const { keyEvent: event } = context;

        if (isKey(["Backspace", "Delete"], event)) {
            editorActions.trigger("removeSelection");

            return true;
        }
    });
};

// HELPERS //////////////////
function isKey(keys = [], event) {
    const { code } = event;

    return keys.includes(code);
}

function isUndo(event, keyboard) {
    return isKey(["KeyZ"], event) && keyboard.isCmd(event) && !keyboard.isShift(event);
}

function isRedo(event, keyboard) {
    return keyboard.isCmd(event) && (isKey(["KeyY"], event) || (isKey(["KeyZ"], event) && keyboard.isShift(event)));
}
