import { httpClient } from "@/shared/api";
import type {
    IProcessResponse,
    IProcessQuery,
    ISearchProcessQuery,
    ISearchProcessResponse,
    IProcess,
    IUploadDiagram,
    IVersion,
    EProcessStatus,
    IProcessSecure,
    IProcessSetSecure,
    IOnePorcessResponse,
    IDiagram,
    IAutosaveDiagram,
    IResponseAutosaveDiagram,
    IOverlaySettings,
    IDiagramShort,
    IDiagramShortFilter,
    ILoadPDFSettings,
} from "../model";
import type { AxiosResponse } from "axios";
import axios from "axios";
import { ShareHttpResponse } from "@/shared/types";

export class ProcessApi {
    static async fetchAll(query: IProcessQuery): Promise<IProcessResponse> {
        const response: AxiosResponse = await httpClient.post("/diagram/search", null, {
            params: query,
        });
        return response.data;
    }
    static async fetchById(id: string): Promise<IOnePorcessResponse> {
        const response: AxiosResponse = await httpClient.get(`/diagram/${id}`);
        return response.data;
    }
    static async fetchCallActivitySearch(): Promise<IProcess[]> {
        const response: AxiosResponse = await httpClient.get("/diagram/call-activity-search");
        return response.data;
    }
    static async fetchAllShortBy(payload?: IDiagramShortFilter): Promise<ShareHttpResponse.IPageResponse<IDiagramShort[]>> {
        const params = payload || {};
        const response: AxiosResponse = await httpClient.get("/diagram/filter/short", { params });
        return response.data;
    }
    static async fetchUnpublic(): Promise<IProcess[]> {
        const response: AxiosResponse = await httpClient.get("/diagram/search/nonPublicDiagram");
        return response.data;
    }
    static async search(query: ISearchProcessQuery): Promise<ISearchProcessResponse> {
        const response: AxiosResponse = await httpClient.get("/diagram-search", { params: query });
        return response.data;
    }
    static async delete(payload: string[]): Promise<void> {
        await httpClient.delete("/diagram/delete", {
            data: payload,
        });
    }
    static async restore(id: number): Promise<IProcess> {
        const response: AxiosResponse = await httpClient.post(`/diagram/trash/restore/${id}`);
        return response.data;
    }
    static async changeVisibility(payload: string[]): Promise<void> {
        await httpClient.post("/diagram/changeVisibility", payload);
    }
    static async uploadVersion(diagramId: string, payload: IUploadDiagram): Promise<IProcess> {
        const response: AxiosResponse = await httpClient.post(`/diagram/${diagramId}`, payload);
        return response.data;
    }
    static async uploadDiagram(payload: IUploadDiagram): Promise<IDiagram> {
        const response: AxiosResponse = await httpClient.post("/diagram", payload);
        return response.data;
    }
    static async createDraft(payload: IUploadDiagram): Promise<IProcess> {
        const response: AxiosResponse = await httpClient.post("/diagram/draft", payload);
        return response.data;
    }
    static async autosave(id: string, payload: IAutosaveDiagram): Promise<IResponseAutosaveDiagram> {
        const response: AxiosResponse = await httpClient.post(`/diagram/${id}/autosave`, payload);
        return response.data;
    }

    static async createDublicate(diagramId: string): Promise<IProcess> {
        const response: AxiosResponse = await httpClient.post(`/diagram/duplicate/${diagramId}`);
        return response.data;
    }
    static async downloadPng(url: string): Promise<Blob> {
        const response: AxiosResponse = await axios.get(url, { responseType: "blob" });
        return response.data;
    }
    static async downloadPDF(query: ILoadPDFSettings, url: string): Promise<ArrayBuffer> {
        const response: AxiosResponse = await httpClient.post(
            `convert/url`,
            {
                url: url,
                params: query,
            },
            { responseType: "arraybuffer" },
        );
        return response.data;
    }
    static async fetchAllVersion(diagramId: string): Promise<IVersion[]> {
        const response: AxiosResponse = await httpClient.get(`/diagram/${diagramId}/version`);
        return response.data;
    }
    static async changeStatus(diagramId: string, status: EProcessStatus): Promise<void> {
        await httpClient.post(`/diagram/${diagramId}/changeStatus/${status}`);
    }
    static async move(folderId: number, payload: string[]): Promise<void> {
        await httpClient.post(`/diagram/massiveMove?folderId=${folderId}`, payload);
    }

    static async fetchSecure(diagramId: string): Promise<IProcessSecure[]> {
        const response: AxiosResponse = await httpClient.get(`/diagram/secure/${diagramId}`);
        return response.data;
    }
    static async fetchSecureByFolderId(folderId: number): Promise<IProcessSecure[]> {
        const response: AxiosResponse = await httpClient.get(`/diagram/secure/folder/${folderId}`);
        return response.data;
    }
    static async setSecure(payload: IProcessSetSecure): Promise<void> {
        await httpClient.post("/diagram/secure", payload);
    }
    static async deleteSecure(id: string): Promise<void> {
        await httpClient.delete(`/diagram/secure/${id}`);
    }

    static async fetchOverlaySettingsById(diagramId: string): Promise<IOverlaySettings> {
        const response: AxiosResponse = await httpClient.get(`/diagram/overlaySettings/${diagramId}`);
        return response.data;
    }
}
