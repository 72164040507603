import { computed, ref } from "vue";
import { defineStore, storeToRefs } from "pinia";

import { useLoading, useNotification } from "@/shared/lib/composables";

import { IAsset, IAssetEdge, ICustomAsset } from "../types";
import { useAssetStore } from "./assetStore";
import { AssetApi } from "../../api";

import { useElementStore } from "@/entities/BpmnModeler";

export const useAssetByDiagramStore = defineStore("asset-by-diagram", () => {
    const assetStore = useAssetStore();
    const { assets } = storeToRefs(assetStore);

    const elementStore = useElementStore();
    const { selectedElement } = storeToRefs(elementStore);

    const { isLoading: isLoadingByDiagram, startLoading: startLoadingByDiagram, finishLoading: finishLoadingByDiagram } = useLoading();
    const { showError } = useNotification();

    const selectedAssets = ref<IAsset[]>([]);
    function selectAssets(assets: IAsset[]) {
        selectedAssets.value = assets;
    }

    const assetsAll = computed<IAsset[]>(() => {
        const assetsArr: IAsset[] = [...assets.value];

        addUniqueAssets(assetsArr, assetsByElement.value);

        return assetsArr;
    });
    const assetsByElement = computed<IAsset[]>(() => {
        const assetsArr: IAsset[] = [];

        for (const item of assetsByDiagram.value) {
            if (item.fromItemId === selectedElement.value?.id) {
                const asset = assets.value.find(i => i.id === item.assetId);
                if (asset) assetsArr.push(asset);
            }
        }
        return assetsArr;
    });
    function addUniqueAssets(targetArray: IAsset[], sourceArray: IAsset[]): void {
        const existingIds: Set<number> = new Set(targetArray.map(item => item.id));

        for (const asset of sourceArray) {
            if (!existingIds.has(asset.id)) {
                targetArray.push(asset);
            }
        }
    }

    // Элементы архитектуры для диаграммы и действия с ними
    const assetsByDiagram = ref<IAssetEdge[]>([]);
    async function fetchByDiagramId(diagramId: string): Promise<void> {
        try {
            startLoadingByDiagram();

            assetsByDiagram.value = await AssetApi.fetchByDiagramId(diagramId);
        } catch (e) {
            assetsByDiagram.value = [];
            showError(e);
        } finally {
            finishLoadingByDiagram();
        }
    }
    function addAssetsForDiagram(assets: IAssetEdge[]): void {
        assetsByDiagram.value.push(...assets);
    }
    function changeAssetsByDiagramByAsset(asset: IAsset): void {
        for (const assetEdge of assetsByDiagram.value) {
            if (assetEdge.assetId === asset.id) {
                assetEdge.assetColor = asset.color;
                assetEdge.assetName = asset.name;
                assetEdge.assetDescription = asset.description;
            }
        }
    }
    function deleteAssetsByDiagramBy(elementId: string, edgeId: string): void {
        const index = assetsByDiagram.value.findIndex(item => item.id === edgeId && item.fromItemId === elementId);
        if (index >= 0) assetsByDiagram.value.splice(index, 1);
    }

    // Кастомные элементы архитектуры для диаграммы
    const customAssetsByDiagram = ref<ICustomAsset[]>([]);
    async function fetchCustomByDiagramId(diagramId: string): Promise<void> {
        try {
            startLoadingByDiagram();
            customAssetsByDiagram.value = await AssetApi.fetchCustomByDiagramId(diagramId);
        } catch (e) {
            customAssetsByDiagram.value = [];
            showError(e);
        } finally {
            finishLoadingByDiagram();
        }
    }

    return {
        selectedAssets,
        selectAssets,

        assetsAll,
        assetsByElement,

        assetsByDiagram,
        fetchByDiagramId,
        addAssetsForDiagram,
        changeAssetsByDiagramByAsset,
        deleteAssetsByDiagramBy,

        customAssetsByDiagram,
        fetchCustomByDiagramId,
    };
});
