<template>
    <div class="flex flex-column gap-2">
        <div class="px-3 py-1 border-1 border-200 surface-50 border-round flex gap-1 justify-content-between">
            <slot name="navigation"></slot>
        </div>
        <div class="diagram-right-sidebar flex-auto">
            <div class="px-3">
                <slot name="title"></slot>
            </div>
            <slot name="body"></slot>
        </div>
    </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
@import "primeflex/primeflex.scss";

.diagram-right-sidebar {
    width: 280px;
    @include styleclass("surface-50 border-1 border-200 border-round flex flex-column gap-2 overflow-auto");
}

:deep(.my-button) {
    @include styleclass("p-2 flex justify-content-center");
    width: 35px !important;
}
.comments {
    @include styleclass("flex-auto flex flex-column overflow-auto");
}

.notice {
    @include styleclass("absolute bg-red-500 text-white border-circle p-1");
    top: 25%;
    left: 60%;
    transform: translate(50%, -50%);
}
</style>
