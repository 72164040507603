<template>
    <Dropdown
        v-model="selectedValue"
        :options="shortDiagrams"
        showClear
        placeholder="Не выбрано"
        option-label="name"
        :panelStyle="{ width }"
        filter-placeholder="Поиск"
        appendTo="self"
        :virtual-scroller-options="{
            itemSize: 70,
            lazy: true,
            step: 10,
            onLazyLoad: onLazyLoading,
            class: 'scroll',
            numToleratedItems: 0,
        }"
        :pt="{
            wrapper: 'scroll',
        }"
    >
        <template #value="{ placeholder, value }">
            <span class="no-wrap">{{ value ? value.name || "Без названия" : placeholder }}</span>
        </template>
        <template #header>
            <div class="p-3 surface-ground border-bottom-1 border-200 border-round-top">
                <IconField iconPosition="right">
                    <InputIcon :class="PrimeIcons.SEARCH"> </InputIcon>
                    <InputText v-model="filterValue" class="w-full" placeholder="Поиск" @input="debounceSearch" />
                </IconField>
            </div>
        </template>
        <template #option="{ option }">
            <div class="flex flex-column gap-1">
                <span class="font-medium no-wrap">{{ option.name || `Без название (${option.id})` }}</span>
                <span class="text-sm text-color-secondary">{{ timeAgo(option.updatedOn) }}</span>
                <span class="text-sm text-color-secondary">{{ option.updatedBy }}</span>
            </div>
        </template>
        <template #footer>
            <div class="py-2 text-center">Показано элементов: {{ shortDiagrams.length }} из {{ totalShortDiagrams }}</div>
        </template>
    </Dropdown>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";

import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import { VirtualScrollerLazyEvent } from "primevue/virtualscroller";

import { timeAgo } from "@/shared/lib/utils/date";
import { debounce } from "@/shared/lib/utils/timer";

import { IDiagramShort, useDiagramStore } from "@/entities/Process";
import { PrimeIcons } from "primevue/api";

interface IProps {
    width?: string;
}

withDefaults(defineProps<IProps>(), {
    width: "100%",
});

const diagramStore = useDiagramStore();

const { shortDiagrams, totalShortDiagrams, isLastPageShort } = storeToRefs(diagramStore);

const debounceSearch = debounce(async () => {
    if (!filterValue.value) {
        await diagramStore.fetchAllShortDiagrams();
    } else {
        await diagramStore.fetchAllShortDiagrams({ diagramName: filterValue.value });
    }
}, 200);

const selectedValue = defineModel<IDiagramShort>();

const filterValue = ref<string>("");

async function onLazyLoading(event: VirtualScrollerLazyEvent) {
    const page = Math.ceil(event.last / 10);

    if (filterValue.value) return;
    if (isLastPageShort.value) return;
    if (shortDiagrams.value.length > event.last && event.last >= 10) return;

    await diagramStore.fetchAllShortDiagrams({
        page,
    });
}

onMounted(() => {
    diagramStore.fetchAllShortDiagrams();
});
</script>

<style lang="scss">
.text-break {
    white-space: normal;
    word-wrap: break-word;
}
</style>
