<template>
    <ProgressSpinner animationDuration="2s" strokeWidth="4" />
</template>

<script setup lang="ts">
import { inject, type Ref, watch } from "vue";

const dialogRef = inject<Ref>("dialogRef");

watch(dialogRef?.value.data, (value: any) => {
    if (!value) {
        dialogRef?.value.close();
    }
});
</script>

<style scoped></style>
