import { ref, computed, watch } from "vue";
import { defineStore, storeToRefs } from "pinia";
import { useRouter } from "vue-router";

import * as Sentry from "@sentry/vue";

import { useLocalStorage } from "@/shared/lib/browser";
import { decodeJWT } from "@/shared/lib/utils/decodeJWT";
import { type IDecodeToken } from "@/shared/types/http-response";

import { TOKEN_KEY, USER_DATA_KEY, FIRST_LOGIN_KEY, ANONYMOUS_ID_KEY } from "@/shared/config";

import { useNotification } from "@/shared/lib/composables";
import { useLoading } from "@/shared/lib/composables";
import { importFormbricks, stopFormbricks } from "@/shared/lib/formbricks";
import { generateRandomSequence } from "@/shared/lib/utils/number";

import { type IUserData, UserDataDto, SessionApi, type IRequestLogin, type IPayloadClickStream, type IRequestRegistration } from "..";
import { usePaymentStore } from "@/entities/Payment";

import { ERoutesName } from "@/app/providers";

export const useSessionStore = defineStore("session", () => {
    const router = useRouter();
    const { showError } = useNotification();
    const { isLoading: loading, startLoading, finishLoading } = useLoading();

    const paymentStore = usePaymentStore();
    const { isTeamPlan, isEnterprisePlan } = storeToRefs(paymentStore);

    const { value: tokenValue, setLSValue: setLSToken } = useLocalStorage(TOKEN_KEY);
    const { value: userDataValue, setLSValue: setLSUserData } = useLocalStorage(USER_DATA_KEY);
    const { value: isFirstLoginValue, setLSValue: setLSIsFirstLoginValue } = useLocalStorage<boolean | null>(FIRST_LOGIN_KEY);
    const { value: anonymousIdValue, setLSValue: setLSAnonymousId } = useLocalStorage<string>(ANONYMOUS_ID_KEY);

    const token = ref(tokenValue);
    const userData = ref<IUserData>(userDataValue as IUserData);
    const isFirstLogin = ref<boolean>(isFirstLoginValue ?? true);
    const anonymousId = ref<string>(anonymousIdValue);

    const isAuth = computed<boolean>(() => !!token.value);
    const isInTeam = computed<boolean>(() => userData.value?.inTeam || false);

    const isFullAccess = computed<boolean>(() => userData.value?.fullAccess || false);
    const isTeamAccess = computed<boolean>(() => isInTeam.value || isTeamPlan.value);

    const authorities = ref<string[]>(); //пока нигде не используется, заготовка на будущее

    async function setToken(value: string): Promise<void> {
        setLSToken(value);
        token.value = value;
    }
    function setUserData(value: IUserData): void {
        setLSUserData(value);
        userData.value = value;

        if (isAuth.value) {
            setSentrySettings();
        } else {
            Sentry.getCurrentScope().clear();
        }
    }
    function setSentrySettings(): void {
        Sentry.setUser({ email: userData.value.username });
        Sentry.setTag("userId", userData.value.userId);
        Sentry.setTag("email", userData.value.username);
        Sentry.setTag("plan", userData.value.plan);
        Sentry.setTag("teamId", userData.value.teamId);
        Sentry.setTag("teamName", userData.value.teamName);
    }

    async function login(payload: IRequestLogin, callback: () => void): Promise<void> {
        try {
            startLoading();
            payload.anonymous_id = anonymousId.value;
            const res: IUserData = await SessionApi.signIn(payload);
            await saveUserData(res);

            if (res.inTeam) {
                router.push({ name: ERoutesName.APP_TEAM_PROCESS });
            }
            callback();
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }
    async function registration(payload: IRequestRegistration): Promise<void> {
        try {
            payload.anonymous_id = anonymousId.value;
            await SessionApi.signUp(payload);

            router.push({
                name: ERoutesName.CHECK_EMAIL,
                params: { email: payload.email },
            });
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function saveUserData(payload: IUserData): Promise<void> {
        await setToken(payload.accessToken);
        setUserData(payload);

        router.push({
            name: ERoutesName.APP_MY_PROCESSES,
        });
    }
    function logout(): void {
        setToken("");
        setUserData({ ...new UserDataDto({}) });

        router.push({
            path: "/",
        });
    }
    function setAnonymousId(): void {
        if (!anonymousIdValue) {
            anonymousId.value = generateRandomSequence();
            setLSAnonymousId(anonymousId.value);
        }
    }
    async function clickStream(fullPath: string): Promise<void> {
        try {
            const payload: IPayloadClickStream = {
                anonId: anonymousId.value,
                pageUrl: fullPath,
            };
            await SessionApi.clickStream(payload);
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    function setIsFirstLogin(value: boolean): void {
        isFirstLogin.value = value;
        setLSIsFirstLoginValue(value);
    }
    async function updateToken(heartbeatAcknowledged: boolean = false): Promise<void> {
        try {
            const newToken: string = await SessionApi.updateToken(heartbeatAcknowledged);
            setToken(newToken);
            const decode: IDecodeToken = decodeJWT(newToken);
            authorities.value = decode.authorities;
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    watch(
        userData,
        value => {
            if (value?.userId) {
                importFormbricks(value);
            } else {
                stopFormbricks();
            }
        },
        { immediate: true },
    );

    return {
        isAuth,
        isFullAccess,
        isTeamAccess,
        isEnterprisePlan,
        isTeamPlan,
        isInTeam,
        token,
        userData,
        loading,
        isFirstLogin,
        anonymousId,

        login,
        registration,
        logout,
        setSentrySettings,
        saveUserData,
        setLSIsFirstLoginValue,
        setAnonymousId,
        clickStream,
        setIsFirstLogin,
        updateToken,
    };
});
