<template>
    <Dialog
        v-model:visible="visible"
        header="Поделиться процессом"
        modal
        :draggable="false"
        position="top"
        style="width: 100%; max-width: 1000px"
    >
        <div class="flex flex-column gap-5">
            <FormColumn title="Прямая ссылка">
                <div class="flex gap-2">
                    <InputText v-model="link" class="w-full" :readonly="true" />
                    <Button class="w-12rem" label="Копировать" icon="pi pi-copy" @click="copyLink" outlined />
                </div>
            </FormColumn>
            <div class="flex flex-column gap-3">
                <AddEmail v-model="emails" />
                <FormColumn title="Выберите тип доступа">
                    <div class="flex gap-3">
                        <div class="flex align-items-center gap-2" v-for="item of secureAccessLevel">
                            <RadioButton v-model="accessLevel" :inputId="'shareProcessAccessType' + item.label" :value="item.value" />
                            <label :for="'shareProcessAccessType' + item.label">{{ item.label }}</label>
                        </div>
                    </div>
                </FormColumn>
                <DataTable v-if="hasSharedList" :value="sharedList">
                    <Column header="Email" field="">
                        <template #body="slotProps">
                            <div class="flex flex-column gap-1">
                                <div class="flex gap-2 align-items-center font-medium">
                                    <span
                                        class="pi"
                                        :class="{ 'pi-user': slotProps.data.currentUser, 'pi-clock': !slotProps.data.currentUser }"
                                    ></span>
                                    <span>{{ slotProps.data.userEmail }}</span>
                                </div>
                                <i class="text-sm text-color-secondary">Создано {{ slotProps.data.createdBy }}</i>
                            </div>
                        </template>
                    </Column>
                    <Column header="Доступ">
                        <template #body="slotProps">
                            <Dropdown
                                v-model="slotProps.data.settings"
                                :options="secureAccessLevel"
                                option-label="label"
                                option-value="value"
                                class="w-13rem"
                            />
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <Button icon="pi pi-times" size="small" text @click="() => deleteSecure(slotProps.data.id)"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="flex flex-column">
                <div class="flex gap-2 align-items-center">
                    <InputSwitch v-model="isAnonymous" @change="changeAnonymousAccess" />
                    <b class="text-xl">Анонимный доступ</b>
                </div>
                <Message v-if="isAnonymous" severity="error" :closable="false"
                    >Любой человек с сетевым доступом сможет просматривать процесс</Message
                >
            </div>
        </div>
        <template #footer>
            <template v-if="hasChanged">
                <div class="w-full flex gap-3">
                    <div class="flex gap-2" v-if="isBPMN">
                        <Button
                            label="Код для встраивания схемы"
                            icon="pi pi-copy"
                            @click="copyCodeCircuitEmbedding"
                            outlined
                            severity="secondary"
                        />
                        <Button
                            label="Код для встраивания карточки процесса"
                            icon="pi pi-copy"
                            @click="copyCodeProcessCardEmbedding"
                            outlined
                            severity="secondary"
                        />
                    </div>
                    <div class="flex gap-2 ml-auto">
                        <Button label="Отменить" outlined :loading="isLoading" @click="cancelChanges" />
                        <Button label="Сохранить изменения" :disabled="!diagramId" :loading="isLoading" @click="createSecure" />
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="w-full flex gap-3">
                    <div class="flex gap-2" v-if="isBPMN">
                        <Button
                            label="Код для встраивания схемы"
                            icon="pi pi-copy"
                            @click="copyCodeCircuitEmbedding"
                            outlined
                            severity="secondary"
                        />
                        <Button
                            label="Код для встраивания карточки процесса"
                            icon="pi pi-copy"
                            @click="copyCodeProcessCardEmbedding"
                            outlined
                            severity="secondary"
                        />
                    </div>
                    <div class="flex gap-2 ml-auto">
                        <Button label="Отмена" outlined @click="hide" />
                        <Button label="Предоставить доступ" :disabled="!emails.length" :loading="isLoading" @click="createSecure" />
                    </div>
                </div>
            </template>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";

import { useDialog, useNotification } from "@/shared/lib/composables";
import { copyToClipboard } from "@/shared/lib/utils/clipboard";
import { AddEmail } from "@/shared/ui/add-email";
import { FormColumn } from "@/shared/ui/form";

import { EProcessSecure, useSecureProcess, useDiagramStore } from "@/entities/Process";

const { visible, show, hide } = useDialog();
const { showError, showSuccess } = useNotification();
const {
    secureAccessLevel,
    isLoading,
    sharedList,
    hasSharedList,
    hasChanged,
    isAnonymous,
    fetchAllByProcessId,
    deleteSecure,
    saveChanges,
    setSecure,
    cancelChanges,
} = useSecureProcess();
const diagramStore = useDiagramStore();

const { isBPMN } = storeToRefs(diagramStore);

const diagramId = ref<string | null>("");
const emails = ref<string[]>([]);
const accessLevel = ref<EProcessSecure>(EProcessSecure.VIEW);
const indexTabView = ref<number>(0);

const link = computed(() => window.location.href);
const linkEmbedded = computed(() => `${link.value}/embedded/card`);

async function beforeShow(id: string) {
    diagramId.value = id;
    indexTabView.value = 0;
    show();
    await fetchAllByProcessId(id);
}

async function createSecure() {
    if (hasChanged.value) {
        await saveChanges();
    } else {
        if (diagramId.value) {
            await setSecure({
                anonymousSettings: false,
                diagramId: diagramId.value,
                folderId: null,
                settings: accessLevel.value,
                userEmails: emails.value,
            });
            emails.value = [];
            accessLevel.value = EProcessSecure.VIEW;
        }
    }

    if (diagramId.value) {
        await fetchAllByProcessId(diagramId.value);
    }
}
async function changeAnonymousAccess() {
    await setSecure({
        diagramId: diagramId.value,
        settings: isAnonymous.value ? EProcessSecure.VIEW : EProcessSecure.NONE,
        anonymousSettings: true,
    });
}

function copyLink() {
    try {
        copyToClipboard(link.value);
        showSuccess("Ссылка в буфере");
    } catch (e: any) {
        showError(e?.message || e);
    }
}
function copyCodeCircuitEmbedding() {
    try {
        const payload = `<iframe src="${link.value}" style="border:1px #f2f2f2 none;" name="extAdmin" scrolling="yes" frameborder="1"  height="340px" width="320px" allowfullscreen></iframe>`;
        copyToClipboard(payload);
        showSuccess("Код для встраивания схемы скопирован в буфер обмена");
    } catch (e: any) {
        showError(e?.message || e);
    }
}
function copyCodeProcessCardEmbedding() {
    try {
        const payload = `<iframe src="${linkEmbedded.value}" style="border:1px #f2f2f2 none;" name="extAdmin" scrolling="yes" frameborder="1"  height="340px" width="320px" allowfullscreen></iframe>`;
        copyToClipboard(payload);
        showSuccess("Код для встраивания карточки процесса скопирован в буфер обмена");
    } catch (e: any) {
        showError(e?.message || e);
    }
}

defineExpose({
    show: (diagramId: string) => beforeShow(diagramId),
});
</script>

<style scoped></style>
