<template>
    <template v-if="!comment.text && comment.files.length === 0">
        <div class="relative">
            <InputText
                ref="inputRef"
                v-model="comment.text"
                class="w-full pr-5 outline-none shadow-none text-sm border-1 border-300"
                placeholder="Оставить комментарий"
                size="small"
            />
            <div class="absolute right-0 top-50 button-cancel">
                <Button class="text-xs p-2" label="Отмена" severity="secondary" @click="hideEditForm()" />
            </div>
        </div>
    </template>
    <template v-else>
        <div class="surface-100 border-round">
            <Mentionable :keys="['@']" :items="suggestions" filtering-disabled offset="6" @open="getSuggestion">
                <Textarea
                    ref="textareaRef"
                    class="w-full border-none text-sm outline-none shadow-none surface-100"
                    v-model="comment.text"
                    rows="1"
                    auto-resize
                    :disabled="isLoading"
                    placeholder="Оставить комментарий"
                />

                <template #no-result>
                    <div class="dim">
                        {{ loadingSuggestions ? "Loading..." : "No result" }}
                    </div>
                </template>

                <template #item-#="{ item }">
                    <div class="issue">
                        <span class="dim">
                            {{ item.label }}
                        </span>
                    </div>
                </template>
            </Mentionable>
            <div class="w-full flex flex-wrap p-2 pt-0 pl-3 surface-100" v-if="comment.files.length > 0">
                <div
                    class="file pt-2 pr-2 relative"
                    v-for="(file, index) of comment.files"
                    :key="file.name + index"
                    @click.stop="onOpenGalleria(index)"
                >
                    <div class="relative w-full h-full border-round border-1 border-200 overflow-hidden">
                        <ImageStorm v-if="file.urlPreview" :src="setFileUrl(file.urlPreview)" :alt="file.name" />
                        <b class="extension" v-else>{{ file.name.split(".").at(-1) }}</b>
                    </div>
                    <button
                        class="icon-delete absolute top-0 right-0 text-white border-circle surface-900 border-2 border-white"
                        :disabled="isLoading"
                        @click.stop="appendFileToDeleteList(file, comment)"
                    >
                        <span class="pi pi-times text-sm"></span>
                    </button>
                </div>
            </div>
            <Divider class="mb-0 mt-2" />
            <div class="w-full flex justify-content-end align-items-center gap-2 p-2">
                <Button class="text-xs p-2" label="Отмена" severity="secondary" outlined @click="hideEditForm()" />
                <Button class="text-xs p-2" label="Сохранить" severity="success" @click="saveEditedComment(comment)" />
            </div>
        </div>
    </template>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

import { Mentionable } from "vue-mention";

import { ImageStorm } from "@/shared/ui/image";
import { useGalleria } from "@/shared/lib/composables";
import { setFileUrl } from "@/shared/lib/utils/file";

import { useSuggestions, type IComment } from "@/entities/Comment";

import { useCommentEdit } from "..";

interface IProps {
    comment: IComment;
}
const props = defineProps<IProps>();

const { setGalleriaCustomIndex, setGalleriaFiles, toggleGalleriaVisible } = useGalleria();
const { isLoading: loadingSuggestions, suggestions, getSuggestion } = useSuggestions();
const { isLoading, hideEditForm, appendFileToDeleteList, saveEditedComment } = useCommentEdit();

const inputRef = ref();
const textareaRef = ref();

function onOpenGalleria(index: number) {
    setGalleriaFiles(
        props.comment.files.map(item => {
            return {
                url: setFileUrl(item.url),
                preview: item.urlPreview ? setFileUrl(item.urlPreview) : "",
                extension: item.name.split(".").at(-1) || "",
            };
        }),
    );

    setGalleriaCustomIndex(index);
    toggleGalleriaVisible();
}

watch([inputRef, textareaRef], value => {
    if (value[0]) value[0].$el.focus();
    if (value[1]) value[1].$el.focus();
});
</script>

<style scoped lang="scss">
.create-comment-icon {
    translate: -50% -50%;
}
.button-cancel {
    translate: -0.25rem -50%;
}
.icon-delete {
    display: none;
    padding: 3px;
    width: 24px;
    height: 24px;
}
.file {
    width: 62px;
    height: 62px;

    &:hover {
        .icon-delete {
            display: block;
        }
    }
}

.extension {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
}

.issue {
    padding: 4px 6px;
    border-radius: 4px;
    cursor: pointer;
}
</style>
