<template>
    <RouterLink :to="{ name: routerName, params: { id: process.id } }">
        <slot></slot>
    </RouterLink>
</template>
<script setup lang="ts">
import { ERoutesName } from "@/app/providers";
import { IProcess } from "@/entities/Process";
import { ShareProcessTypes } from "@/shared/types";
import { computed } from "vue";

interface IProps {
    process: IProcess;
}

const props = defineProps<IProps>();

const routerName = computed<string>(() => {
    if (props.process.type === ShareProcessTypes.EDiagramType.BPMN) return ERoutesName.APP_DIAGRAM_ITEM;
    if (props.process.type === ShareProcessTypes.EDiagramType.BCM2) return ERoutesName.APP_BCM_DIAGRAM_ITEM;
    return "";
});
</script>

<style scoped></style>
